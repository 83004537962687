// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: 25% 100%;
}

.navbarMain {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.terms_Condition_Box{
  background-color: white;
  width: 70%;
  height: 80%;
  border-radius: 0.5rem;
  overflow-y: auto;
  padding: 1.3rem 2.8rem;
  scrollbar-width:thin;
}
`, "",{"version":3,"sources":["webpack://./src/styles/login.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,iBAAiB;EACjB,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,WAAW;AACb;;AAEA;EACE,uBAAuB;EACvB,UAAU;EACV,WAAW;EACX,qBAAqB;EACrB,gBAAgB;EAChB,sBAAsB;EACtB,oBAAoB;AACtB","sourcesContent":["img {\n  height: 100%;\n  width: 100%;\n  object-fit: cover;\n  object-position: 25% 100%;\n}\n\n.navbarMain {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  width: 100%;\n}\n\n.terms_Condition_Box{\n  background-color: white;\n  width: 70%;\n  height: 80%;\n  border-radius: 0.5rem;\n  overflow-y: auto;\n  padding: 1.3rem 2.8rem;\n  scrollbar-width:thin;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

export const CREATE_DIOCESE = "CREATE_DIOCESE";
export const CREATE_DIOCESE_SUCCESS = "CREATE_DIOCESE_SUCCESS";
export const CREATE_DIOCESE_FAILURE = "CREATE_DIOCESE_FAILURE";

export const GET_DIOCESE = "GET_DIOCESE";
export const GET_DIOCESE_SUCCESS = "GET_DIOCESE_SUCCESS";
export const GET_DIOCESE_FAILURE = "GET_DIOCESE_FAILURE";

export const UPDATE_DIOCESE = "UPDATE_DIOCESE";
export const UPDATE_DIOCESE_SUCCESS = "UPDATE_DIOCESE_SUCCESS";
export const UPDATE_DIOCESE_FAILURE = "UPDATE_DIOCESE_FAILURE";

export const UPDATE_DIOCESE_STATUS = "UPDATE_DIOCESE_STATUS";
export const UPDATE_DIOCESE_STATUS_SUCCESS = "UPDATE_DIOCESE_STATUS_SUCCESS";
export const UPDATE_DIOCESE_STATUS_FAILURE = "UPDATE_DIOCESE_STATUS_FAILURE";

export const DELETE_DIOCESE = "DELETE_DIOCESE";
export const DELETE_DIOCESE_SUCCESS = "DELETE_DIOCESE_SUCCESS";
export const DELETE_DIOCESE_FAILURE = "DELETE_DIOCESE_FAILURE";

export const DIALOG_MESSAGE = "DIALOG_MESSAGE";

export const DELETE_TOGGLE_MODAL_STATUS = "DELETE_TOGGLE_MODAL_STATUS";

export const deleteDioceseToggleModal = () => {
  return {
    type: DELETE_TOGGLE_MODAL_STATUS,
  };
};

export const getAllDIOCESE = (payload) => {
  return {
    type: GET_DIOCESE,
    payload,
  };
};

export const getAllDIOCESESuccess = (data, page) => {
  return {
    type: GET_DIOCESE_SUCCESS,
    payload: data,
    page,
  };
};

export const getAllDIOCESEFailure = (error) => {
  return {
    type: GET_DIOCESE_FAILURE,
    payload: error,
  };
};

export const createDIOCESE = (data) => {
  return {
    type: CREATE_DIOCESE,
    payload: data,
  };
};
export const createDIOCESESuccess = (data) => {
  return {
    type: CREATE_DIOCESE_SUCCESS,
    payload: data,
  };
};
export const createDIOCESEFailure = (error) => {
  return {
    type: CREATE_DIOCESE_FAILURE,
    payload: error,
  };
};

export const updateDIOCESE = (payload, id) => {
  return {
    type: UPDATE_DIOCESE,
    payload,
    id,
  };
};

export const updateDIOCESESuccess = (data) => {
  return {
    type: UPDATE_DIOCESE_SUCCESS,
    payload: data,
  };
};

export const updateDIOCESEFailure = (error) => {
  return {
    type: UPDATE_DIOCESE_FAILURE,
    payload: error,
  };
};

export const updateDIOCESEStatus = (id, status, rowName) => {
  return {
    type: UPDATE_DIOCESE_STATUS,
    id,
    status,
    rowName,
  };
};

export const updateDIOCESEStatusSuccess = (data) => {
  return {
    type: UPDATE_DIOCESE_STATUS_SUCCESS,
    payload: data,
  };
};

export const updateDIOCESEStatusFailure = (error) => {
  return {
    type: UPDATE_DIOCESE_STATUS_FAILURE,
    payload: error,
  };
};

export const deleteDIOCESE = (id) => {
  return {
    type: DELETE_DIOCESE,
    payload: id,
  };
};

export const deleteDIOCESESuccess = () => {
  return {
    type: DELETE_DIOCESE_SUCCESS,
  };
};

export const deleteDIOCESEFailure = (error) => {
  return {
    type: DELETE_DIOCESE_FAILURE,
    payload: error,
  };
};

export const showUnshowDialogMessage = () => {
  return {
    type: DIALOG_MESSAGE,
  };
};

export const CREATE_SINGER = "CREATE_SINGER";
export const CREATE_SINGER_SUCCESS = "CREATE_SINGER_SUCCESS";
export const CREATE_SINGER_FAILURE = "CREATE_SINGER_FAILURE";

export const GET_SINGER = "GET_SINGER";
export const GET_SINGER_SUCCESS = "GET_SINGER_SUCCESS";
export const GET_SINGER_FAILURE = "GET_SINGER_FAILURE";

export const UPDATE_SINGER = "UPDATE_SINGER";
export const UPDATE_SINGER_SUCCESS = "UPDATE_SINGER_SUCCESS";
export const UPDATE_SINGER_FAILURE = "UPDATE_SINGER_FAILURE";

export const DELETE_SINGER = "DELETE_SINGER";
export const DELETE_SINGER_SUCCESS = "DELETE_SINGER_SUCCESS";
export const DELETE_SINGER_FAILURE = "DELETE_SINGER_FAILURE";

export const DIALOG_MESSAGE = "DIALOG_MESSAGE";
export const DELETE_TOGGLE_MODAL_STATUS = "DELETE_TOGGLE_MODAL_STATUS";

export const showUnshowDialogMessage = () => {
  return {
    type: DIALOG_MESSAGE,
  };
};

export const getAllSinger = (urlParameters) => {
  return {
    type: GET_SINGER,
    params: urlParameters,
  };
};

export const getAllSingersSuccess = (data) => {
  return {
    type: GET_SINGER_SUCCESS,
    payload: data,
  };
};

export const getAllSingersFailure = (error) => {
  return {
    type: GET_SINGER_FAILURE,
    payload: error,
  };
};

export const createSinger = (data) => {
  return {
    type: CREATE_SINGER,
    payload: data,
  };
};
export const createSingerSuccess = (data) => {
  return {
    type: CREATE_SINGER_SUCCESS,
    payload: data,
  };
};
export const createSingerFailure = (error) => {
  return {
    type: CREATE_SINGER_FAILURE,
    payload: error,
  };
};

export const updateSinger = (payload, id) => {
  return {
    type: UPDATE_SINGER,
    payload,
    id,
  };
};

export const updateSingerSuccess = (data) => {
  return {
    type: UPDATE_SINGER_SUCCESS,
    payload: data,
  };
};

export const updateSingerFailure = (error) => {
  return {
    type: UPDATE_SINGER_SUCCESS,
    payload: error,
  };
};

export const deleteSinger = (id) => {
  return {
    type: DELETE_SINGER,
    payload: id,
  };
};

export const deleteSingerSuccess = () => {
  return {
    type: DELETE_SINGER_SUCCESS,
  };
};

export const deleteSingerFailure = (error) => {
  return {
    type: DELETE_SINGER_FAILURE,
    payload: error,
  };
};

// defining action for enabling/disabling delete modal
export const deleteToggleModal = () => {
  return {
    type: DELETE_TOGGLE_MODAL_STATUS,
  };
};

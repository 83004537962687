import {
  CREATE_HELPS,
  CREATE_HELPS_SUCCESS,
  CREATE_HELPS_FAILURE,
  GET_HELPS,
  GET_HELPS_SUCCESS,
  GET_HELPS_FAILURE,
  UPDATE_HELPS,
  UPDATE_HELPS_SUCCESS,
  UPDATE_HELPS_FAILURE,
} from "./action";

const initialState = {
  loading: false,
  error: "",
  helpsData: [],
  successRsponse: false,
  updatedHelpSuccess: false,
  page: [],
};

export const helpsReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_HELPS:
      return {
        ...state,
        error: "",
        loading: true,
        updatedHelpSuccess: false,
      };
    case UPDATE_HELPS_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
        successRsponse: true,
        updatedHelpSuccess: true,
      };
    case UPDATE_HELPS_FAILURE:
      return {
        ...state,
        error: action.payload,
        updatedHelpSuccess: false,
      };
    case CREATE_HELPS:
      return {
        ...state,
        error: "",
        loading: true,
      };
    case CREATE_HELPS_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
        successRsponse: true,
      };
    case CREATE_HELPS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_HELPS:
      return {
        ...state,
        helpsData: [],
        loading: true,
      };
    case GET_HELPS_SUCCESS:
      return {
        ...state,
        helpsData: action.payload,
        error: "",
        loading: false,
        page: action.page,
      };
    case GET_HELPS_FAILURE:
      return {
        ...state,
        helpsData: [],
        error: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

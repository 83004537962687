import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Popover,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  useTheme,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import DeleteDialogLayout from "../../commonLayout/DeleteDialogLayout";
import Paginate from "../commonComponent/Pagination";
import { formatDateInAlphanumericFormat } from "../../helpers/dateHelper";
import {
  deleteCommunity,
  deleteToggleModal,
  getCommPost,
} from "../../redux/community/action";
import withMixpanelTracking from "../commonComponent/withMixPanelTracking";

import { useNavigate } from "react-router-dom";
import useCustomStyles from "../../customHooks/useCustomStyles";
import TableTooltip from "../../helpers/tableTooltip";

const styles = (theme) => ({
  table: {
    overflowY: "auto",
    scrollbarWidth: "thin",
    [theme.breakpoints.down(1400)]: {
      maxHeight: 240,
    },
    [theme.breakpoints.between(1401, 1919)]: {
      maxHeight: 380,
    },
    [theme.breakpoints.up(1920)]: {
      maxHeight: 530,
    },
  },

  icon_size: {
    [theme.breakpoints.down(1400)]: {
      height: "25px",
      width: "25px",
    },
    [theme.breakpoints.between(1401, 1919)]: {
      height: "30px",
      width: "30px",
    },
  },
});

const ViewCommunityPost = (props) => {
  const theme = useTheme();
  const classes = useCustomStyles(styles, theme);
  const navigate = useNavigate();
  const [deleteToggle, setDeleteToggle] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [openSuccessDelete, setOpenSuccessDelete] = useState(false);
  const [paginationObject, setPaginationObject] = useState({
    page: 1,
    rowsPerPage: 10,
    totalRowCount: 0,
  });

  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const tableContainerRef = useRef(null);

  const handlePageChange = (event, newPage) => {
    setPaginationObject({ ...paginationObject, page: newPage });
    props.getCommPost(newPage);

    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTop = 0;
    }
  };

  const setCommunityDataWithPagination = (successValue) => {
    if (successValue && props?.communtyPostdata) {
      const { content, totalPages } = props?.communtyPostdata;

      props.setCommunityPostList(content);
      props.setRecordsList(content);

      setPaginationObject({
        ...paginationObject,
        totalRowCount: totalPages,
      });
    }
  };

  const closeSuccessDeleteModal = () => {
    props.deleteToggleModal();
    setOpenSuccessDelete(false);
  };

  const handleDeleteData = (value) => {
    setDeleteToggle(!deleteToggle);
    setRowData(value);
  };

  const deleteRowData = (value) => {
    props.deleteCommunity(value.id);
    props.trackButtonClick("Delete Community Post Button", 1);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  let sortedList = [];
  if (props?.communityPostList && props?.communityPostList?.length > 0) {
    sortedList = [...props?.communityPostList].sort((a, b) => {
      if (order) {
        return (
          (a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1) *
          (order === "asc" ? 1 : -1)
        );
      }
      return 0;
    });
  }

  const width = 300;
  const style = {
    maxWidth: width,
    borderStyle: "border-box",
  };

  const handleRowEdit = (val) => {
    const jsonData = JSON.stringify(val);

    const encodedData = encodeURIComponent(jsonData);
    navigate(`/content/community-post/edit?data=${encodedData}`);
  };

  useEffect(() => {
    props.getCommPost(paginationObject?.page);
  }, []);

  useEffect(() => {
    setCommunityDataWithPagination(props?.getCommPostSuccess);
  }, [props.getCommPostSuccess]);

  useEffect(() => {
    if (props.deleteCommunitySuccess) {
      props.getCommPost(paginationObject?.page);
      setRowData([]);
      setOpenSuccessDelete(true);
    }
  }, [props.deleteCommunitySuccess]);

  useEffect(() => {
    if (props?.communityPostList?.length !== props?.recordsList?.length) {
      setPaginationObject({
        ...paginationObject,
        totalRowCount: 1,
      });
    } else {
      setCommunityDataWithPagination(true);
    }
  }, [props?.communityPostList]);

  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const handleLanguageFilterChange = (event) => {
    setSelectedLanguage(event.target.value);
  };
  const applyLanguageFilter = () => {
    if (selectedLanguage) {
      // setFilteredList(
      //   props.communityPostList.filter(
      //     (post) =>
      //       (post.languageCode === "en_US" && selectedLanguage === "English") ||
      //       (post.languageCode === "hi_IN" && selectedLanguage === "Hindi") ||
      //       (post.languageCode === "pa_IN" && selectedLanguage === "Punjabi"),
      //   ),
      // );
    } else {
      // setFilteredList(props.communityPostList);
    }
    setFilterAnchorEl(null);
  };

  const clearLanguageFilter = () => {
    setSelectedLanguage("");
    // setFilteredList(props.communityPostList);
    setFilterAnchorEl(null);
  };

  const handleFilterIconClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    // setFilterAnchorEl(null);
  };

  const isFilterOpen = Boolean(filterAnchorEl);

  return (
    <>
      {
        <DeleteDialogLayout
          deleteToggle={deleteToggle}
          setDeleteToggle={setDeleteToggle}
          recordType="Post"
          recordRowData={rowData}
          deleteRowData={deleteRowData}
          openSuccessDelete={openSuccessDelete}
          closeSuccessDeleteModal={closeSuccessDeleteModal}
        />
      }

      <>
        <Grid container spacing={2}>
          {/* Grid item for table */}
          <Grid item xs={12}>
            <TableContainer
              component={Paper}
              elevation={0}
              className={classes?.table}
              ref={tableContainerRef}
            >
              <Table
                stickyHeader
                sx={{ minWidth: 650 }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell sx={style}>
                      <TableSortLabel
                        active={orderBy === "name"}
                        direction={order}
                        onClick={() => handleRequestSort("name")}
                      >
                        Title
                      </TableSortLabel>
                    </TableCell>

                    <TableCell>
                      Language
                      <IconButton
                        aria-label="filter"
                        size="small"
                        onClick={handleFilterIconClick}
                      >
                        <FilterListIcon />
                      </IconButton>
                      <Popover
                        open={isFilterOpen}
                        anchorEl={filterAnchorEl}
                        onClose={handleFilterClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                      >
                        <Box p={2}>
                          <Select
                            value={selectedLanguage}
                            onChange={handleLanguageFilterChange}
                            displayEmpty
                            sx={{ minWidth: 120, mb: 2 }}
                          >
                            <MenuItem value="">Select Language</MenuItem>
                            <MenuItem value="Hindi">Hindi</MenuItem>
                            <MenuItem value="English">English</MenuItem>
                            <MenuItem value="Punjabi">Punjabi</MenuItem>
                          </Select>
                          <Box mt={1}>
                            <Button
                              onClick={applyLanguageFilter}
                              sx={{ mr: 1 }}
                            >
                              Apply
                            </Button>
                            <Button onClick={clearLanguageFilter}>Clear</Button>
                          </Box>
                        </Box>
                      </Popover>
                    </TableCell>
                    <TableCell>For the Date</TableCell>
                    <TableCell>Uploaded By</TableCell>
                    <TableCell>Uploaded On</TableCell>
                    <TableCell align="right" sx={{ pr: 2.5 }}>
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {sortedList.length > 0 &&
                    sortedList.map((communityrow) => (
                      <TableRow
                        key={communityrow.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row" sx={style}>
                          <TableTooltip title={communityrow.name} />
                        </TableCell>
                        <TableCell>
                          {communityrow.languageCode === "en_US"
                            ? "English"
                            : communityrow.languageCode === "hi_IN"
                              ? "Hindi"
                              : communityrow.languageCode === "ta_IN"
                                ? "Tamil"
                                : communityrow.languageCode === "te_IN"
                                  ? "Telugu"
                                  : communityrow.languageCode === "kn_IN"
                                    ? "Kannada"
                                    : communityrow.languageCode === "si_LK"
                                      ? "Sinhalese"
                                      : "Punjabi"}
                        </TableCell>
                        <TableCell>
                          {" "}
                          {formatDateInAlphanumericFormat(
                            communityrow.createdAt,
                          )}
                        </TableCell>
                        <TableCell>Admin</TableCell>
                        <TableCell>
                          {formatDateInAlphanumericFormat(
                            communityrow.updatedAt,
                          )}
                        </TableCell>
                        <TableCell align="right" sx={{ p: 0 }}>
                          <IconButton
                            aria-label="edit"
                            sx={{ padding: 0, margin: 0 }}
                            onClick={() => {
                              props.setContentDataToBeUpdated(communityrow);
                              handleRowEdit(communityrow);
                              props.trackButtonClick(
                                "Edit Community Post Button",
                                1,
                              );
                            }}
                          >
                            <img
                              className={classes?.icon_size}
                              src="/edit-icon.svg"
                              alt="edit-icon"
                            />
                          </IconButton>

                          <IconButton
                            aria-label="delete"
                            sx={{ padding: 0, margin: 0, marginLeft: "0.8rem" }}
                            onClick={() => {
                              handleDeleteData(communityrow);
                            }}
                          >
                            <img
                              className={classes?.icon_size}
                              src="/delete-icon.svg"
                              alt="delete-icon"
                            />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>

            {sortedList === undefined ||
              (sortedList?.length === 0 && (
                <Box
                  sx={{
                    display: "grid",
                    justifyContent: "center",
                    textAlign: "center",
                    margin: "auto",
                    marginTop: "30px",
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <img
                      src="/mailbox.png"
                      alt="No data"
                      style={{ width: "30px", height: "30px" }}
                    />
                  </Box>
                  <Box sx={{}}>No data</Box>
                </Box>
              ))}

            {sortedList?.length > 0 ? (
              <Grid
                container
                sx={{ marginTop: "25px", justifyContent: "center" }}
              >
                <Paginate
                  paginationObject={paginationObject}
                  handlePageChange={handlePageChange}
                />
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </>
    </>
  );
};

const mapStateToProps = (state) => {
  const { communtyPostdata, getCommPostSuccess, deleteCommunitySuccess } =
    state.communityReducer;
  return {
    communtyPostdata,
    getCommPostSuccess,
    deleteCommunitySuccess,
  };
};

export default connect(mapStateToProps, {
  deleteCommunity,
  getCommPost,
  deleteToggleModal,
})(withMixpanelTracking(ViewCommunityPost, "Community Post"));

import { combineReducers } from "redux";
import { communityReducer } from "./community/reducer";
import { eventReducer } from "./Events/reducer";
import { bibleReducer } from "./bible/reducer";
import { dailyVerseReducer } from "./dailyVerse/reducer";
import { loginReducer } from "./login/reducer";
import { prayerReducer } from "./prayer/reducer";
import { languageReducer } from "./language/reducer";
import { volunteerReducer } from "./volunteer/reducer";
import { helpsReducer } from "./help-centre/reducer";
import { userReducer } from "./usermanagement/reducer";
import { gospelReducer } from "./gospel/reducer";
import { CHURCHReducer } from "./church/reducer";
import { profileReducer } from "./userprofile/reducer";
import { quizReducer } from "./quiz/reducer";
import { dashboardReducer } from "./dashboard/reducer";
import { deleteRequestReducer } from "./delete-request/reducer";
import { singerReducer } from "./singer/reducer";
import { albumReducer } from "./album/reducer";
import { snackbarReducer } from "./snackbar/reducer";
import { paneReducer } from "./navigationaPane/reducer";
import { geoLocationReducer } from "./geoLocation/reducer";
import { DIOCESEReducer } from "./diocese/reducer";
const rootReducer = combineReducers({
  DIOCESEReducer,
  geoLocationReducer,
  communityReducer,
  eventReducer,
  bibleReducer,
  dailyVerseReducer,
  loginReducer,
  userReducer,
  prayerReducer,
  languageReducer,
  volunteerReducer,
  helpsReducer,
  gospelReducer,
  CHURCHReducer,
  profileReducer,
  quizReducer,
  dashboardReducer,
  deleteRequestReducer,
  albumReducer,
  singerReducer,
  snackbarReducer,
  paneReducer,
});

export default rootReducer;

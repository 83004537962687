import React, { useCallback } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import {
  updateSinger,
  createSinger,
  showUnshowDialogMessage,
} from "../../redux/singer/action";
import withMixpanelTracking from "../commonComponent/withMixPanelTracking";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import useCustomStyles from "../../customHooks/useCustomStyles";
import { FileUploadOutlined } from "@mui/icons-material";
import DialogBoxFileUploadLayout from "../../commonLayout/DialogBoxFileUploadLayout";
import DialogBoxLayout from "../../commonLayout/DialogBoxLayout";
import LoaderLayout from "../../commonLayout/LoaderLayout";
import ProgressDialogLayout from "../../commonLayout/ProgressDialogLayout";
import { truncateAtDot } from "../../helpers/truncateFileName";
import { fetchLanguage } from "../../redux/language/action";

const styles = (theme) => ({
  container: {
    marginTop: "45px",
    backgroundColor: "var(--primary-color)",
    borderRadius: "8px",
    padding: "0 10px 300px",
    height: "800px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  componentTitle: {
    fontSize: "24px",
    paddingLeft: "15px",
    fontWeight: "400",
    color: "#333333",
  },

  input_box: {
    backgroundColor: "#ffffff",
    color: "#A0A09E",
  },
});

const AddSinger = ({ closeEditModal, ...props }) => {
  const theme = useTheme();
  const { action } = useParams();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const encodedData = query.get("data");
  const decodedData = decodeURIComponent(encodedData);

  const editRecords = JSON.parse(decodedData);
  const matches = useMediaQuery("(max-width:1440px)");
  const navigate = useNavigate();
  const classes = useCustomStyles(styles, theme);

  const [uploadSinger, setuploadSinger] = useState({
    singerName: "",

    thumbnailPath: "",

    languageCode: "",
  });

  // state variable used to keep a track of the different behaviour of drag and drop and normal file upload
  const [toBeUploadedFileFormat, setToBeUploadedFileFormat] = useState({
    fileType: "",
    acceptedFileFormatForDragDrop: [],
    acceptedFileFormatForNormalFileUpload: [],
  });

  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [progressDialogOpen, setProgressDialogOpen] = useState(false);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [uploadFileName, setUploadFileName] = useState("");
  const [uploadInterval, setUploadInterval] = useState(null);
  const [fileName, setFileName] = useState("");
  const [, setAudioFileName] = useState("");

  const resetInputFieldsOnApiSuccess = useCallback(
    (successValue, loadingValue) => {
      if (successValue) {
        setLoading(false);
        setuploadSinger((prevuploadSinger) => ({
          ...prevuploadSinger,
          singerName: "",
          thumbnailPath: "",

          languageCode: "",
        }));
        setFileName("");
        setAudioFileName("");
      } else {
        setLoading(loadingValue);
      }
    },
    [],
  );

  useEffect(() => {
    if (props?.dialogMessage) {
      resetInputFieldsOnApiSuccess(true, "Created Successfully.");
    }
  }, [props?.createRespone, props.updateResponse]);

  useEffect(() => {
    getAllLanguagesList();

    if (action === "edit") {
      let records = editRecords;

      setuploadSinger((prevuploadSinger) => ({
        ...prevuploadSinger,
        singerName: records.singerName,
        thumbnailPath: records.thumbnailPath,
        languageCode: records.languageCode,
      }));
      if(records.thumbnailPath){
        setFileName("song Image.jpg")
      }
    }
  }, []);

  const [errors, setErrors] = useState(null);

  const validateForm = () => {
    const newErrors = {};
    if (!uploadSinger.singerName)
      newErrors.singerName = "Singer Name is required.";
    if (!uploadSinger.thumbnailPath)
      newErrors.thumbnailPath = "Singer Image is required.";
    if (!uploadSinger.languageCode)
      newErrors.languageCode = "Language is required.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const handleOpen = (fileType, dragDropFileFormat, normalUploadFileFormat) => {
    setToBeUploadedFileFormat({
      ...toBeUploadedFileFormat,
      fileType: fileType,
      acceptedFileFormatForDragDrop: dragDropFileFormat,
      acceptedFileFormatForNormalFileUpload: normalUploadFileFormat,
    });
    setDialogOpen(true);
  };

  const handleProgressDialogClose = () => {
    setProgressDialogOpen(false);
    setUploadInProgress(false);
    clearInterval(uploadInterval);
    setProgress(0);
    setFileName("");
    setAudioFileName("");
  };

  const handleChangeLanguage = (val) => {
    setuploadSinger({
      ...uploadSinger,
      languageCode: val.target.value,
    });
  };

  const handleFileChange = (event) => {
    const file = event.target ? event.target.files[0] : event;

    if (file && (file.type === "image/png" || file.type === "image/jpeg")) {
      setUploadFileName(file.name);
      setuploadSinger((prevuploadSinger) => ({
        ...prevuploadSinger,
        thumbnailPath: file,
      }));
      setFileName(file.name);
      setDialogOpen(false);
      handleProgressDialogOpen();
      uploadFile(file);
    } else {
      alert("Please upload a valid file.");
    }
  };

  const handleProgressDialogOpen = () => {
    setProgressDialogOpen(true);
    setUploadInProgress(true);
  };

  const uploadFile = (file) => {
    setProgress(0);

    const UploadProgress = () => {
      let progress = 0;

      const interval = setInterval(() => {
        progress += 10;
        setProgress(progress);

        if (progress >= 110) {
          clearInterval(interval);
          setUploadInProgress(false);
          setProgressDialogOpen(false);
        }
      }, 300);

      setUploadInterval(interval);
    };
    UploadProgress();
  };

  const handleKirtanUpload = (event) => {
    event.preventDefault();
    if (!validateForm()) return;
    setLoading(true);

    const formData = new FormData();
    formData.append("singerName", uploadSinger?.singerName);

    formData.append("languageCode", uploadSinger?.languageCode);

    if (action === "edit" && !uploadSinger.thumbnailPath) {
      formData.append("thumbnailPath", editRecords?.thumbnailPath);
    } else {
      formData.append("thumbnailPath", uploadSinger?.thumbnailPath);
    }

    if (action === "edit") {
      props.updateSinger(formData, editRecords.id);
      props.trackButtonClick(
        "Update Singer Button",
        1,
        uploadSinger?.singerName,
      );
    } else {
      props.createSinger(formData);
      props.trackButtonClick(
        "Create Singer Button",
        1,
        uploadSinger?.singerName,
      );
    }
  };

  const handleCloseSuccessBox = () => {
    props.showUnshowDialogMessage();
    navigate("/content/gospel/view");
  };

  const getAllLanguagesList = () => {
    props.fetchLanguage();
  };

  return (
    <>
      {props?.dialogMessage && (
        <DialogBoxLayout
          dialogBoxToggle={props.dialogMessage}
          setDialogBoxToggle={handleCloseSuccessBox}
          header={
            action === "edit"
              ? "Updated Successfully!"
              : "Uploaded Successfully!"
          }
          content={
            action === "edit"
              ? "You Have Edited Singer Successfully"
              : "You Have Uploaded Singer Successfully"
          }
        />
      )}
      <div className="kirtan__upload-container">
        {dialogOpen && (
          <DialogBoxFileUploadLayout
            dialogOpen={dialogOpen}
            setDialogOpen={setDialogOpen}
            uploadInProgress={uploadInProgress}
            progress={progress}
            handleFileChange={handleFileChange}
            toBeUploadedFileFormat={toBeUploadedFileFormat}
          />
        )}

        {progressDialogOpen && (
          <ProgressDialogLayout
            progressDialogOpen={progressDialogOpen}
            handleProgressDialogClose={handleProgressDialogClose}
            uploadFileName={truncateAtDot(uploadFileName)}
            uploadInProgress={uploadInProgress}
            toBeUploadedFileFormat={toBeUploadedFileFormat}
            progress={progress}
          />
        )}

        <Grid
          xs={12}
          sx={{
            width: {
              xl: "60%",
            },
            margin: {
              xl: "auto",
            },
            marginTop: {
              xl: "80px",
            },
            paddingBottom: {
              xl: "60px",
            },
          }}
          component="form"
          onSubmit={handleKirtanUpload}
        >
          <div>
            <Stack spacing={2} direction="column" gap={3}>
              <Grid container spacing={{ lg: 8, xl: 20 }}>
                <Grid xs={6}>
                  <FormControl
                    variant="outlined"
                    sx={{ width: { xs: 200, md: 250, lg: 400 } }}
                  >
                    <TextField
                      className={classes?.input_box}
                      id="outlined-basic"
                      placeholder="Enter Singer Name"
                      size={matches ? "small" : ""}
                      label="Singer Name"
                      fullWidth
                      error={!!errors?.singerName}
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      value={uploadSinger?.singerName}
                      onChange={(event) => {
                        setErrors(null);
                        setuploadSinger({
                          ...uploadSinger,
                          singerName: event.target.value,
                        });
                      }}
                    />
                    {errors?.singerName && (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errors?.singerName}
                      </span>
                    )}
                  </FormControl>
                </Grid>
                <Grid xs={6}>
                  {" "}
                  <FormControl
                    variant="outlined"
                    sx={{ width: { xs: 200, md: 250, lg: 400 }, mb: 2 }}
                  >
                    <TextField
                      className={classes?.input_box}
                      id="outlined-basic"
                      error={!!errors?.thumbnailPath}
                      size={matches ? "small" : ""}
                      placeholder="In JPEG/PNG format only*"
                      label="Singer Image"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        readOnly: true,
                        endAdornment: (
                          <>
                            <Button
                              aria-label="upload"
                              component="label"
                              className="addFileButton"
                              size={matches ? "small" : ""}
                              onClick={() => {
                                setErrors(null);
                                handleOpen(
                                  "JPEG/PNG",
                                  ["JPEG", "PNG"],
                                  [".jpeg", ".png"],
                                );
                              }}
                            >
                              Add File
                            </Button>
                          </>
                        ),
                      }}
                      value={truncateAtDot(fileName)}
                    />
                    {errors?.thumbnailPath && (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errors?.thumbnailPath}
                      </span>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={{ lg: 8, xl: 20 }}>
                <Grid xs={6}>
                  {" "}
                  <FormControl
                    variant="outlined"
                    sx={{ width: { xs: 200, md: 250, lg: 400 } }}
                    size={matches ? "small" : ""}
                  >
                    <TextField
                      error={!!errors?.languageCode}
                      InputLabelProps={{ shrink: true }}
                      id="language"
                      select
                      variant="outlined"
                      size={matches ? "small" : ""}
                      sx={{
                        backgroundColor: "white",
                        paddingBottom: "0px",
                        "& .MuiSelect-select span::before": {
                          color: "#A0A09E",
                          content: "'Choose Language'",
                        },
                      }}
                      label="Language"
                      value={uploadSinger?.languageCode}
                      onChange={(e) => {
                        setErrors(null);
                        handleChangeLanguage(e);
                      }}
                    >
                      {
                        props?.languageData?.data?.length > 0 &&
                            props?.languageData?.data.map((language) => (
                              <MenuItem value={language?.code}>
                                {language?.name}
                              </MenuItem>
                            ))
                      }
                    </TextField>
                    {errors?.languageCode && (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errors?.languageCode}
                      </span>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </Stack>

            {loading ? (
              <LoaderLayout />
            ) : (
              <div sx={{ float: "right" }}>
                <Button
                  variant="contained"
                  type="submit"
                  startIcon={action === "edit" ? <FileUploadOutlined /> : <AddCircleOutlineOutlinedIcon />}
                  size="small"
                  sx={{
                    backgroundColor: "var(--secondary-color)",
                    textTransform: "none",
                    fontSize: {
                      lg: "14px",
                      xl: "20px",
                    },
                    fontWeight: "500",
                    marginTop: "30px",
                    textAlign: "end",
                    float: "right",
                    marginLeft: "30px",
                  }}
                >
                  {action === "edit" ? "Update" : "Create"}
                </Button>
                {/* <Button
                  variant="outlined"
                  // startIcon={<AddCircleOutlineOutlined />}
                  size="small"
                  sx={{
                    color: "var(--secondary-color)",
                    border: "1px solid var(--secondary-color)",
                    textTransform: "none",
                    fontSize: {
                      lg: "14px",
                      xl: "20px",
                    },
                    fontWeight: "500",
                    marginTop: "30px",
                    textAlign: "end",
                    float: "right",
                    marginLeft: "30px",
                  }}
                  onClick={() => navigate("/content/gospel/view")}
                >
                  Cancel
                </Button> */}
              </div>
            )}
          </div>
        </Grid>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { createRespone, updateResponse, dialogMessage } = state.singerReducer;
  const { languageData } = state.languageReducer;

  return {
    createRespone,
    updateResponse,
    dialogMessage,
    languageData
  };
};

export default connect(mapStateToProps, {
  updateSinger,
  createSinger,
  showUnshowDialogMessage,
  fetchLanguage
})(withMixpanelTracking(AddSinger, "Add Singer"));

import {
  GET_REQUEST,
  GET_REQUEST_SUCCESS_DETAILS,
  GET_REQUEST_FAILURE_DETAILS,
  GET_REQUEST_BY_STATUS,
  GET_REQUEST_BY_STATUS_SUCCESS,
  GET_REQUEST_BY_STATUS_FAILURE,
  UPDATE_REQUEST_BY_STATUS,
  UPDATE_REQUEST_BY_STATUS_SUCCESS,
  UPDATE_REQUEST_BY_STATUS_FAILURE,
} from "./action";

const initialState = {
  loading: false,
  error: "",
  deleteRequestData: [],
  DeleteRByStatusData: [],

  getDeleteRSuccess: false,
  getByStatusSuccess: false,
  updateByStatusSuccess: false,
  pendingRequest: "",
  RejectedRequest: "",
  ApprovedRequest: "",
};

export const deleteRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REQUEST:
      return {
        ...state,
        deleteRequestData: [],
        error: "",
        getDeleteRSuccess: false,
        loading: true,
      };
    case GET_REQUEST_SUCCESS_DETAILS:
      return {
        ...state,
        deleteRequestData: action.payload,
        error: "",
        getDeleteRSuccess: true,
        loading: false,
      };

    case GET_REQUEST_FAILURE_DETAILS:
      return {
        ...state,
        deleteRequestData: [],
        error: action.payload,
        getDeleteRSuccess: false,
        loading: false,
      };

    case GET_REQUEST_BY_STATUS:
      return {
        ...state,
        DeleteRByStatusData: [],
        error: "",
        getByStatusSuccess: false,
        pendingRequest: "",
        RejectedRequest: "",
        ApprovedRequest: "",
      };
    case GET_REQUEST_BY_STATUS_SUCCESS:
      return {
        ...state,
        DeleteRByStatusData: action.payload,
        error: "",
        getByStatusSuccess: true,
        pendingRequest: action.AppliedCount,
        RejectedRequest: action.RejectedCount,
        ApprovedRequest: action.approvedCount,
      };
    case GET_REQUEST_BY_STATUS_FAILURE:
      return {
        ...state,
        DeleteRByStatusData: [],
        error: action.payload,
        getByStatusSuccess: false,
        pendingRequest: "",
        RejectedRequest: "",
        ApprovedRequest: "",
      };
    case UPDATE_REQUEST_BY_STATUS:
      return {
        ...state,

        error: "",
        updateByStatusSuccess: false,
      };
    case UPDATE_REQUEST_BY_STATUS_SUCCESS:
      return {
        ...state,

        error: "",
        updateByStatusSuccess: true,
      };
    case UPDATE_REQUEST_BY_STATUS_FAILURE:
      return {
        ...state,

        error: action.payload,
        updateByStatusSuccess: false,
      };

    default:
      return state;
  }
};

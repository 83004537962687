import { call, put, takeEvery } from "redux-saga/effects";

import { CREATE_QUIZ, GET_QUIZ, UPDATE_QUIZ } from "./action";
import { saveAs } from "file-saver";
import { getQuizrDownloadService } from "../../apiServices/Quiz";

import {
  updateQuizByIDSuccess,
  updateQuizByIDFail,
  createQuizPostSuccess,
  createQuizPostFailure,
  getQuizSuccess,
  getQuizFailure,
  DOWNLOAD_QUIZ,
} from "./action";

import {
  postRequest,
  getRequest,
  putRequest,
} from "../../apiServices/ApiActions";

import {
  addQuizUrls,
  getQuizUrls,
  updateQuizUrls,
  downloadQuizUrls,
} from "../../apiServices/ApiUrl";
import { showSnackbar } from "../snackbar/action";

const createQuizR = async (payload) => {
  return postRequest(addQuizUrls, payload);
};

const getQuizR = async (page) => {
  return getRequest(`${getQuizUrls}?page=${--page}&size=${10}`);
};

const updateQuizR = async (id, payload) => {
  return putRequest(updateQuizUrls + `${id}`, payload);
};

const downloadQuizR = async (id) => {
  return getQuizrDownloadService(id);
};

function* createQuiz(action) {
  try {
    const Result = yield call(createQuizR, action.payload);

    if (Result.status === 201) {
      yield put(createQuizPostSuccess(Result));
      yield put(
        showSnackbar({
          message: `Quiz uploaded successfully.`,
          severity: "success",
        }),
      );
    } else {
      yield put(createQuizPostFailure(Result));
    }
  } catch (error) {
    yield put(createQuizPostFailure(error));
  }
}

function* getQuiz(action) {
  try {
    const Result = yield call(getQuizR, action.params);

    if (Result.status === 200) {
      let response = Result.data;
      yield put(getQuizSuccess(response));
    } else {
      yield put(getQuizFailure(Result));
    }
  } catch (error) {
    yield put(getQuizFailure(error));
  }
}

function* updateQuiz(action) {
  try {
    const Result = yield call(updateQuizR, action.id, action.payload);

    if (Result.status === 200 || Result.status === 204) {
      yield put(updateQuizByIDSuccess());
      yield put(
        showSnackbar({
          message: `Quiz updated successfully.`,
          severity: "success",
        }),
      );
    } else {
      yield put(updateQuizByIDFail(Result));
    }
  } catch (error) {
    yield put(updateQuizByIDFail(error));
  }
}
function* downLoadQuiz(action) {
  try {
    const Result = yield call(downloadQuizR, action.id);

    if (Result.status === 200 || Result.status === 204) {
      const blob = new Blob([Result.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });

      saveAs(blob, "Quizfile.xlsx");
      yield put(updateQuizByIDSuccess());
    } else {
      yield put(updateQuizByIDFail(Result));
    }
  } catch (error) {
    yield put(updateQuizByIDFail(error));
  }
}

function* quizSaga() {
  yield takeEvery(CREATE_QUIZ, createQuiz);
  yield takeEvery(GET_QUIZ, getQuiz);
  yield takeEvery(UPDATE_QUIZ, updateQuiz);
  yield takeEvery(DOWNLOAD_QUIZ, downLoadQuiz);
}
export default quizSaga;

import { useEffect, useRef, useState } from "react";
import {
  Grid,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TableSortLabel,
  useTheme,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import DeleteDialogLayout from "../../commonLayout/DeleteDialogLayout";
import Paginate from "../commonComponent/Pagination";
import {
  deleteBible,
  deleteToggleModal,
  getBibledetails,
} from "../../redux/bible/action";
import { formatDateInAlphanumericFormat } from "../../helpers/dateHelper";
import { connect } from "react-redux";
import withMixpanelTracking from "../commonComponent/withMixPanelTracking";
import useCustomStyles from "../../customHooks/useCustomStyles";

const LanguageList = [
  { langName: "HINDI", LangCode: "hi_IN" },
  { langName: "ENGLISH", LangCode: "en_US" },
  { langName: "PUNJABI", LangCode: "pa_IN" },
];

const styles = (theme) => ({
  table: {
    overflowY: "auto",
    scrollbarWidth: "thin",
    [theme.breakpoints.down(1400)]: {
      maxHeight: 240,
    },
    [theme.breakpoints.between(1401, 1919)]: {
      maxHeight: 380,
    },
    [theme.breakpoints.up(1920)]: {
      maxHeight: 530,
    },
  },

  icon_size: {
    [theme.breakpoints.down(1400)]: {
      height: "25px",
      width: "25px",
    },
    [theme.breakpoints.between(1401, 1919)]: {
      height: "30px",
      width: "30px",
    },
  },
});

const ViewBible = (props) => {
  const theme = useTheme();
  const classes = useCustomStyles(styles, theme);
  const navigate = useNavigate();
  const [deleteToggle, setDeleteToggle] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [orderDirection, setOrderDirection] = useState("asc");
  const [sortedData, setSortedData] = useState([]);
  const [openSuccessDelete, setOpenSuccessDelete] = useState(false);
  const [paginationObject, setPaginationObject] = useState({
    page: 1,
    rowsPerPage: 10,
    totalRowCount: 0,
  });
  const tableContainerRef = useRef(null);

  const handlePageChange = (event, newPage) => {
    setPaginationObject({ ...paginationObject, page: newPage });
    props.getBibledetails(newPage);

    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTop = 0;
    }
  };

  const setBibleDataWithPagination = (loading) => {
    if (!loading && props?.bibleData) {
      const { content, page } = props?.bibleData;

      props.setBibleList(content);
      props.setRecordsList(content);
      setSortedData(content);

      setPaginationObject({
        ...paginationObject,
        totalRowCount: page?.totalPages,
      });
    }
  };

  const handleSortRequest = () => {
    if (sortedData?.length > 0) {
      const isAsc = orderDirection === "asc";
      const sorted = [...sortedData].sort((a, b) =>
        isAsc ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name),
      );
      setOrderDirection(isAsc ? "desc" : "asc");
      setSortedData(sorted);
    }
  };

  const handleDeleteData = (value) => {
    setDeleteToggle(!deleteToggle);
    setRowData(value);
  };

  const deleteRowData = (value) => {
    props.deleteBible(value.id);
    props.trackButtonClick("Delete Bible Button", 1, value?.id);
  };

  const handleRowEdit = (val) => {
    const jsonData = JSON.stringify(val);

    const encodedData = encodeURIComponent(jsonData);
    navigate(`/content/bible/edit?data=${encodedData}`);
  };

  const closeSuccessDeleteModal = () => {
    props.deleteToggleModal();
    setOpenSuccessDelete(false);
  };

  useEffect(() => {
    setSortedData(props.bibleList);

    if (props?.bibleList?.length !== props?.recordsList?.length) {
      setPaginationObject({
        ...paginationObject,
        totalRowCount: 1,
      });
    } else {
      setBibleDataWithPagination(false);
    }
  }, [props?.bibleList]);

  useEffect(() => {
    props.getBibledetails(paginationObject?.page);
  }, [props.getBibledetails]);

  useEffect(() => {
    setBibleDataWithPagination(props.loading);
  }, [props.loading]);

  useEffect(() => {
    if (props.deleteBiblesucces) {
      props.getBibledetails(paginationObject?.page);
      setRowData([]);
      setOpenSuccessDelete(true);
    }
  }, [props.deleteBiblesucces, props.updateBibleSuccess]);

  return (
    <>
      <DeleteDialogLayout
        deleteToggle={deleteToggle}
        setDeleteToggle={setDeleteToggle}
        recordType="Bible"
        recordRowData={rowData}
        deleteRowData={deleteRowData}
        openSuccessDelete={openSuccessDelete}
        closeSuccessDeleteModal={closeSuccessDeleteModal}
      />

      <Grid container xs={12} flexWrap="wrap">
        <TableContainer
          component={Paper}
          elevation={0}
          className={classes?.table}
          ref={tableContainerRef}
        >
          <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active
                    direction={orderDirection}
                    onClick={handleSortRequest}
                  >
                    Book Name
                  </TableSortLabel>
                </TableCell>
                <TableCell>Language</TableCell>
                <TableCell>Uploaded By</TableCell>
                <TableCell>Uploaded on</TableCell>
                <TableCell align="right" sx={{ pr: 2.5 }}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {sortedData?.length > 0 &&
                sortedData.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell>
                      {row?.languageCode === "en_US"
                        ? "English"
                        : row?.languageCode === "hi_IN"
                          ? "Hindi"
                          : row?.languageCode === "ta_IN"
                            ? "Tamil"
                            : row?.languageCode === "te_IN"
                              ? "Telugu"
                              : row?.languageCode === "kn_IN"
                                ? "Kannada"
                                : row?.languageCode === "si_LK"
                                  ? "Sinhalese"
                                  : "Punjabi"}
                    </TableCell>
                    <TableCell>Admin</TableCell>
                    <TableCell>
                      {formatDateInAlphanumericFormat(row.createdAt)}
                    </TableCell>
                    <TableCell align="right" sx={{ p: 0 }}>
                      <IconButton
                        aria-label="edit"
                        sx={{ padding: 0, margin: 0 }}
                        onClick={() => {
                          props.setContentDataToBeUpdated(row);
                          handleRowEdit(row);
                          props.trackButtonClick("Edit Bible Button", 1);
                        }}
                      >
                        <img
                          className={classes?.icon_size}
                          src="/edit-icon.svg"
                          alt="edit"
                          style={{
                            cursor: "pointer",
                          }}
                        />
                      </IconButton>

                      <IconButton
                        aria-label="delete"
                        sx={{ padding: 0, margin: 0, marginLeft: "0.8rem" }}
                        onClick={() => handleDeleteData(row)}
                      >
                        <img
                          className={classes?.icon_size}
                          src="/delete-icon.svg"
                          alt="delete-icon"
                        />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        {sortedData === undefined ||
          (sortedData?.length === 0 && (
            <Box
              sx={{
                display: "grid",
                justifyContent: "center",
                textAlign: "center",
                margin: "auto",
                marginTop: "30px",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <img
                  src="/mailbox.png"
                  alt="No data"
                  style={{ width: "30px", height: "30px" }}
                />
              </Box>
              <Box sx={{}}>No data</Box>
            </Box>
          ))}

        {sortedData?.length > 0 ? (
          <Grid container sx={{ marginTop: "25px", justifyContent: "center" }}>
            <Paginate
              paginationObject={paginationObject}
              handlePageChange={handlePageChange}
            />
          </Grid>
        ) : null}
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    deleteBiblesucces,
    bibleDataById,
    bibleData,
    loading,
    updateBibleSuccess,
    getBibleSucces,
  } = state.bibleReducer;

  return {
    deleteBiblesucces,
    bibleDataById,
    bibleData,
    loading,
    updateBibleSuccess,
    getBibleSucces,
  };
};

export default connect(mapStateToProps, {
  deleteBible,
  getBibledetails,
  deleteToggleModal,
})(withMixpanelTracking(ViewBible, "Bible"));

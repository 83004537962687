import { Pagination, useTheme } from "@mui/material";
import useCustomStyles from "../../customHooks/useCustomStyles";

const styles = (theme) => ({
  caption: {
    ".MuiTablePagination-displayedRows": {
      fontSize: "1.15rem",
      fontWeight: 600,
      textAlign: "right",
    },
  },
});

// implementing pagination as a common for all the implemented tables.
const Paginate = ({ paginationObject, handlePageChange, style }) => {
  const theme = useTheme();
  const classes = useCustomStyles(styles, theme);

  return (
    <>
      <Pagination
        className={classes?.caption}
        colSpan={6}
        rowsPerPageOptions={[10]}
        count={paginationObject?.totalRowCount}
        rowsPerPage={paginationObject?.rowsPerPage}
        page={paginationObject?.page}
        onChange={handlePageChange}
      />
    </>
  );
};

export default Paginate;

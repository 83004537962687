import { apiClient } from "./AxiosClient";

export const getAllChurch = async (payload) =>
  await apiClient.get("/location/search" + `?${payload}`);

export const createChurch = async (data) =>
  await apiClient.post("/location/save", data);

export const updateChurch = async (data, id) =>
  await apiClient.put(`/location/update`, data);

export const updateChurchStatus = async (id, status) =>
  await apiClient.put(`/location/diocese/status/${id}?isActive=${status}`);

export const deleteChurch = async (id) =>
  await apiClient.delete(`/location/${id}`);

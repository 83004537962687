import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  createBible,
  showUnshowDialogMessage,
  updateBible,
} from "../../redux/bible/action";

import {
  Grid,
  FormControl,
  TextField,
  Button,
  useTheme,
  Select,
  InputLabel,
  MenuItem,
  useMediaQuery,
  Box,
  OutlinedInput,
} from "@mui/material";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import useCustomStyles from "../../customHooks/useCustomStyles";
import FileUploadOutlined from "@mui/icons-material/FileUploadOutlined";
import DialogBoxLayout from "../../commonLayout/DialogBoxLayout";
import DialogBoxFileUploadLayout from "../../commonLayout/DialogBoxFileUploadLayout";
import ProgressDialogLayout from "../../commonLayout/ProgressDialogLayout";
import withMixpanelTracking from "../commonComponent/withMixPanelTracking";
import { truncateAtDot } from "../../helpers/truncateFileName";
import "../../styles/bible.css";

const styles = (theme) => ({
  componentTitle: {
    fontSize: "24px",
    paddingLeft: "15px",
    fontWeight: "400",
    color: "#333333",
  },

  input_box: {
    backgroundColor: "#ffffff",
    color: "#A0A09E",
  },
});

const AddEditBible = (props) => {
  const { action, tabs } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const classes = useCustomStyles(styles, theme);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const encodedData = query.get("data");
  const decodedData = decodeURIComponent(encodedData);
  const matches = useMediaQuery("(max-width:1440px)");
  const editRecords = JSON.parse(decodedData);

  const [bibleObject, setBibleObject] = useState({
    name: "",
    description: "",
    files: "",
    languageCode: "",
  });

  // state variable used to keep a track of the different behaviour of drag and drop and normal file upload
  const [toBeUploadedFileFormat, setToBeUploadedFileFormat] = useState({
    fileType: "",
    acceptedFileFormatForDragDrop: [],
    acceptedFileFormatForNormalFileUpload: [],
  });

  const [progress, setProgress] = useState(0);
  const [progressDialogOpen, setProgressDialogOpen] = useState(false);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [uploadFileName, setUploadFileName] = useState("");
  const [uploadInterval, setUploadInterval] = useState(null);
  const [fileName, setFileName] = useState("");
  const [fileError, setFileError] = useState(false);

  // Opens the file upload dialog for the specified type
  const handleOpen = (fileType, dragDropFileFormat, normalUploadFileFormat) => {
    setToBeUploadedFileFormat({
      ...toBeUploadedFileFormat,
      fileType: fileType,
      acceptedFileFormatForDragDrop: dragDropFileFormat,
      acceptedFileFormatForNormalFileUpload: normalUploadFileFormat,
    });
    setDialogOpen(true);
  };

  // Closes the progress dialog, stops the upload progress, and resets progress
  const handleProgressDialogClose = () => {
    setBibleObject({ ...bibleObject, files: "" });
    setFileName("");
    setProgressDialogOpen(false);
    setUploadInProgress(false);
    clearInterval(uploadInterval); // Stop the upload interval
    setProgress(0); // Reset the progress
  };

  // Handles file input change, sets the file name, and starts the upload process
  const handleFileChange = (event) => {
    setErrors(null);
    const file = event.target ? event.target.files[0] : event;

    if (file && file.type === "application/epub+zip") {
      setUploadFileName(file.name);
      setBibleObject({ ...bibleObject, files: file });
      setFileName(file.name);
      setDialogOpen(false);
      handleProgressDialogOpen();
      uploadFile(file);
      setFileError(false);
    } else {
      alert("Please upload a valid epub file.");
    }
  };

  // Opens the progress dialog and sets upload status
  const handleProgressDialogOpen = () => {
    setProgressDialogOpen(true);
    setUploadInProgress(true);
  };

  // Simulates file upload progress
  const uploadFile = (file) => {
    setProgress(0);

    const UploadProgress = () => {
      let progress = 0;

      const interval = setInterval(() => {
        progress += 10;
        setProgress(progress);

        if (progress >= 110) {
          clearInterval(interval);
          setUploadInProgress(false);
          setProgressDialogOpen(false);
        }
      }, 300);

      setUploadInterval(interval); // Save the interval ID to stop it later
    };
    UploadProgress();
  };

  const [errors, setErrors] = useState(null);

  const validateForm = () => {
    const newErrors = {};

    if (!bibleObject.name) newErrors.names = "Title  is required.";
    if (!bibleObject.description)
      newErrors.description = "Description is required.";
    if (!bibleObject.languageCode)
      newErrors.languageCode = "Language is required.";
    if (!bibleObject.files)
      newErrors.files = "Please upload a file.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  // Handles form submission
  const handleBibleUpload = async (event) => {
    event.preventDefault();

    if (!validateForm()) return;

    const formData = new FormData();

    formData.append("name", bibleObject?.name);
    formData.append("description", bibleObject?.description);
    formData.append("languageCode", bibleObject?.languageCode);
    formData.append("files", bibleObject.files);
    if (action === "edit" && !bibleObject.files) {
      let Records = editRecords;

      let data = {
        lastModified: Records.createdAt,
        lastModifiedDate: Records.updatedAt,
        name: Records.fileName,
        size: Records.fileSize,
        type: Records.fileType,
        webkitRelativePath: Records.filePath,
      };
      formData.append("files", data);
    } else {
      formData.append("files", bibleObject.files);
    }
    if (action === "edit") {
      props.updateBible(formData, editRecords.id);
      props.trackButtonClick("Update Bible Button", 1);
    } else {
      props.createBible(formData);
      props.trackButtonClick("Create Bible Button", 1);
    }
  };

  const resetInputFieldsOnApiSuccess = (successValue) => {
    if (successValue) {
      setBibleObject({
        ...bibleObject,
        name: "",
        description: "",
      });
      setFileName("");
    }
  };

  // function that will be responsible for closing the dialog box
  const handleCloseSuccessBox = () => {
    props.showUnshowDialogMessage();
    navigate(`/content/${tabs}/view`);
  };

  const handleChangeLanguage = (val) => {
    setBibleObject({
      ...bibleObject,
      languageCode: val.target.value,
    });
  };

  useEffect(() => {
    if (action === "edit") {
      let records = editRecords;

      setBibleObject({
        ...bibleObject,
        name: records?.name,
        files: records?.fileName,
        description: records?.description,
        languageCode: records?.languageCode,
      });
      setFileName(records?.fileName);
    }
  }, []);

  useEffect(() => {
    resetInputFieldsOnApiSuccess(props?.successResponse);
  }, [props?.successResponse]);

  return (
    <>
      {props.successResponse && (
        <DialogBoxLayout
          dialogBoxToggle={props?.successResponse}
          setDialogBoxToggle={handleCloseSuccessBox}
          header={
            action === "edit"
              ? "Updated Successfully!"
              : "Uploaded Successfully!"
          }
          content={
            action === "edit"
              ? "You Have Edited Your File Successfully"
              : "You Have Uploaded Your File Successfully"
          }
        />
      )}

      {dialogOpen && (
        <DialogBoxFileUploadLayout
          dialogOpen={dialogOpen}
          setDialogOpen={setDialogOpen}
          uploadInProgress={uploadInProgress}
          progress={progress}
          handleFileChange={handleFileChange}
          toBeUploadedFileFormat={toBeUploadedFileFormat}
        />
      )}

      {progressDialogOpen && (
        <ProgressDialogLayout
          progressDialogOpen={progressDialogOpen}
          handleProgressDialogClose={handleProgressDialogClose}
          toBeUploadedFileFormat={toBeUploadedFileFormat}
          uploadFileName={truncateAtDot(uploadFileName)}
          uploadInProgress={uploadInProgress}
          progress={progress}
        />
      )}

      <Grid
        xs={12}
        className={"bible_container"}
        component="form"
        onSubmit={handleBibleUpload}
        container
        spacing={4}
        mt={matches ? 0 : 2}
      >
        <Grid item sm={6}>
          <FormControl
            variant="outlined"
            sx={{ width: { xs: 200, md: 250, lg: 380, xl: 480 } }}
          >
            <TextField
              className={classes?.input_box}
              error={!!errors?.names}
              id="outlined-basic"
              placeholder="Enter Title"
              label="Title"
              size={matches ? "small" : ""}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={bibleObject?.name}
              onChange={(event) => {
                setErrors(null);
                setBibleObject({
                  ...bibleObject,
                  name: event.target.value,
                });
              }}
            />
            {errors?.names && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {errors?.names}
              </span>
            )}
          </FormControl>
        </Grid>
        <Grid item sm={6}>
          {" "}
          <FormControl
            variant="outlined"
            sx={{ width: { xs: 200, md: 250, lg: 380, xl: 480 }, mb: 2 }}
          >
            <TextField
              required
              className={classes?.input_box}
              id="outlined-basic"
              placeholder="EPUB File"
              label="File"
              variant="outlined"
              size={matches ? "small" : ""}
              error={!!errors?.files}
              value={truncateAtDot(fileName)}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <>
                    <Button
                      aria-label="upload"
                      component="label"
                      className="addFileButton"
                      onClick={() => {
                        setErrors(null);
                        handleOpen("EPUB", ["EPUB"], [".epub"]);
                      }}
                    >
                      Add File
                    </Button>
                  </>
                ),
              }}
            />
            {errors?.files && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {errors?.files}
              </span>
            )}
          </FormControl>
        </Grid>
        <Grid item sm={6}>
          <FormControl
            sx={{ width: { xs: 200, md: 250, lg: 380, xl: 480 } }}
            size={matches ? "small" : ""}
          >
            <TextField
              className={classes?.input_box}
              error={!!errors?.languageCode}
              InputLabelProps={{ shrink: true }}
              id="language"
              select
              variant="outlined"
              size={matches ? "small" : ""}
              sx={{
                paddingBottom: "0px",
                "& .MuiSelect-select span::before": {
                  color: "#A0A09E",
                  content: "'Choose Language'",
                },
              }}
              label="Language"
              value={bibleObject?.languageCode}
              onChange={(e) => {
                setErrors(null);
                handleChangeLanguage(e);
              }}
            >
              <MenuItem value="en_US">English</MenuItem>
              <MenuItem value="hi_IN">Hindi</MenuItem>
              <MenuItem value="pa_IN">Punjabi</MenuItem>
            </TextField>
            {errors?.languageCode && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {errors?.languageCode}
              </span>
            )}
          </FormControl>
        </Grid>
        <Grid item sm={6}>
          <FormControl
            variant="outlined"
            sx={{ width: { xs: 200, md: 250, lg: 380, xl: 480 } }}
          >
            <TextField
              className={classes?.input_box}
              error={!!errors?.description}
              id="outlined-multiline-static"
              size={matches ? "small" : ""}
              placeholder="Enter Description"
              label="Description"
              InputLabelProps={{ shrink: true }}
              multiline
              rows={6}
              value={bibleObject?.description}
              onChange={(event) => {
                setErrors(null);
                setBibleObject({
                  ...bibleObject,
                  description: event.target.value,
                });
              }}
            />
            {errors?.description && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {errors?.description}
              </span>
            )}
          </FormControl>
        </Grid>

        <Box className="bible_button_container">
          <Button
            variant="contained"
            type="submit"
            startIcon={<FileUploadOutlined />}
            className="bible_button"
            sx={{ textTransform: "none" }}
          >
            {action === "edit" ? "Update" : "Upload"}
          </Button>
        </Box>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  const { dialogMessage } = state.bibleReducer;

  return {
    successResponse: dialogMessage,
  };
};

export default connect(mapStateToProps, {
  createBible,
  showUnshowDialogMessage,
  updateBible,
})(withMixpanelTracking(AddEditBible, "Add Bible"));

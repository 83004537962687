import {
  CREATE_BIBLE,
  BIBLE_SUCCESS,
  BIBLE_FAILURE,
  DIALOG_MESSAGE,
  DELETE_BIBLE_FAILURE,
  DELETE_BIBLE_SUCCESS,
  DELETE_BIBLE,
  GET_BIBLE_DETAILS_BYID,
  GET_BIBLE_SUCCESS_DETAILS_BYID,
  GET_BIBLE_FAILURE_DETAILS_BYID,
  GET_BIBLE_DETAILS,
  GET_BIBLE_SUCCESS_DETAILS,
  GET_BIBLE_FAILURE_DETAILS,
  UPDATE_BIBLE,
  UPDATE_BIBLE_SUCCESS,
  UPDATE_BIBLE_FAILURE,
  DELETE_TOGGLE_MODAL_STATUS
} from "./action";

const initialState = {
  loading: false,
  error: "",
  bibleData: [],
  dialogMessage: false,
  deleteBiblesucces: false,
  bibleDataById: [],
  updateBibleSuccess: false,
  getBibleSucces: false,
};

export const bibleReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_BIBLE:
      return {
        ...state,

        error: "",
        dialogMessage: false,
        loading: true,
      };

    case BIBLE_SUCCESS:
      return {
        ...state,

        error: "",
        dialogMessage: true,
        loading: false,
      };

    case BIBLE_FAILURE:
      return {
        ...state,

        error: action.payload,
        dialogMessage: false,
        loading: false,
      };
    case UPDATE_BIBLE:
      return {
        ...state,

        error: "",
        dialogMessage: false,
        loading: true,
        updateBibleSuccess: false,
      };

    case UPDATE_BIBLE_SUCCESS:
      return {
        ...state,

        error: "",
        dialogMessage: true,
        updateBibleSuccess: true,
        loading: false,
      };

    case UPDATE_BIBLE_FAILURE:
      return {
        ...state,

        error: action.payload,
        dialogMessage: false,
        loading: false,
        updateBibleSuccess: false,
      };
    case DELETE_BIBLE:
      return {
        ...state,
        deleteBiblesucces: false,
        error: "",
      };
    case DELETE_BIBLE_SUCCESS:
      return {
        ...state,
        error: "",
        deleteBiblesucces: true,
      };
    case DELETE_BIBLE_FAILURE:
      return {
        ...state,
        error: action.payload,
        deleteBiblesucces: false,
      };
    case DIALOG_MESSAGE:
      return {
        ...state,
        dialogMessage: false,
      };
    case GET_BIBLE_DETAILS:
      return {
        ...state,
        bibleData: [],
        loading: true,
        error: "",
      };
    case GET_BIBLE_SUCCESS_DETAILS:
      return {
        ...state,
        bibleData: action.payload,
        loading: false,
        getBibleSucces: true,
        error: "",
      };
    case GET_BIBLE_FAILURE_DETAILS:
      return {
        ...state,
        bibleData: [],
        loading: false,
        getBibleSucces: false,
        error: action.payload,
      };
    case GET_BIBLE_DETAILS_BYID:
      return {
        ...state,
        bibleDataById: [],
        loading: true,
        error: "",
      };
    case GET_BIBLE_SUCCESS_DETAILS_BYID:
      return {
        ...state,
        bibleDataById: action.payload,
        loading: false,
        error: "",
      };
    case GET_BIBLE_FAILURE_DETAILS_BYID:
      return {
        ...state,
        bibleDataById: [],
        loading: false,
        error: action.payload,
      };

    case DELETE_TOGGLE_MODAL_STATUS:
      return {
          ...state,
          deleteBiblesucces: false,
      };

    default:
      return state;
  }
};

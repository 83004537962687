import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import DialogBoxLayout from "../../commonLayout/DialogBoxLayout";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { FileUploadOutlined } from "@mui/icons-material";
import {
  createDIOCESE,
  updateDIOCESE,
  showUnshowDialogMessage,
} from "../../redux/diocese/action";
import withMixpanelTracking from "../commonComponent/withMixPanelTracking";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  closeGeoLocation,
  getGeoLocation,
} from "../../redux/geoLocation/action";
import MapComponent from "./mapComponent";

const FieldName = [
  { key: "diocese", keyName: "Diocese", placeholder: "Type Diocese Name" },
  { key: "bishop", keyName: "Bishop", placeholder: "Type Bishop Name" },
  {
    key: "subdivision",
    keyName: "Subdivision",
    placeholder: "Type Diocese Subdivision",
  },
  {
    key: "established",
    keyName: "Established",
    placeholder: "Choose Diocese Established Date",
  },
  {
    key: "residence",
    keyName: "residence",
    placeholder: "Type Diocese Address",
  },
  { key: "latitude", keyName: "Latitude", placeholder: "Type Latitude" },
  { key: "longitude", keyName: "Longitude", placeholder: "Type Longitude" },
  { key: "pinCode", keyName: "Pin Code", placeholder: "Type Pin Code" },
];

const AddDiocese = (props) => {
  const [userData, setUserData] = useState({
    diocese: "",
    bishop: "",
    pinCode: "",
    established: "",
    subdivision: "",
    residence: "",
    latitude: "",
    longitude: "",
  });

  const matches = useMediaQuery("(max-width:1440px)");

  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const tabTypeEn = query.get("tabType");

  const navigate = useNavigate();
  const { action } = useParams();
  const [formErrors, setFormErrors] = useState({});
  const encodedData = query.get("data");
  const decodedData = decodeURIComponent(encodedData);

  const editRecords = JSON.parse(decodedData);
  const [openMap, setOpenMap] = useState(false);
  useEffect(() => {
    if (action === "edit") {
      const records = editRecords;

      setUserData({
        ...userData,

        ["diocese"]: records.diocese,
        ["bishop"]: records.bishop,
        ["pinCode"]: records.pinCode,
        ["established"]: records.established,
        ["subdivision"]: records.subdivision,
        ["residence"]: records.residence,
        ["latitude"]: records.latitude,
        ["longitude"]: records.longitude,
      });
    }
  }, [action]);

  const handleChange = (e) => {
    setFormErrors({});
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const errors = {};
    FieldName.forEach((field) => {
      if (!userData[field.key]) {
        errors[field.key] = `${field.keyName} is required`;
      }
    });
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      if (action === "edit") {
        props.updateDIOCESE(userData, editRecords.id);
        props.trackButtonClick("Update Diocese Button", 1);
      } else {
        props.createDIOCESE(userData);
        props.trackButtonClick("Create Diocese Button", 1);
      }
    }
  };

  const handleCloseSuccessBox = () => {
    props.showUnshowDialogMessage();

    navigate(`/church/view?tabType=${tabTypeEn}`);
  };

  const resetInputFieldsOnApiSuccess = (successValue) => {
    if (successValue) {
      setUserData({
        diocese: "",
        bishop: "",
        pinCode: "",
        established: "",
        subdivision: "",
        residence: "",
        latitude: "",
        longitude: "",
      });
    }
  };

  useEffect(() => {
    resetInputFieldsOnApiSuccess(props?.successResponse);
  }, [props?.successResponse]);

  useEffect(() => {
    if (props?.geoLocationSuccess) {
      let data = props.geoLocationData;

      setUserData({
        ...userData,
        latitude: data.lat,
        longitude: data.lon,
      });
      setOpenMap(true);
    }
  }, [props.geoLocationSuccess]);

  useEffect(() => {
    if (props?.geoLocationSuccess) {
      setOpenMap(true);
    }
  }, [userData?.latitude]);

  const handleCloseGeoLocation = () => {
    setOpenMap(false);
    props.closeGeoLocation();
  };

  const handleGetLocation = () => {
    if (userData?.residence && userData?.pinCode) {
      props.getGeoLocation(userData?.residence, userData?.pinCode);
    }
  };

  return (
    <>
      <DialogBoxLayout
        dialogBoxToggle={props?.DiocesedialogMessage}
        setDialogBoxToggle={handleCloseSuccessBox}
        header={
          action === "edit" ? "Edited Successfully" : "Added Successfully!"
        }
        content={
          action === "edit"
            ? "You Have Edited Diocese Successfully"
            : "You Have Added Diocese Successfully"
        }
      />

      <MapComponent
        open={openMap}
        handleDialogClose={handleCloseGeoLocation}
        geoData={userData}
        setUserData={setUserData}
      />

      <Grid
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <KeyboardBackspaceIcon
          style={{
            fontSize: "30px",
            fontWeight: 600,
            color: "black",
            fontFamily: "inter",
            cursor: "pointer",
            marginRight: 10,
          }}
          onClick={() => {
            navigate(`/church/view?tabType=${tabTypeEn}`);
          }}
        />
        <Typography
          style={{
            fontSize: "1.2rem",
            fontWeight: 600,
            color: "black",
            fontFamily: "inter",
          }}
        >
          {action === "edit" ? " Edit Diocese" : "Add Diocese"}
        </Typography>
      </Grid>
      <Grid
        xs={12}
        sx={{
          backgroundColor: "var(--primary-color)",
          borderRadius: 2,
          width: "auto",
        }}
      >
        <Box component="form" onSubmit={handleFormSubmit}>
          <Grid
            container
            spacing={matches ? 4 : 8}
            sx={{
              paddingX: matches ? "20px" : "40px",
              marginTop: "0px",
              paddingBottom: matches ? "10px" : "40px",
            }}
          >
            {FieldName.map((item) => (
              <Grid
                item
                xs={12}
                sm={item.key === "latitude" || item.key === "longitude" ? 3 : 6}
                key={item.key}
                sx={item.key === "pinCode" && { marginTop: "-30px" }}
              >
                <TextField
                  fullWidth
                  id={item.key}
                  name={item.key}
                  label={`${item.keyName}*`}
                  placeholder={item.placeholder}
                  type={item.key === "established" && "date"}
                  size={matches ? "small" : ""}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  sx={{ backgroundColor: "white", borderRadius: "10px" }}
                  value={userData[item.key]}
                  onChange={handleChange}
                  rows={item.keyName === "Address" ? 4 : undefined}
                  multiline={item.keyName === "Address"}
                  error={!!formErrors[item.key]}
                />
                {formErrors[item.key] && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {item.keyName} is required
                  </span>
                )}
                {item.key === "longitude" ? (
                  <Button
                    className="coords"
                    disabled={
                      userData.residence.length <= 0 ||
                      userData.pinCode.length <= 0
                    }
                    onClick={handleGetLocation}
                    sx={{
                      paddingX: 0,
                      width: "fit-content",
                      float: "right",
                      "&:hover": {
                        color: "#eb4c13",
                        background: "none",
                      },
                    }}
                  >
                    {userData.residence.length <= 0 ||
                    userData.pinCode.length <= 0 ? (
                      <img
                        src="/grey-location.svg"
                        alt="longitude"
                        className="coords-img"
                      />
                    ) : (
                      <img
                        src="/orange-location.svg"
                        alt="longitude"
                        className="coords-img"
                      />
                    )}
                    {props.geoLoader
                      ? "Fetching Geo Location"
                      : "Get Coordinates"}
                  </Button>
                ) : (
                  ""
                )}
              </Grid>
            ))}
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="end"
              gap={"20px"}
              sx={{ marginTop: { lg: "-20px", xl: "80px" } }}
            >
              <Button
                type="submit"
                variant="contained"
                startIcon={
                  action === "Add" ? (
                    <AddCircleOutlineOutlinedIcon />
                  ) : (
                    <FileUploadOutlined />
                  )
                }
                sx={{
                  textTransform: "none",
                  fontSize: { lg: "15px", xl: "20px" },
                  paddingX: { xl: "30px" },
                  fontWeight: "400",
                }}
                size={matches ? "small" : "large"}
              >
                {action === "Add" ? "Create" : "Update"}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    DioceseData,

    DioceseupdateResponse,
    DiocesedeleteResponse,
    DiocesedialogMessage,
  } = state.DIOCESEReducer;
  const { geoLocationData, geoLocationSuccess, geoLoader } =
    state.geoLocationReducer;

  return {
    DioceseData,

    DioceseupdateResponse,
    DiocesedeleteResponse,
    DiocesedialogMessage,
    geoLocationData,
    geoLocationSuccess,
    geoLoader,
  };
};

export default connect(mapStateToProps, {
  createDIOCESE,
  updateDIOCESE,
  showUnshowDialogMessage,
  getGeoLocation,
  closeGeoLocation,
})(withMixpanelTracking(AddDiocese, " Add Diocese"));

import { call, put, takeEvery } from "redux-saga/effects";

import {
  CREATE_BIBLE,
  bibleSuccess,
  bibleFailure,
  DELETE_BIBLE,
  deleteBibleFails,
  deleteBibleSuccess,
  GET_BIBLE_DETAILS_BYID,
  GET_BIBLE_DETAILS,
  getBibledetailsFailByID,
  getBibledetailsSuccessByID,
  getBibledetailsFail,
  getBibledetailsSuccess,
  updatebibleSuccess,
  updatebibleFailure,
  UPDATE_BIBLE,
} from "./action";

import {
  postRequest,
  deleteRequest,
  getRequest,
  putRequest,
} from "../../apiServices/ApiActions";
import {
  addBibleUrl,
  deleteBibleUrl,
  getBibleUrls,
  upDateBibleUrls,
} from "../../apiServices/ApiUrl";

const addBible = async (payload) => {
  return postRequest(addBibleUrl, payload);
};

const deleteBibleData = async (payload) => {
  return deleteRequest(deleteBibleUrl + `${payload}`);
};

const getbibleDetails = async (page) => {
  return getRequest(`${getBibleUrls}?page=${--page}&size=${10}`);
};

const updateBiblebyId = (payload, id) => {
  return putRequest(upDateBibleUrls + `${id}`, payload);
};

function* createBible(action) {
  try {
    const result = yield call(addBible, action.payload);

    if (result.status === 200) {
      yield put(bibleSuccess(result));
    } else {
      let res = result?.response?.data;
      yield put(bibleFailure(res)); 
    }
  } catch (error) {
    yield put(bibleFailure(error?.response?.data));
  }
}

function* updateBible(action) {
  try {
    const result = yield call(updateBiblebyId, action.payload, action.id);

    if (result.status === 200) {
      yield put(updatebibleSuccess(result));
    } else {
      let res = result?.response?.data;
      yield put(updatebibleFailure(res));  
    }
  } catch (error) {
    yield put(updatebibleFailure(error?.response?.data));
  }
}

function* deleteBible(action) {
  try {
    const result = yield call(deleteBibleData, action.payload);

    if (result.status === 204 || result.status === 200) {
      yield put(deleteBibleSuccess(result));
    } else {
      let res = result?.response?.data;
      yield put(deleteBibleFails(res));

    }
  } catch (error) {
    yield put(deleteBibleFails(error?.response?.data));
  }
}
function* getBible(action) {
  try {
    const result = yield call(getbibleDetails, action.params);

    if (result.status === 200) {
      let response = result.data;
      yield put(getBibledetailsSuccess(response));
    } else {
      let res = result?.response?.data;
      yield put(getBibledetailsFail(res));    
    }
  } catch (error) {
    yield put(getBibledetailsFail(error?.response?.data));
  }
}

function* getBibleById(action) {
  try {
    const result = yield call(deleteBibleData, action.payload);

    if (result.status === 200) {
      yield put(getBibledetailsSuccessByID(result));
    } else {
      let res = result?.response?.data;
      yield put(getBibledetailsFailByID(res)); 
    }
  } catch (error) {
    yield put(getBibledetailsFailByID(error?.response?.data));
  }
  
}

function* bibleSaga() {
  yield takeEvery(CREATE_BIBLE, createBible);
  yield takeEvery(DELETE_BIBLE, deleteBible);
  yield takeEvery(GET_BIBLE_DETAILS, getBible);
  yield takeEvery(GET_BIBLE_DETAILS_BYID, getBibleById);
  yield takeEvery(UPDATE_BIBLE, updateBible);
}

export default bibleSaga;

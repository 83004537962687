import { all, fork } from "redux-saga/effects";
import communitySaga from "./community/community-saga";
import eventsSaga from "./Events/events-saga";
import bibleSaga from "./bible/bible-saga";
import dailyVerseSaga from "./dailyVerse/daily-verse-saga";
import loginSaga from "./login/login-saga";
import prayerSaga from "./prayer/prayer-saga";
import languageSaga from "./language/language-saga";
import volunteerSaga from "./volunteer/volunteer-saga";
import helpsSaga from "./help-centre/helps-saga";
import userSaga from "./usermanagement/usermanagement-saga";
import gospelSaga from "./gospel/gospel-saga";
import CHURCHSaga from "./church/church-saga";
import profileSaga from "./userprofile/userprofile-saga";
import quizSaga from "./quiz/quiz-saga";
import dashboardSaga from "./dashboard/dashboard-saga";
import deleteRequestSaga from "./delete-request/delete-request-saga";
import SingerSaga from "./singer/singer-saga";
import albumSaga from "./album/album-saga";
import PaneSaga from "./navigationaPane/pane-saga";
import snackbarSaga from "./snackbar/snackbar-saga";
import geoLocationSaga from "./geoLocation/geoLocation-saga";
import DIOCESESaga from "./diocese/diocese-saga";

export default function* rootSaga() {
  yield all([
    fork(DIOCESESaga),
    fork(communitySaga),
    fork(geoLocationSaga),
    fork(snackbarSaga),
    fork(eventsSaga),
    fork(bibleSaga),
    fork(dailyVerseSaga),
    fork(loginSaga),
    fork(prayerSaga),
    fork(languageSaga),
    fork(volunteerSaga),
    fork(helpsSaga),
    fork(userSaga),
    fork(gospelSaga),
    fork(CHURCHSaga),
    fork(profileSaga),
    fork(quizSaga),
    fork(dashboardSaga),
    fork(deleteRequestSaga),
    fork(SingerSaga),
    fork(albumSaga),
    fork(PaneSaga),
  ]);
}

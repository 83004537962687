export const CREATE_GOSPEL_WITH_SINGER = "CREATE_GOSPEL_WITH_SINGER";
export const CREATE_GOSPEL_WITH_SINGER_SUCCESS =
  "CREATE_GOSPEL_WITH_SINGER_SUCCESS";
export const CREATE_GOSPEL_WITH_SINGER_FAILURE =
  "CREATE_GOSPEL_WITH_SINGER_FAILURE";

export const UPDATE_GOSPEL_WITH_SINGER = "UPDATE_GOSPEL_WITH_SINGER";
export const UPDATE_GOSPEL_WITH_SINGER_SUCCESS =
  "UPDATE_GOSPEL_WITH_SINGER_SUCCESS";
export const UPDATE_GOSPEL_WITH_SINGER_FAILURE =
  "UPDATE_GOSPEL_WITH_SINGER_FAILURE";

export const CREATE_GOSPEL_WITH_ALBUM = "CREATE_GOSPEL_WITH_ALBUM";
export const CREATE_GOSPEL_WITH_ALBUM_SUCCESS =
  "CREATE_GOSPEL_WITH_ALBUM_SUCCESS";
export const CREATE_GOSPEL_WITH_ALBUM_FAILURE =
  "CREATE_GOSPEL_WITH_ALBUM_FAILURE";

export const UPDATE_GOSPEL_WITH_ALBUM = "UPDATE_GOSPEL_WITH_ALBUM";
export const UPDATE_GOSPEL_WITH_ALBUM_SUCCESS =
  "UPDATE_GOSPEL_WITH_ALBUM_SUCCESS";
export const UPDATE_GOSPEL_WITH_ALBUM_FAILURE =
  "UPDATE_GOSPEL_WITH_ALBUM_FAILURE";

export const GET_GOSPEL_WITH_ALBUM = "GET_GOSPEL_WITH_ALBUM";
export const GET_GOSPEL_WITH_ALBUM_SUCCESS = "GET_GOSPEL_WITH_ALBUM_SUCCESS";
export const GET_GOSPEL_WITH_ALBUM_FAILURE = "GET_GOSPEL_WITH_ALBUM_FAILURE";

export const GET_GOSPEL_WITH_SINGER = "GET_GOSPEL_WITH_SINGER";
export const GET_GOSPEL_WITH_SINGER_SUCCESS = "GET_GOSPEL_WITH_SINGER_SUCCESS";
export const GET_GOSPEL_WITH_SINGER_FAILURE = "GET_GOSPEL_WITH_SINGER_FAILURE";

export const DELETE_SONG_SINGER = "DELETE_SONG_SINGER";
export const DELETE_SONG_SINGER_SUCCESS = "DELETE_SONG_SINGER_SUCCESS";
export const DELETE_SONG_SINGER_FAILURE = "DELETE_SONG_SINGER_FAILURE";

export const DELETE_SONG_ALBUM = "DELETE_SONG_ALBUM";
export const DELETE_SONG_ALBUM_SUCCESS = "DELETE_SONG_ALBUM_SUCCESS";
export const DELETE_SONG_ALBUM_FAILURE = "DELETE_SONG_ALBUM_FAILURE";

export const DIALOG_MESSAGE = "DIALOG_MESSAGE";
export const DELETE_TOGGLE_MODAL_STATUS = "DELETE_TOGGLE_MODAL_STATUS";

export const showUnshowDialogMessage = () => {
  return {
    type: DIALOG_MESSAGE,
  };
};

export const createGospelWithSinger = (payload) => {
  return {
    type: CREATE_GOSPEL_WITH_SINGER,
    payload,
  };
};

export const createGospelWithSingerSuccess = () => {
  return {
    type: CREATE_GOSPEL_WITH_SINGER_SUCCESS,
  };
};

export const createGospelWithSingerFail = () => {
  return {
    type: CREATE_GOSPEL_WITH_SINGER_FAILURE,
  };
};

export const updateGospelWithSinger = (payload, id) => {
  return {
    type: UPDATE_GOSPEL_WITH_SINGER,
    payload,
    id,
  };
};

export const updateGospelWithSingerSuccess = () => {
  return {
    type: UPDATE_GOSPEL_WITH_SINGER_SUCCESS,
  };
};

export const updateGospelWithSingerFail = () => {
  return {
    type: UPDATE_GOSPEL_WITH_SINGER_FAILURE,
  };
};

export const createGospelWithAlbum = (payload) => {
  return {
    type: CREATE_GOSPEL_WITH_ALBUM,
    payload,
  };
};

export const createGospelWithAlbumSuccess = () => {
  return {
    type: CREATE_GOSPEL_WITH_ALBUM_SUCCESS,
  };
};

export const createGospelWithAlbumFail = () => {
  return {
    type: CREATE_GOSPEL_WITH_ALBUM_FAILURE,
  };
};

export const updateGospelWithAlbum = (payload, id) => {
  return {
    type: UPDATE_GOSPEL_WITH_ALBUM,
    payload,
    id,
  };
};

export const updateGospelWithAlbumSuccess = () => {
  return {
    type: UPDATE_GOSPEL_WITH_ALBUM_SUCCESS,
  };
};

export const updateGospelWithAlbumFail = () => {
  return {
    type: UPDATE_GOSPEL_WITH_ALBUM_FAILURE,
  };
};

export const getGospelWithAlbum = () => {
  return {
    type: GET_GOSPEL_WITH_ALBUM,
  };
};

export const getGospelWithAlbumSuccess = (payload) => {
  return {
    type: GET_GOSPEL_WITH_ALBUM_SUCCESS,
    payload,
  };
};

export const getGospelWithAlbumFail = () => {
  return {
    type: GET_GOSPEL_WITH_ALBUM_FAILURE,
  };
};

export const getGospelWithSinger = () => {
  return {
    type: GET_GOSPEL_WITH_SINGER,
  };
};

export const getGospelWithSingerSuccess = (payload) => {
  return {
    type: GET_GOSPEL_WITH_SINGER_SUCCESS,
    payload,
  };
};

export const getGospelWithSingerFail = () => {
  return {
    type: GET_GOSPEL_WITH_SINGER_FAILURE,
  };
};

export const deleteAlbumSong = (id) => {
  return {
    type: DELETE_SONG_ALBUM,
    payload: id,
  };
};

export const deleteAlbumSongSuccess = () => {
  return {
    type: DELETE_SONG_ALBUM_SUCCESS,
  };
};

export const deleteAlbumSongFailure = (error) => {
  return {
    type: DELETE_SONG_ALBUM_FAILURE,
    payload: error,
  };
};

export const deleteSingerSong = (id) => {
  return {
    type: DELETE_SONG_SINGER,
    id,
  };
};

export const deleteSingerSongSuccess = () => {
  return {
    type: DELETE_SONG_SINGER_SUCCESS,
  };
};

export const deleteSingerSongFailure = (error) => {
  return {
    type: DELETE_SONG_SINGER_FAILURE,
    payload: error,
  };
};

// defining action for enabling/disabling delete modal
export const deleteToggleModal = () => {
  return {
    type: DELETE_TOGGLE_MODAL_STATUS,
  };
};

import {
  ADMIN_LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  ADMIN_LOGOUT,
  LOGIN_UPDATE_FAIL,
  LOGIN_UPDATE_SUCCESS,
  ADMIN_UPDATE,
  UPDATE_POLICY_SUCCESS,
  UPDATE_POLICY_FAILURE,
  UPDATE_POLICY,
} from "./action";

const initialState = {
  success: false,
  loading: false,
  error: "",
  data: [],
  loginUpdatestatus: false,
  loginUpdateData: [],
  updatePolicyStatus: false,
};

export const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_LOGIN:
      return {
        ...state,
        data: [],
        error: "",
        loading: true,
        // success: false,
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: "",
        success: true,
        loading: false,
      };

    case LOGIN_FAILURE:
      return {
        ...state,
        data: [],
        error: action.payload,
        success: false,
        loading: false,
      };

    case ADMIN_UPDATE:
      return {
        ...state,
        loginUpdatestatus: false,
        loginUpdateData: [],
      };

    case LOGIN_UPDATE_SUCCESS:
      return {
        ...state,
        loginUpdatestatus: true,
        loginUpdateData: action.payload,
      };

    case LOGIN_UPDATE_FAIL:
      return {
        ...state,
        loginUpdatestatus: false,
        loginUpdateData: [],
      };
    case ADMIN_LOGOUT:
      return {
        ...state,
        data: [],
        error: "",
        success: false,
        loginUpdatestatus: false,
        loginUpdateData: [],
      };
    case UPDATE_POLICY:
      return {
        ...state,
        updatePolicyStatus: false,
      };
    case UPDATE_POLICY_SUCCESS:
      return {
        ...state,
        updatePolicyStatus: true,
      };
    case UPDATE_POLICY_FAILURE:
      return {
        ...state,
        updatePolicyStatus: false,
      };

    default:
      return state;
  }
};

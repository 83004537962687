import { call, put, takeEvery } from "redux-saga/effects";

import {
  GET_VOLUNTEER_BY_STATUS,
  GET_VOLUNTEER_DETAILS,
  GET_VOLUNTEER_DOWNLOAD,
  GET_VOLUNTEER_LIST,
  getVolunteerByStatusFail,
  getVolunteerByStatusSuccess,
  getVolunteerdetailsFail,
  getVolunteerdetailsSuccess,
  getVolunteerDownloadFailure,
  getVolunteerDownloadSuccess,
  getVolunteerListFail,
  getVolunteerListSuccess,
  UPDATE_VOLUNTEER_BY_STATUS,
  updateVolunteerByStatusFail,
  updateVolunteerByStatusSuccess,
} from "./action";

import { saveAs } from "file-saver";

import { getRequest, putRequest } from "../../apiServices/ApiActions";
import {
  getEventsUrl,
  getVolunteeByStatusUrls,
  getVolunteerListUrls,
  updateVolunteerStatusUrls,
} from "../../apiServices/ApiUrl";
import { getVolunteerDownloadService } from "../../apiServices/volunteer";
import { showSnackbar } from "../snackbar/action";

const getVolunteerDetails = async (payload) => {
  return getRequest(getEventsUrl);
};

const getVolunteerlist = async (id) => {
  return getRequest(getVolunteerListUrls + `${id}`);
};
const getVolunteerStatus = async () => {
  return getRequest(getVolunteeByStatusUrls);
};
const updateStatus = async (id, status) => {
  return putRequest(updateVolunteerStatusUrls + `${id}/${status}`);
};

const getVolunteerDownloadServiceCall = async (status, id) => {
  return getVolunteerDownloadService(status, id);
};

function* getVolunteer(action) {
  try {
    const result = yield call(getVolunteerDetails, action.payload);

    if (result.status === 200) {
      let response = result.data;
      yield put(getVolunteerdetailsSuccess(response));
    } else {
      let res = result?.response?.data;
      yield put(getVolunteerdetailsFail(result));

      if (res) {
        yield put(alert(res.message));
      }
    }
  } catch (error) {
    yield put(getVolunteerdetailsFail(error));
  }
}

function* getVolunteerDownload(action) {
  try {
    const result = yield call(
      getVolunteerDownloadServiceCall,
      action.payload.status,
      action.payload.id,
    );

    if (result.status === 200) {
      const blob = new Blob([result.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });

      saveAs(blob, "Volunteer.xlsx");
      yield put(getVolunteerDownloadSuccess(result));
    } else if (result.status === 204) {
      yield put(
        getVolunteerDownloadFailure({
          message: "No content to download",
        }),
      );
    } else {
      let res = result?.response?.data;
      yield put(getVolunteerDownloadFailure(result));

      if (res) {
        yield put(alert(res.message));
      }
    }
  } catch (error) {
    yield put(getVolunteerDownloadFailure(error));
  }
}

function* getVolunteerList(action) {
  try {
    const result = yield call(getVolunteerlist, action.payload);

    if (result.status === 200) {
      let response = result.data.content;
      yield put(getVolunteerListSuccess(response, result.data.page));
    } else {
      let res = result?.response?.data;
      yield put(getVolunteerListFail(result));
    }
  } catch (error) {
    yield put(getVolunteerListFail(error));
  }
}
function* getStatusListById(action) {
  try {
    const result = yield call(getVolunteerStatus, action.payload);

    if (result.status === 200) {
      let response = result.data.content;
      yield put(getVolunteerByStatusSuccess(response));
    } else {
      let res = result?.response?.data;
      yield put(getVolunteerByStatusFail(result));

      if (res) {
        yield put(alert(res.message));
      }
    }
  } catch (error) {
    yield put(getVolunteerByStatusFail(error));
  }
}
function* updateStatusById(action) {
  try {
    const result = yield call(updateStatus, action.id, action.status);

    if (result.status === 200) {
      let response = result.data.content;
      yield put(updateVolunteerByStatusSuccess(response));
      yield put(
        showSnackbar({
          message: `Volunteer ${action.status} successfully.`,
          severity: "success",
        }),
      );
    } else {
      let res = result?.response?.data;
      yield put(updateVolunteerByStatusFail(result));

      if (res) {
        yield put(alert(res.message));
      }
    }
  } catch (error) {
    yield put(updateVolunteerByStatusFail(error));
  }
}
function* volunteerSaga() {
  yield takeEvery(GET_VOLUNTEER_DETAILS, getVolunteer);
  yield takeEvery(GET_VOLUNTEER_LIST, getVolunteerList);
  yield takeEvery(GET_VOLUNTEER_BY_STATUS, getStatusListById);
  yield takeEvery(UPDATE_VOLUNTEER_BY_STATUS, updateStatusById);
  yield takeEvery(GET_VOLUNTEER_DOWNLOAD, getVolunteerDownload);
}

export default volunteerSaga;

import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";

import "../../styles/SearchInput.css";

const SearchInput = ({ tableData, setSearchedTable }) => {
  const [searched, setSearched] = useState("");

  const requestSearch = (e) => {
    let text = e.target.value;

    setSearched(text);
    if (tableData && tableData?.length > 0) {
      const filteredData = tableData?.filter((row) => {
        if (row.name && row.locationName) {
          return Object.values(row).some((value) =>
            value?.toString().toLowerCase().includes(text.toLowerCase()),
          );
        } else if (row.name) {
          return row?.name
            ?.toString()
            .toLowerCase()
            .includes(text.toLowerCase());
        } else if (row.songName) {
          return row?.songName
            ?.toString()
            .toLowerCase()
            .includes(text.toLowerCase());
        } else if (row.title) {
          return row?.title
            ?.toString()
            .toLowerCase()
            .includes(text.toLowerCase());
        } else {
          return Object.values(row).some((value) =>
            value?.toString().toLowerCase().includes(text.toLowerCase()),
          );
        }
      });

      setSearchedTable(filteredData);
    }
  };

  return (
    <div className="search__input--container">
      <input
        className="search__input"
        value={searched}
        placeholder="Search"
        onChange={(e) => requestSearch(e)}
      />
      <SearchIcon className="search__input--icon" color="#666666" />
    </div>
  );
};

export default SearchInput;

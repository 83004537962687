import { call, put, takeEvery } from "redux-saga/effects";
import {
  uploadGospelWithSongUrl,
  uploadGospelWithAlbumUrl,
  updateGospelWithSongUrl,
  updateGospelWithAlbumUrl,
  getAllGospelWithAlbum,
  getAllGospelWithSinger,
  deleteSongWitlSingerUrls,
  deleteSongWitlAlbumUrls,
} from "../../apiServices/ApiUrl";
import {
  getRequest,
  putRequest,
  deleteRequest,
  postRequest,
} from "../../apiServices/ApiActions";
import {
  CREATE_GOSPEL_WITH_SINGER,
  UPDATE_GOSPEL_WITH_SINGER,
  CREATE_GOSPEL_WITH_ALBUM,
  UPDATE_GOSPEL_WITH_ALBUM,
  GET_GOSPEL_WITH_ALBUM,
  GET_GOSPEL_WITH_SINGER,
  DELETE_SONG_SINGER,
  DELETE_SONG_ALBUM,
  createGospelWithSingerSuccess,
  createGospelWithSingerFail,
  updateGospelWithSingerFail,
  createGospelWithAlbumSuccess,
  createGospelWithAlbumFail,
  updateGospelWithAlbumSuccess,
  updateGospelWithAlbumFail,
  updateGospelWithSingerSuccess,
  getGospelWithSingerFail,
  getGospelWithSingerSuccess,
  getGospelWithAlbumFail,
  getGospelWithAlbumSuccess,
  deleteAlbumSongSuccess,
  deleteAlbumSongFailure,
  deleteSingerSongSuccess,
  deleteSingerSongFailure,
} from "./action";

import { showSnackbar } from "../snackbar/action";

const createCallWithSinger = async (payload) => {
  return postRequest(uploadGospelWithSongUrl, payload);
};

const createCallWithAlbum = async (payload) => {
  return postRequest(uploadGospelWithAlbumUrl, payload);
};

const updateCallWithSinger = async (payload, id) => {
  return putRequest(updateGospelWithSongUrl + `${id}`, payload);
};

const updateCallWithAlbum = async (payload, id) => {
  return putRequest(updateGospelWithAlbumUrl + `${id}`, payload);
};

const getCallWithAlbum = async () => {
  return getRequest(getAllGospelWithAlbum);
};

const getCallWithSinger = async () => {
  return getRequest(getAllGospelWithSinger);
};

const deleteCallWithSinger = async (id) => {
  return deleteRequest(deleteSongWitlSingerUrls + `${id}`);
};
const deleteCallWithAlbum = async (id) => {
  return deleteRequest(deleteSongWitlAlbumUrls + `${id}`);
};

function* createGospelsWithSinger(action) {
  try {
    const result = yield call(createCallWithSinger, action.payload);
    if (result.status === 200) {
      yield put(createGospelWithSingerSuccess(result));
    } else {
      yield put(createGospelWithSingerFail(result));
      yield put(
        showSnackbar({
          message: result.message,
          severity: "error",
        }),
      );
    }
  } catch (error) {
    yield put(createGospelWithSingerFail(error));
  }
}

function* updateGospelsWithSinger(action) {
  try {
    const result = yield call(updateCallWithSinger, action.payload, action.id);
    if (result.status === 200) {
      yield put(updateGospelWithSingerSuccess(result));
    } else {
      yield put(updateGospelWithSingerFail(result));
      yield put(
        showSnackbar({
          message: "Song Is Not Updated.",
          severity: "error",
        }),
      );
    }
  } catch (error) {
    yield put(updateGospelWithSingerFail(error));
  }
}

function* createGospelsWithAlbum(action) {
  try {
    const result = yield call(createCallWithAlbum, action.payload);
    if (result.status === 200) {
      yield put(createGospelWithAlbumSuccess(result));
    } else {
      yield put(createGospelWithAlbumFail(result));
      yield put(
        showSnackbar({
          message: result.message,
          severity: "error",
        }),
      );
    }
  } catch (error) {
    yield put(createGospelWithAlbumFail(error));
  }
}

function* updateGospelsWithAlbum(action) {
  try {
    const result = yield call(updateCallWithAlbum, action.payload, action.id);
    if (result.status === 200) {
      yield put(updateGospelWithAlbumSuccess(result));
    } else {
      yield put(updateGospelWithAlbumFail(result));
    }
  } catch (error) {
    yield put(updateGospelWithAlbumFail(error));
  }
}

function* getGospelWithSinger(action) {
  try {
    const result = yield call(getCallWithSinger);

    if (result.status === 200) {
      let response = result.data;
      yield put(getGospelWithSingerSuccess(response.singers));
    } else {
      yield put(getGospelWithSingerFail(result));
    }
  } catch (error) {
    yield put(getGospelWithSingerFail(error));
  }
}

function* deleteAlbumsSong(action) {
  try {
    const result = yield call(deleteCallWithAlbum, action.id);
    if (result.status === 204 || result.status === 200) {
      yield put(deleteAlbumSongSuccess(result));
    } else {
      let res = result?.response?.data;
      yield put(deleteAlbumSongFailure(result));
      yield put(
        showSnackbar({
          message: res.message,
          severity: "success",
        }),
      );
    }
  } catch (error) {
    yield put(deleteAlbumSongFailure(error));
  }
}

function* deleteSingerSong(action) {
  try {
    const result = yield call(deleteCallWithSinger, action.id);
    if (result.status === 204 || result.status === 200) {
      yield put(deleteSingerSongSuccess(result));
      yield put(
        showSnackbar({
          message: "Song Deleted successfully!",
          severity: "success",
        }),
      );
    } else {
      let res = result?.response?.data;

      yield put(deleteSingerSongFailure(result));
      yield put(
        showSnackbar({
          message: res?.message,
          severity: "error",
        }),
      );
    }
  } catch (error) {
    yield put(deleteSingerSongFailure(error));
  }
}

function* getGospelWithAlbum(action) {
  try {
    const result = yield call(getCallWithAlbum);

    if (result.status === 200) {
      let response = result.data;
      yield put(getGospelWithAlbumSuccess(response.albums));
    } else {
      yield put(getGospelWithAlbumFail(result));
    }
  } catch (error) {
    yield put(getGospelWithAlbumFail(error));
  }
}

function* gospelSaga() {
  yield takeEvery(CREATE_GOSPEL_WITH_SINGER, createGospelsWithSinger);
  yield takeEvery(UPDATE_GOSPEL_WITH_SINGER, updateGospelsWithSinger);
  yield takeEvery(CREATE_GOSPEL_WITH_ALBUM, createGospelsWithAlbum);
  yield takeEvery(UPDATE_GOSPEL_WITH_ALBUM, updateGospelsWithAlbum);
  yield takeEvery(GET_GOSPEL_WITH_SINGER, getGospelWithSinger);
  yield takeEvery(GET_GOSPEL_WITH_ALBUM, getGospelWithAlbum);
  yield takeEvery(DELETE_SONG_ALBUM, deleteAlbumsSong);
  yield takeEvery(DELETE_SONG_SINGER, deleteSingerSong);
}
export default gospelSaga;

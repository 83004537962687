export const CREATE_CHURCH = "CREATE_CHURCH";
export const CREATE_CHURCH_SUCCESS = "CREATE_CHURCH_SUCCESS";
export const CREATE_CHURCH_FAILURE = "CREATE_CHURCH_FAILURE";

export const GET_CHURCH = "GET_CHURCH";
export const GET_CHURCH_SUCCESS = "GET_CHURCH_SUCCESS";
export const GET_CHURCH_FAILURE = "GET_CHURCH_FAILURE";

export const UPDATE_CHURCH = "UPDATE_CHURCH";
export const UPDATE_CHURCH_SUCCESS = "UPDATE_CHURCH_SUCCESS";
export const UPDATE_CHURCH_FAILURE = "UPDATE_CHURCH_FAILURE";

export const UPDATE_CHURCH_STATUS = "UPDATE_CHURCH_STATUS";
export const UPDATE_CHURCH_STATUS_SUCCESS = "UPDATE_CHURCH_STATUS_SUCCESS";
export const UPDATE_CHURCH_STATUS_FAILURE = "UPDATE_CHURCH_STATUS_FAILURE";

export const DELETE_CHURCH = "DELETE_CHURCH";
export const DELETE_CHURCH_SUCCESS = "DELETE_CHURCH_SUCCESS";
export const DELETE_CHURCH_FAILURE = "DELETE_CHURCH_FAILURE";

export const DIALOG_MESSAGE = "DIALOG_MESSAGE";

export const DELETE_TOGGLE_MODAL_STATUS = "DELETE_TOGGLE_MODAL_STATUS";

export const deleteToggleModal = () => {
  return {
    type: DELETE_TOGGLE_MODAL_STATUS,
  };
};

export const getAllCHURCH = (payload) => {
  return {
    type: GET_CHURCH,
    payload,
  };
};

export const getAllCHURCHSuccess = (data, page) => {
  return {
    type: GET_CHURCH_SUCCESS,
    payload: data,
    page,
  };
};

export const getAllCHURCHFailure = (error) => {
  return {
    type: GET_CHURCH_FAILURE,
    payload: error,
  };
};

export const createCHURCH = (data) => {
  return {
    type: CREATE_CHURCH,
    payload: data,
  };
};
export const createCHURCHSuccess = (data) => {
  return {
    type: CREATE_CHURCH_SUCCESS,
    payload: data,
  };
};
export const createCHURCHFailure = (error) => {
  return {
    type: CREATE_CHURCH_FAILURE,
    payload: error,
  };
};

export const updateCHURCH = (payload, id) => {
  return {
    type: UPDATE_CHURCH,
    payload,
    id,
  };
};

export const updateCHURCHSuccess = (data) => {
  return {
    type: UPDATE_CHURCH_SUCCESS,
    payload: data,
  };
};

export const updateCHURCHFailure = (error) => {
  return {
    type: UPDATE_CHURCH_FAILURE,
    payload: error,
  };
};

export const updateCHURCHStatus = (id, status, rowName) => {
  return {
    type: UPDATE_CHURCH_STATUS,
    id,
    status,
    rowName,
  };
};

export const updateCHURCHStatusSuccess = (data) => {
  return {
    type: UPDATE_CHURCH_STATUS_SUCCESS,
    payload: data,
  };
};

export const updateCHURCHStatusFailure = (error) => {
  return {
    type: UPDATE_CHURCH_STATUS_FAILURE,
    payload: error,
  };
};

export const deleteCHURCH = (id) => {
  return {
    type: DELETE_CHURCH,
    payload: id,
  };
};

export const deleteCHURCHSuccess = () => {
  return {
    type: DELETE_CHURCH_SUCCESS,
  };
};

export const deleteCHURCHFailure = (error) => {
  return {
    type: DELETE_CHURCH_FAILURE,
    payload: error,
  };
};

export const showUnshowDialogMessage = () => {
  return {
    type: DIALOG_MESSAGE,
  };
};

export const CREATE_PRAYER = "CREATE_PRAYER";
export const PRAYER_SUCCESS = "PRAYER_SUCCESS";
export const PRAYER_FAILURE = "PRAYER_FAILURE";

export const FETCH_ALL_PRAYER = "FETCH_ALL_PRAYER";
export const FETCH_ALL_PRAYER_SUCCESS = "FETCH_ALL_PRAYER_SUCCESS";
export const FETCH_ALL_PRAYER_FAILURE = "FETCH_ALL_PRAYER_FAILIURE";

export const UPDATE_PRAYER = "UPDATE_PRAYER";
export const UPDATE_PRAYER_SUCCESS = "UPDATE_PRAYER_SUCCESS";
export const UPDATE_PRAYER_FAILURE = "UPDATE_PRAYER_FAILURE";

export const DELETE_PRAYER = "DELETE_PRAYER";
export const DELETE_PRAYER_SUCCESS = "DELETE_PRAYER_SUCCESS";
export const DELETE_PRAYER_FAILURE = "DELETE_PRAYER_FAILURE";

export const DIALOG_MESSAGE = "DIALOG_MESSAGE";
export const DELETE_TOGGLE_MODAL_STATUS = "DELETE_TOGGLE_MODAL_STATUS";

// defining actions for create prayer
export const createPrayer = (data) => {
  return {
    type: CREATE_PRAYER,
    payload: data,
  };
};

export const prayerSuccess = (data) => {
  return {
    type: PRAYER_SUCCESS,
    payload: data,
  };
};

export const prayerFailure = (error) => {
  return {
    type: PRAYER_FAILURE,
    payload: error,
  };
};

// defining actions for get all prayer
export const fetchAllPrayerWithPagination = (urlParameters) => {
  return {
    type: FETCH_ALL_PRAYER,
    params: urlParameters
  }
}

export const fetchAllPrayerSuccess = (data) => {
  return {
    type: FETCH_ALL_PRAYER_SUCCESS,
    payload: data,
  };
};

export const fetchAllPrayerFailure = (error) => {
  return {
    type: FETCH_ALL_PRAYER_FAILURE,
    payload: error,
  };
};

// defining actions for update prayer
export const updatePrayer = (data, urlParameters) => {
  return {
    type: UPDATE_PRAYER,
    payload: data,
    params: urlParameters
  };
};

export const updatePrayerSuccess = (data) => {
  return {
    type: UPDATE_PRAYER_SUCCESS,
    payload: data,
  };
};

export const updatePrayerFailure = (error) => {
  return {
    type: UPDATE_PRAYER_FAILURE,
    payload: error,
  };
};

// defining actions for delete prayer
export const deletePrayer = (id) => {
  return {
    type: DELETE_PRAYER,
    params: id
  };
};

export const deletePrayerSuccess = (data) => {
  return {
    type: DELETE_PRAYER_SUCCESS,
    payload: data,
  };
};

export const deletePrayerFailure = (error) => {
  return {
    type: DELETE_PRAYER_FAILURE,
    payload: error,
  };
};

export const showUnshowDialogMessage = () => {
  return {
    type: DIALOG_MESSAGE
  };
};

// defining action for enabling/disabling delete modal
export const deleteToggleModal = () => {
  return {
    type: DELETE_TOGGLE_MODAL_STATUS,
  };
};

import {
  GET_GEO_LOCATION,
  GET_GEO_LOCATION_SUCCESS,
  GET_GEO_LOCATION_FAILURE,
  CLOSE_GEO_LOCATION,
} from "./action";

const initialState = {
  error: "",
  geoLocationData: [],
  geoLocationSuccess: false,
  geoLoader: false,
};

export const geoLocationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_GEO_LOCATION:
      return {
        ...state,

        error: "",
        geoLocationData: [],
        geoLocationSuccess: false,
        geoLoader: true,
      };
    case GET_GEO_LOCATION_SUCCESS:
      return {
        ...state,

        error: "",
        geoLocationData: action.payload,
        geoLocationSuccess: true,
        geoLoader: false,
      };
    case GET_GEO_LOCATION_FAILURE:
      return {
        ...state,
        error: action.payload,

        geoLocationData: [],
        geoLocationSuccess: false,
        geoLoader: false,
      };
    case CLOSE_GEO_LOCATION:
      return {
        ...state,
        geoLocationSuccess: false,
        geoLoader: false,
      };
    default:
      return state;
  }
};

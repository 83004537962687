import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import {
  Grid,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  useTheme,
  useMediaQuery,
  OutlinedInput,
} from "@mui/material";

import useCustomStyles from "../../customHooks/useCustomStyles";
import FileUploadOutlined from "@mui/icons-material/FileUploadOutlined";
import DialogBoxLayout from "../../commonLayout/DialogBoxLayout";
import DialogBoxFileUploadLayout from "../../commonLayout/DialogBoxFileUploadLayout";
import ProgressDialogLayout from "../../commonLayout/ProgressDialogLayout";

import {
  createPrayer,
  updatePrayer,
  showUnshowDialogMessage,
} from "../../redux/prayer/action";
import { fetchLanguage } from "../../redux/language/action";
import withMixpanelTracking from "../commonComponent/withMixPanelTracking";
import { truncateAtDot } from "../../helpers/truncateFileName";

const styles = (theme) => ({
  container: {
    backgroundColor: "var(--primary-color)",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",

    alignItems: "center",
    [theme.breakpoints.down("xl")]: {
      height: "400px",
      paddingTop: "40px",
    },
    [theme.breakpoints.up("xl")]: {
      padding: "0 10px 25px",
      marginTop: "45px",
      height: "600px",
    },
  },

  componentTitle: {
    fontSize: "24px",
    paddingLeft: "15px",
    fontWeight: "400",
    color: "#333333",
  },

  input_box: {
    backgroundColor: "#ffffff",
    color: "#A0A09E",
  },
});

const AddEditPrayer = (props) => {
  const { action, tabs } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const classes = useCustomStyles(styles, theme);
  const [fileError, setFileError] = useState(false);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const encodedData = query.get("data");
  const decodedData = decodeURIComponent(encodedData);
  const matches = useMediaQuery("(max-width:1440px)");
  const editRecords = JSON.parse(decodedData);

  const prayerTypeObject = [
    { value: "MORNING_TIME", label: "Morning" },
    { value: "EVENING_TIME", label: "Evening" },
    { value: "NIGHT_TIME", label: "Night" },
  ];

  const [prayerObject, setPrayerObject] = useState({
    names: "",
    prayerType: "",
    languageCode: "",
    files: "",
  });

  // state variable used to keep a track of the different behaviour of drag and drop and normal file upload
  const [toBeUploadedFileFormat, setToBeUploadedFileFormat] = useState({
    fileType: "",
    acceptedFileFormatForDragDrop: [],
    acceptedFileFormatForNormalFileUpload: [],
  });

  const [progress, setProgress] = useState(0);
  const [progressDialogOpen, setProgressDialogOpen] = useState(false);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [uploadFileName, setUploadFileName] = useState("");
  const [uploadInterval, setUploadInterval] = useState(null);
  const [fileName, setFileName] = useState("");

  // Opens the file upload dialog for the specified type
  const handleOpen = (fileType, dragDropFileFormat, normalUploadFileFormat) => {
    setToBeUploadedFileFormat({
      ...toBeUploadedFileFormat,
      fileType: fileType,
      acceptedFileFormatForDragDrop: dragDropFileFormat,
      acceptedFileFormatForNormalFileUpload: normalUploadFileFormat,
    });
    setDialogOpen(true);
  };

  // Closes the progress dialog, stops the upload progress, and resets progress
  const handleProgressDialogClose = () => {
    setPrayerObject({ ...prayerObject, files: "" });
    setFileName("");
    setProgressDialogOpen(false);
    setUploadInProgress(false);
    clearInterval(uploadInterval); // Stop the upload interval
    setProgress(0); // Reset the progress
  };

  // Handles file input change, sets the file name, and starts the upload process
  const handleFileChange = (event) => {
    const file = event.target ? event.target.files[0] : event;

    if (file && file.type === "application/epub+zip") {
      setUploadFileName(file.name);
      setPrayerObject({ ...prayerObject, files: file });
      setFileName(file.name);
      setDialogOpen(false);
      handleProgressDialogOpen();
      uploadFile(file);
      setFileError(false);
    } else {
      alert("Please upload a valid epub file.");
    }
  };

  // Opens the progress dialog and sets upload status
  const handleProgressDialogOpen = () => {
    setProgressDialogOpen(true);
    setUploadInProgress(true);
  };

  // Simulates file upload progress
  const uploadFile = (file) => {
    setProgress(0);

    const UploadProgress = () => {
      let progress = 0;

      const interval = setInterval(() => {
        progress += 10;
        setProgress(progress);
        if (progress >= 110) {
          clearInterval(interval);
          setUploadInProgress(false);
          setProgressDialogOpen(false);
        }
      }, 300);

      setUploadInterval(interval); // Save the interval ID to stop it later
    };
    UploadProgress();
  };

  const [errors, setErrors] = useState(null);

  const validateForm = () => {
    const newErrors = {};

    if (!prayerObject.names) newErrors.names = "Title  is required.";
    if (!prayerObject.prayerType) newErrors.prayerType = "Please select a time";
    if (!prayerObject.languageCode)
      newErrors.languageCode = "Language is required.";
    if (prayerObject.files.length <= 0)
      newErrors.files = "Please upload a file.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const handlePrayerUpload = async (event) => {
    event.preventDefault();
    if (!validateForm()) return;

    const formData = new FormData();

    formData.append("files", prayerObject?.files);
    formData.append("languageCode", prayerObject?.languageCode);
    formData.append("prayerType", prayerObject?.prayerType);
    formData.append("name", prayerObject?.names);

    if (action === "edit") {
      formData.append("name", prayerObject?.names);
      props.updatePrayer(formData, editRecords?.id);
      props.trackButtonClick("Update Prayer Button", 1);
    } else {
      // performing add prayer operation
      formData.append("names", prayerObject?.names);
      props.createPrayer(formData);
      props.trackButtonClick("Create Prayer Button", 1);
    }
  };

  const resetInputFieldsOnApiSuccess = (successValue) => {
    if (successValue) {
      setPrayerObject({
        ...prayerObject,
        names: "",
        languageCode: "",
        prayerType: "",
      });
      setFileName("");
    }
  };

  // function that will be responsible for closing the dialog box
  const handleCloseSuccessBox = () => {
    props.showUnshowDialogMessage();
    navigate(`/content/${tabs}/view`);
  };

  const getAllLanguagesList = () => {
    props.fetchLanguage();
  };

  useEffect(() => {
    getAllLanguagesList();

    if (action === "edit") {
      let filesData = {};

      if (editRecords?.fileName) {
        filesData["lastModified"] = editRecords.createdAt;
        filesData["lastModifiedDate"] = editRecords.updatedAt;
        filesData["name"] = editRecords.fileName;
        filesData["size"] = editRecords.fileSize;
        filesData["type"] = editRecords.fileType;
        filesData["webkitRelativePath"] = editRecords?.filePath;
      }
      setPrayerObject({
        ...prayerObject,
        names: editRecords?.name,
        files: filesData,
        languageCode: editRecords?.languageCode,
        prayerType: editRecords?.prayerType,
      });

      setFileName(editRecords?.fileName);
    }
  }, []);

  useEffect(() => {
    resetInputFieldsOnApiSuccess(props?.successResponse);
  }, [props?.successResponse]);

  return (
    <>
      {props?.successResponse && (
        <DialogBoxLayout
          dialogBoxToggle={props.successResponse}
          setDialogBoxToggle={handleCloseSuccessBox}
          header={
            action === "edit"
              ? "Updated Successfully!"
              : "Uploaded Successfully!"
          }
          content={
            action === "edit"
              ? "You Have Edited Your File Successfully"
              : "You Have Uploaded Your File Successfully"
          }
        />
      )}

      {dialogOpen && (
        <DialogBoxFileUploadLayout
          dialogOpen={dialogOpen}
          setDialogOpen={setDialogOpen}
          uploadInProgress={uploadInProgress}
          progress={progress}
          handleFileChange={handleFileChange}
          toBeUploadedFileFormat={toBeUploadedFileFormat}
        />
      )}

      {progressDialogOpen && (
        <ProgressDialogLayout
          progressDialogOpen={progressDialogOpen}
          handleProgressDialogClose={handleProgressDialogClose}
          uploadFileName={truncateAtDot(uploadFileName)}
          uploadInProgress={uploadInProgress}
          toBeUploadedFileFormat={toBeUploadedFileFormat}
          progress={progress}
        />
      )}

      <Grid
        xs={12}
        className={classes?.container}
        component="form"
        onSubmit={handlePrayerUpload}
      >
        <div>
          <Stack
            spacing={8}
            direction="column"
            sx={{ justifyContent: "space-evenly", alignItems: "center" }}
          >
            <Stack spacing={4} direction="row">
              <FormControl
                variant="outlined"
                sx={{ width: { xs: 200, md: 250, lg: 380, xl: 480 }, mt: 1 }}
              >
                <TextField
                  className={classes?.input_box}
                  id="outlined-basic"
                  error={!!errors?.names}
                  placeholder="Enter Title"
                  label="Title"
                  variant="outlined"
                  size={matches ? "small" : ""}
                  InputLabelProps={{ shrink: true }}
                  value={prayerObject?.names}
                  onChange={(event) => {
                    setErrors(null);
                    setPrayerObject({
                      ...prayerObject,
                      names: event.target.value,
                    });
                  }}
                />
                {errors?.names && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors?.names}
                  </span>
                )}
              </FormControl>

              <FormControl
                variant="outlined"
                sx={{ width: { xs: 200, md: 250, lg: 380, xl: 480 }, mb: 5 }}
              >
                <TextField
                  className={classes?.input_box}
                  id="outlined-basic"
                  placeholder="EPUB File"
                  label="File"
                  error={!!errors?.files}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  size={matches ? "small" : ""}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <>
                        <Button
                          aria-label="upload"
                          component="label"
                          className="addFileButton"
                          onClick={() => {
                            setErrors(null);
                            handleOpen("EPUB", ["EPUB"], [".epub"]);
                          }}
                        >
                          Add File
                        </Button>
                      </>
                    ),
                  }}
                  value={truncateAtDot(fileName)}
                />
                {errors?.files && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors?.files}
                  </span>
                )}
              </FormControl>
            </Stack>

            <Stack spacing={4} direction="row">
              <FormControl
                sx={{ width: { xs: 200, md: 250, lg: 380, xl: 480 }, mt: 5 }}
                size={matches ? "small" : ""}
              >
                <TextField
                  className={classes?.input_box}
                  error={!!errors?.prayerType}
                  labelId="demo-simple-select-label"
                  placeholder="Choose Time"
                  label="Time"
                  InputLabelProps={{ shrink: true }}
                  id="time"
                  select
                  variant="outlined"
                  size={matches ? "small" : ""}
                  sx={{
                    mt: 1,
                    paddingBottom: "0px",
                    "& .MuiSelect-select span::before": {
                      color: "#A0A09E",
                      content: "'Choose Time'",
                    },
                  }}
                  value={prayerObject?.prayerType}
                  onChange={(event) => {
                    setErrors(null);
                    setPrayerObject({
                      ...prayerObject,
                      prayerType: event.target.value,
                    });
                  }}
                >
                  {prayerTypeObject.map((prayer) => {
                    return (
                      <MenuItem value={prayer.value}>{prayer.label}</MenuItem>
                    );
                  })}
                </TextField>
                {errors?.prayerType && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors?.prayerType}
                  </span>
                )}
              </FormControl>

              <FormControl
                sx={{ width: { xs: 200, md: 250, lg: 380, xl: 480 }, mt: 1 }}
                size={matches ? "small" : ""}
              >
                <TextField
                  className={classes?.input_box}
                  InputLabelProps={{ shrink: true }}
                  labelId="demo-simple-select-label"
                  id="language"
                  select
                  variant="outlined"
                  size={matches ? "small" : ""}
                  sx={{
                    mt: 1,
                    paddingBottom: "0px",
                    "& .MuiSelect-select span::before": {
                      color: "#A0A09E",
                      content: "'Choose Language'",
                    },
                  }}
                  label="Language"
                  value={prayerObject?.languageCode}
                  placeholder="Choose Language"
                  onChange={(event) => {
                    setErrors(null);
                    setPrayerObject({
                      ...prayerObject,
                      languageCode: event.target.value,
                    });
                  }}
                >
                  {props?.languageData?.data?.length > 0 &&
                    props?.languageData?.data.map((language) => (
                      <MenuItem value={language?.code}>
                        {language?.name}
                      </MenuItem>
                    ))}
                </TextField>
                {errors?.languageCode && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors?.languageCode}
                  </span>
                )}
              </FormControl>
            </Stack>
          </Stack>

          <Grid xs={12} sx={{ mt: 6, ml: 2 }}>
            <Button
              variant="contained"
              type="submit"
              startIcon={<FileUploadOutlined />}
              size="small"
              sx={{
                backgroundColor: "var(--secondary-color)",
                textTransform: "none",
                fontSize: "20px",
                fontWeight: "500",
                marginLeft: { sm: 11, md: 52, lg: 85, xl: 110 },
              }}
            >
              {action === "edit" ? "Update" : "Upload"}
            </Button>
          </Grid>
        </div>
      </Grid>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { dialogMessage } = state.prayerReducer;
  const { success, languageData } = state.languageReducer;

  return {
    successResponse: dialogMessage,
    languageSuccessResponse: success,
    languageData,
    contentDataToBeUpdated: ownProps.contentDataToBeUpdated,
  };
};

export default connect(mapStateToProps, {
  createPrayer,
  updatePrayer,
  showUnshowDialogMessage,
  fetchLanguage,
})(withMixpanelTracking(AddEditPrayer, "Add Prayer"));

import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FileUploadOutlined } from "@mui/icons-material";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import DialogBoxLayout from "../../commonLayout/DialogBoxLayout";
import {
  createCHURCH,
  showUnshowDialogMessage,
  updateCHURCH,
} from "../../redux/church/action";
import withMixpanelTracking from "../commonComponent/withMixPanelTracking";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  closeGeoLocation,
  getGeoLocation,
} from "../../redux/geoLocation/action";
import MapComponent from "./mapComponent";
import { getAllDIOCESE } from "../../redux/diocese/action";

const FieldName = [
  {
    key: "dioceseId",
    keyName: "dioceseId",
    placeholder: "Diocese*",
    type: "dropdown",
    errorMessage: "Please select one diocese",
  },
  {
    key: "name",
    keyName: "Churches",
    placeholder: "Type Church Name",
    errorMessage: "Church Name is required",
  },
  {
    key: "pinCode",
    keyName: "Pin Code",
    placeholder: "Type Pincode",
    errorMessage: "Pincode is required",
  },
  {
    key: "latitude",
    keyName: "Latitude",
    placeholder: "Type Latitude",
    errorMessage: "Latitude is required",
  },
  {
    key: "longitude",
    keyName: "Longitude",
    placeholder: "Type Longitude",
    errorMessage: "Longitude is required",
  },
  {
    key: "address",
    keyName: "Address",
    placeholder: "Type Address",
    errorMessage: "Address is required",
  },
];

const AddChurch = (props) => {
  const [userData, setUserData] = useState({
    name: "",
    dioceseId: "",
    pinCode: "",
    address: "",
    latitude: "",
    longitude: "",
  });
  const matches = useMediaQuery("(max-width:1440px)");
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const tabTypeEn = query.get("tabType");
  const navigate = useNavigate();
  const { action } = useParams();
  const [formErrors, setFormErrors] = useState({});
  const encodedData = query.get("data");
  const decodedData = decodeURIComponent(encodedData);
  const [searchValue, setSearchValue] = useState(null);
  const editRecords = JSON.parse(decodedData);
  const [openMap, setOpenMap] = useState(false);

  useEffect(() => {
    if (action === "edit") {
      const records = editRecords;
      setUserData({
        ...userData,
        ["name"]: records.name,
        ["dioceseId"]: records?.diocese?.id,
        ["pinCode"]: records.pinCode,
        ["address"]: records.address,
        ["latitude"]: records.latitude,
        ["longitude"]: records.longitude,
      });
    }
  }, [action]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const errors = {};
    FieldName.forEach((field) => {
      if (!userData[field.key]) {
        errors[field.key] = `${field.keyName} is required`;
      }
    });
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    if (validateForm()) {
      if (action === "edit") {
        props.updateCHURCH({ ...userData, id: editRecords.id });
        props.trackButtonClick("Update Church Button", 1);
      } else {
        props.createCHURCH(userData);
        props.trackButtonClick("Create Church Button", 1);
      }
    }
  };

  const handleCloseSuccessBox = () => {
    props.showUnshowDialogMessage();

    navigate(`/church/view?tabType=${tabTypeEn}`);
  };

  const resetInputFieldsOnApiSuccess = (successValue) => {
    if (successValue) {
      setUserData({
        name: "",
        latitude: "",
        longitude: "",
        dioceseId: "",
        pinCode: "",
        address: "",
      });
    }
  };

  useEffect(() => {
    resetInputFieldsOnApiSuccess(props?.successResponse);
  }, [props?.successResponse]);

  useEffect(() => {
    props.getAllDIOCESE();
  }, []);

  useEffect(() => {
    if (props?.geoLocationSuccess) {
      let data = props.geoLocationData;

      setUserData({
        ...userData,
        latitude: data.lat,
        longitude: data.lon,
      });
      setOpenMap(true);
    }
  }, [props.geoLocationSuccess]);

  useEffect(() => {
    if (props?.geoLocationSuccess) {
      setOpenMap(true);
    }
  }, [userData?.latitude]);

  const handleCloseGeoLocation = () => {
    setOpenMap(false);
    props.closeGeoLocation();
  };

  const handleGetLocation = () => {
    if (userData?.address && userData?.pinCode) {
      props.getGeoLocation(userData?.address, userData?.pinCode);
    }
  };

  return (
    <>
      {props.successResponse && (
        <DialogBoxLayout
          dialogBoxToggle={props?.successResponse}
          setDialogBoxToggle={handleCloseSuccessBox}
          header={
            action === "edit" ? "Edited Successfully!" : "Added Successfully!"
          }
          content={
            action === "edit"
              ? "You Have Edited Church Successfully"
              : "You Have Added Church Successfully"
          }
        />
      )}
      <MapComponent
        open={openMap}
        handleDialogClose={handleCloseGeoLocation}
        geoData={userData}
        setUserData={setUserData}
      />

      <Grid
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <KeyboardBackspaceIcon
          style={{
            fontSize: "30px",
            fontWeight: 600,
            color: "black",
            fontFamily: "inter",
            cursor: "pointer",
            marginRight: 10,
          }}
          onClick={() => {
            navigate(`/church/view?tabType=${tabTypeEn}`);
          }}
        />
        <Typography
          style={{
            fontSize: matches ? "1.2rem" : "1.5rem",
            fontWeight: 600,
            color: "black",
            fontFamily: "inter",
          }}
        >
          {action === "edit" ? `Edit ${"Church"}` : `Add ${"Church"}`}
        </Typography>
      </Grid>
      <Grid
        xs={12}
        sx={{
          backgroundColor: "var(--primary-color)",
          borderRadius: 2,
          width: "auto",
        }}
      >
        <Box component="form" onSubmit={handleFormSubmit}>
          <Grid
            container
            spacing={matches ? 4 : 8}
            sx={{ padding: matches ? "20px" : "50px", marginTop: "0px" }}
          >
            {FieldName.map((item) => (
              <Grid
                item
                xs={12}
                sm={item.key === "latitude" || item.key === "longitude" ? 3 : 6}
                key={item.key}
                sx={item.key === "address" && { marginTop: "-20px" }}
              >
                {item?.type === "dropdown" ? (
                  <FormControl
                    variant="outlined"
                    sx={{ width: "100%" }}
                    size={matches ? "small" : ""}
                  >
                    <Autocomplete
                      id="diocese"
                      name="diocese"
                      disabled={action === "edit" ? true : false}
                      options={props?.DioceseData || []}
                      getOptionLabel={(option) => option.diocese || ""}
                      value={
                        props?.DioceseData?.find(
                          (item) => item.id === (userData?.dioceseId || ""),
                        ) || null
                      }
                      onChange={(event, newValue) => {
                        setFormErrors({
                          name: false,
                          latitude: false,
                          longitude: false,
                          dioceseId: false,
                          pinCode: false,
                          address: false,
                        });
                        setUserData({
                          ...userData,
                          dioceseId: newValue ? newValue.id : "",
                        });
                      }}
                      inputValue={searchValue}
                      onInputChange={(event, newInputValue) => {
                        setSearchValue(newInputValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Diocese"
                          variant="outlined"
                          size={matches ? "small" : ""}
                          placeholder="Choose diocese"
                          InputLabelProps={{ shrink: true }}
                          sx={{
                            backgroundColor: "white",
                            paddingBottom: "0px",
                          }}
                          error={!!formErrors?.dioceseId}
                        />
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      fullWidth
                      renderOption={(props, option) => (
                        <MenuItem {...props} key={option.id} value={option.id}>
                          {option.diocese}
                        </MenuItem>
                      )}
                      ListboxProps={{
                        style: {
                          maxHeight: 200,
                          overflowY: "auto",
                          scrollbarWidth: "thin",
                        },
                      }}
                    />
                  </FormControl>
                ) : (
                  <TextField
                    fullWidth
                    id={item.key}
                    name={item.key}
                    label={`${item.keyName}*`}
                    placeholder={item.placeholder}
                    size={matches ? "small" : ""}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    sx={{ backgroundColor: "white", borderRadius: "10px" }}
                    value={userData[item.key]}
                    onChange={handleChange}
                    rows={item.keyName === "Address" ? 4 : undefined}
                    multiline={item.keyName === "Address"}
                    error={!!formErrors[item.key]}
                    type={item.key === "pinCode" ? "number" : ""}
                  />
                )}
                {formErrors[item.key] && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {item.errorMessage}
                  </span>
                )}
                {item.key === "longitude" ? (
                  <Button
                    className="coords"
                    disabled={
                      userData.address.length <= 0 ||
                      userData.pinCode.length <= 0
                    }
                    onClick={handleGetLocation}
                    sx={{
                      paddingX: 0,
                      width: "fit-content",
                      float: "right",
                      "&:hover": {
                        color: "#eb4c13",
                        background: "none",
                      },
                    }}
                  >
                    {userData.address.length <= 0 ||
                    userData.pinCode.length <= 0 ? (
                      <img
                        src="/grey-location.svg"
                        alt="longitude"
                        className="coords-img"
                      />
                    ) : (
                      <img
                        src="/orange-location.svg"
                        alt="longitude"
                        className="coords-img"
                      />
                    )}
                    {props.geoLoader
                      ? "Fetching Geo Location"
                      : "Get Coordinates"}
                  </Button>
                ) : (
                  ""
                )}
              </Grid>
            ))}
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="end"
              gap={"20px"}
              sx={{
                marginTop: { lg: 0, xl: "100px" },
              }}
            >
              <Button
                type="submit"
                variant="contained"
                startIcon={
                  action === "Add" ? (
                    <AddCircleOutlineOutlinedIcon />
                  ) : (
                    <FileUploadOutlined />
                  )
                }
                sx={{
                  textTransform: "none",
                  fontSize: { lg: "15px", xl: "20px" },
                  paddingX: { xl: "30px" },
                  fontWeight: "400",
                }}
                size={matches ? "small" : "large"}
              >
                {action === "Add" ? "Create" : "Update"}
              </Button>
              {/* <Button
                variant="outlined"
                size={matches ? "small" : "large"}
                color="error"
                sx={{
                  width: 120,
                  textTransform: "none",
                  "&.MuiButtonBase-root:hover": {
                    color: "#c62828",
                  },
                }}
                onClick={() => {
                  navigate(`/church/view`, { replace: true });
                }}
              >
                Cancel
              </Button> */}
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    data,
    dialogMessage,
    createGurudwara,
    createResponse,
    updateGurudwara,
    updateResponse,
    error,
  } = state.CHURCHReducer;
  const { geoLocationData, geoLocationSuccess, geoLoader } =
    state.geoLocationReducer;

  const { DioceseData } = state.DIOCESEReducer;

  return {
    data,
    createGurudwara,
    createResponse,
    updateGurudwara,
    updateResponse,
    error,
    successResponse: dialogMessage,
    geoLocationData,
    geoLocationSuccess,
    geoLoader,
    DioceseData: DioceseData?.data,
  };
};

export default connect(mapStateToProps, {
  createCHURCH,
  updateCHURCH,
  showUnshowDialogMessage,
  getGeoLocation,
  closeGeoLocation,
  getAllDIOCESE,
})(withMixpanelTracking(AddChurch, " Add Church"));

import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_GEO_LOCATION,
  getGeoLocationSuccess,
  getGeoLocationFailure,
} from "./action";
import { showSnackbar } from "../snackbar/action";
import axios from "axios";

const getCall = async (address, pinCode) => {
  return axios.get("https://nominatim.openstreetmap.org/search", {
    params: {
      q: `${address}, ${pinCode}`,
      format: "json",
      limit: 1,
    },
  });
};

function* getGeoLocation(action) {
  try {
    const { address, pinCode } = action.payload;
    const result = yield call(getCall, address, pinCode);
    if (result.status === 200 && result?.data?.length > 0) {
      yield put(getGeoLocationSuccess(result?.data[0]));
      yield put(
        showSnackbar({
          message: "Location Fetched Successfully.",
          severity: "success",
        }),
      );
    } else {
      yield put(getGeoLocationFailure(result));
      yield put(
        showSnackbar({
          message: "Location Not found.",
          severity: "error",
        }),
      );
    }
  } catch (error) {
    yield put(getGeoLocationFailure(error));
    yield put(
      showSnackbar({
        message: "Error while fetching the location.",
        severity: "error",
      }),
    );
  }
}

function* geoLocationSaga() {
  yield takeEvery(GET_GEO_LOCATION, getGeoLocation);
}
export default geoLocationSaga;

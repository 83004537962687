import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import {
  Grid,
  Stack,
  Button,
  FormControl,
  TextField,
  useTheme,
  InputLabel,
  Select,
  MenuItem,
  useMediaQuery,
  OutlinedInput,
} from "@mui/material";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import {
  formatDateSeperatedWithHyphen,
  anyDate,
  todayDate,
} from "../../helpers/dateHelper";
import useCustomStyles from "../../customHooks/useCustomStyles";
import DialogBoxLayout from "../../commonLayout/DialogBoxLayout";
import withMixpanelTracking from "../commonComponent/withMixPanelTracking";

import FileUploadOutlined from "@mui/icons-material/FileUploadOutlined";

import { fetchLanguage } from "../../redux/language/action";
import {
  createDailyVerse,
  updateDailyVerse,
  showUnshowDialogMessage,
  fetchAllDailyVerseWithPagination,
} from "../../redux/dailyVerse/action";

const styles = (theme) => ({
  container: {
    backgroundColor: "var(--primary-color)",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xl")]: {
      padding: "20px 10px",
      marginTop: "20px",
      height: "400px",
    },
    [theme.breakpoints.up("xl")]: {
      padding: "0 10px 25px",
      marginTop: "45px",
      height: "600px",
    },
  },

  componentTitle: {
    fontSize: "24px",
    paddingLeft: "15px",
    fontWeight: "400",
    color: "#333333",
  },

  input_box: {
    backgroundColor: "#ffffff",
    color: "#A0A09E",
  },
});

const AddEditDailyVerse = (props) => {
  const { action, tabs } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const classes = useCustomStyles(styles, theme);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const encodedData = query.get("data");
  const decodedData = decodeURIComponent(encodedData);
  const matches = useMediaQuery("(max-width:1440px)");
  const editRecords = JSON.parse(decodedData);
  const [uploadedDailyVerseLanguageList, setUploadedDailyVerseLanguageList] =
    useState([]);

  // defining all the necessary state variables
  const [dailyVerseObect, setDailyVerseObject] = useState({
    languageCode: null,
    dateValue: todayDate(),
    title: "",
    description: "",
  });

  const [errors, setErrors] = useState(null);

  const validateForm = () => {
    const newErrors = {};
    if (!dailyVerseObect.languageCode)
      newErrors.languageCode = "Language is required.";
    if (!dailyVerseObect.title) newErrors.title = "Title is required.";
    if (!dailyVerseObect.description)
      newErrors.description = "Description is required.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // function to add/update dailyVerse
  const handleAddDailyVerse = async (event) => {
    event.preventDefault();

    if (!validateForm()) return;

    const hyphenFormattedDate = formatDateSeperatedWithHyphen(
      dailyVerseObect?.dateValue,
    );

    const newDailyVerseObject = {
      displaydate: hyphenFormattedDate,
      languageCode: dailyVerseObect?.languageCode,
      description: dailyVerseObect?.description,
      title: dailyVerseObect?.title,
    };

    if (action === "add") {
      // perform add dailyVerse
      props.createDailyVerse(newDailyVerseObject);
      props.trackButtonClick("Create Daily Verse Button", 1);
    } else if (action === "edit") {
      // perform edit dailyVerse
      props.updateDailyVerse(newDailyVerseObject, editRecords?.id);
      props.trackButtonClick("Update Daily Verse Button", 1);
    }
  };

  // function to check if there is any dailyVerse created in today's date or not
  const getAllDailyVerses = () => {
    props.fetchAllDailyVerseWithPagination(1);
  };

  // function that will reset the input fields after succesing add/edit operation
  const resetInputFieldsOnCreateSuccess = (successValue) => {
    if (successValue) {
      setDailyVerseObject({
        ...dailyVerseObect,
        languageCode: "",
        title: "",
        description: "",
      });
    }
  };

  // function that will be responsible for closing the dialog box
  const handleCloseSuccessBox = () => {
    props.showUnshowDialogMessage();
    navigate(`/content/${tabs}/view`);
  };

  const getAllLanguagesList = () => {
    props.fetchLanguage();
  };

  const retrieveTodaysUploadedDailyVerses = (loadingValue) => {
    if (action === "add" && !loadingValue && props?.dailyVerseData?.data) {
      const hyphenFormattedDate = formatDateSeperatedWithHyphen(todayDate());
      let todayExistingDailyVerseRecords = props?.dailyVerseData?.data?.content;

      todayExistingDailyVerseRecords = todayExistingDailyVerseRecords
        ?.filter(
          (dailyVerse) => hyphenFormattedDate === dailyVerse?.displaydate,
        )
        ?.map((dailyVerse) => dailyVerse?.languageCode);

      if (
        todayExistingDailyVerseRecords?.length !==
        props?.languageData?.data?.length
      ) {
        setUploadedDailyVerseLanguageList(
          props?.languageData?.data?.filter(
            (language) =>
              !todayExistingDailyVerseRecords.includes(language?.code),
          ),
        );
      }
    } else if (action === "edit") {
      setDailyVerseObject({
        ...dailyVerseObect,
        dateValue: anyDate(editRecords?.displaydate),
        languageCode: editRecords?.languageCode,
        title: editRecords?.title,
        description: editRecords?.description,
      });
    }
  };

  useEffect(() => {
    if (action === "add") {
      getAllDailyVerses();
    }
    getAllLanguagesList();
  }, []);

  useEffect(() => {
    retrieveTodaysUploadedDailyVerses(props?.loading);
  }, [props?.loading, props?.languageSuccessResponse]);

  useEffect(() => {
    resetInputFieldsOnCreateSuccess(props.successResponse);
  }, [props.successResponse]);

  return (
    <>
      {props.successResponse && (
        <DialogBoxLayout
          dialogBoxToggle={props?.successResponse}
          setDialogBoxToggle={handleCloseSuccessBox}
          header={
            action === "edit"
              ? "Updated Successfully!"
              : "Uploaded Successfully!"
          }
          content={
            action === "edit"
              ? "You Have Edited Your File Successfully"
              : "You Have Uploaded Your File Successfully"
          }
        />
      )}

      <Grid
        xs={12}
        className={classes?.container}
        component="form"
        onSubmit={handleAddDailyVerse}
      >
        <div>
          <Stack
            spacing={8}
            direction="column"
            sx={{ justifyContent: "space-evenly", alignItems: "center" }}
          >
            <Grid container xs={12} gap={4}>
              <Grid sx={6}>
                <FormControl
                  sx={{ width: { xs: 200, md: 250, lg: 380, xl: 480 } }}
                  size={matches ? "small" : ""}
                >
                  <TextField
                    className={classes?.input_box}
                    error={errors?.languageCode}
                    inputProps={{
                      readOnly: action === "add" ? false : true
                    }}
                    InputLabelProps={{ shrink: true }}
                    labelId="demo-simple-select-label"
                    id="language"
                    select
                    variant="outlined"
                    size={matches ? "small" : ""}
                    sx={{
                      mt: 1,
                      paddingBottom: "0px",
                      "& .MuiSelect-select span::before": {
                        color: "#A0A09E",
                        content: "'Choose Language'",
                      },
                    }}
                    label="Language"
                    value={dailyVerseObect?.languageCode}
                    placeholder="Choose Language"
                    onChange={(event) => {
                      setErrors(null);
                      setDailyVerseObject({
                        ...dailyVerseObect,
                        languageCode: event.target.value,
                      });
                    }}
                  >
                    {
                      action === "add" ? 
                        uploadedDailyVerseLanguageList?.length > 0 &&
                          uploadedDailyVerseLanguageList?.map((language) => (
                            <MenuItem value={language?.code}>
                              {language?.name}
                            </MenuItem>
                          )) :
                        props?.languageData?.data?.length > 0 &&
                          props?.languageData?.data.map((language) => (
                            <MenuItem value={language?.code}>
                              {language?.name}
                            </MenuItem>
                          ))
                    }
                  </TextField>
                  {errors?.languageCode && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errors?.languageCode}
                    </span>
                  )}
                </FormControl>
              </Grid>

              <Grid sx={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer
                    components={["DatePicker"]}
                    sx={{ overflow: "none" }}
                  >
                    <DatePicker
                      slotProps={{
                        textField: {
                          size: matches ? "small" : "",
                        },
                      }}
                      className={classes?.input_box}
                      readOnly
                      sx={{
                        width: { xs: 200, md: 250, lg: 380, xl: 480 },

                        "& .MuiInputBase-root": {
                          height: { lg: "40px", xl: "60px" }, // Adjust this value as needed
                        },
                        "& .MuiInputLabel-root": {
                          lineHeight: "40px", // Match label height with input
                          marginTop: "-6px",
                        },
                      }}
                      InputLabelProps={{ shrink: true }}
                      label="Date *"
                      format="DD/MM/YYYY"
                      value={dailyVerseObect?.dateValue}
                      onChange={(newValue) =>
                        setDailyVerseObject({
                          ...dailyVerseObect,
                          dateValue: newValue,
                        })
                      }
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
            </Grid>

            <Stack spacing={4} direction="row">
              <FormControl
                variant="outlined"
                sx={{ width: { xs: 200, md: 250, lg: 380, xl: 480 }, mt: 4 }}
              >
                <TextField
                  className={classes?.input_box}
                  id="outlined-multiline-static"
                  error={errors?.description}
                  InputLabelProps={{ shrink: true }}
                  label="Content"
                  placeholder="Content"
                  size={matches ? "small" : ""}
                  multiline
                  rows={6}
                  value={dailyVerseObect?.description}
                  onChange={(event) => {
                    setErrors(null);
                    setDailyVerseObject({
                      ...dailyVerseObect,
                      description: event.target.value,
                    })
                  }}
                />
                {errors?.description && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors?.description}
                  </span>
                )}
              </FormControl>

              <FormControl
                variant="outlined"
                sx={{ width: { xs: 200, md: 250, lg: 380, xl: 480 }, mt: 4 }}
              >
                <TextField
                  className={classes?.input_box}
                  id="outlined-basic"
                  InputLabelProps={{ shrink: true }}
                  error={errors?.title}
                  label="Title"
                  placeholder="Title"
                  size={matches ? "small" : ""}
                  variant="outlined"
                  value={dailyVerseObect?.title}
                  onChange={(event) => {
                    setErrors(null);
                    setDailyVerseObject({
                      ...dailyVerseObect,
                      title: event.target.value,
                    })
                  }}
                />
                {errors?.title && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors?.title}
                  </span>
                )}
              </FormControl>
            </Stack>
          </Stack>

          <Grid xs={12} sx={{ mt: 3 }}>
            <Button
              variant="contained"
              type="submit"
              startIcon={<FileUploadOutlined />}
              size="small"
              sx={{
                backgroundColor: "var(--secondary-color)",
                textTransform: "none",
                fontSize: "20px",
                fontWeight: "500",
                marginLeft: { sm: 11, md: 52, lg: 85, xl: 110 },
              }}
            >
              {action === "edit" ? "Update" : "Upload"}
            </Button>
          </Grid>
        </div>
      </Grid>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { dialogMessage, getDailyVerseSuccess, loading, dailyVerseData } =
    state.dailyVerseReducer;
  const { success, languageData } = state.languageReducer;

  return {
    successResponse: dialogMessage,
    getDailyVerseSuccess,
    dailyVerseData,
    loading,
    languageSuccessResponse: success,
    languageData,
    contentDataToBeUpdated: ownProps.contentDataToBeUpdated,
  };
};

export default connect(mapStateToProps, {
  createDailyVerse,
  fetchAllDailyVerseWithPagination,
  updateDailyVerse,
  showUnshowDialogMessage,
  fetchLanguage,
})(withMixpanelTracking(AddEditDailyVerse, "Add Daily Verse"));

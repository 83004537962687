export const CREATE_BIBLE = "CREATE_BIBLE";
export const BIBLE_SUCCESS = "BIBLE_SUCCESS";
export const BIBLE_FAILURE = "BIBLE_FAILURE";

export const DIALOG_MESSAGE = "DIALOG_MESSAGE";
export const DELETE_TOGGLE_MODAL_STATUS = "DELETE_TOGGLE_MODAL_STATUS";

export const DELETE_BIBLE = "DELETE_BIBLE";
export const DELETE_BIBLE_SUCCESS = "DELETE_BIBLE_SUCCESS";
export const DELETE_BIBLE_FAILURE = "DELETE_BIBLE_FAILURE";

export const GET_BIBLE_DETAILS = "GET_BIBLE_DETAILS";
export const GET_BIBLE_SUCCESS_DETAILS = "GET_BIBLE_SUCCESS_DETAILS";
export const GET_BIBLE_FAILURE_DETAILS = "GET_BIBLE_FAILURE_DETAILS";

export const GET_BIBLE_DETAILS_BYID = "GET_BIBLE_DETAILS_BYID";
export const GET_BIBLE_SUCCESS_DETAILS_BYID =
  "GET_BIBLE_SUCCESS_DETAILS_BYID";
export const GET_BIBLE_FAILURE_DETAILS_BYID =
  "GET_BIBLE_FAILURE_DETAILS_BYID";

export const UPDATE_BIBLE = "UPDATE_BIBLE";
export const UPDATE_BIBLE_SUCCESS = "UPDATE_BIBLE_SUCCESS";
export const UPDATE_BIBLE_FAILURE = "UPDATE_BIBLE_FAILURE";

export const createBible = (data) => {
  return {
    type: CREATE_BIBLE,
    payload: data,
  };
};

export const bibleSuccess = (data) => {
  return {
    type: BIBLE_SUCCESS,
    payload: data,
  };
};

export const bibleFailure = (error) => {
  return {
    type: BIBLE_FAILURE,
    payload: error,
  };
};
export const updateBible = (data, id) => {
  return {
    type: UPDATE_BIBLE,
    payload: data,
    id: id,
  };
};

export const updatebibleSuccess = (data) => {
  return {
    type: UPDATE_BIBLE_SUCCESS,
    payload: data,
  };
};

export const updatebibleFailure = (error) => {
  return {
    type: UPDATE_BIBLE_FAILURE,
    payload: error,
  };
};

export const showUnshowDialogMessage = () => {
  return {
    type: DIALOG_MESSAGE,
  };
};

export const deleteBible = (id) => {
  return {
    type: DELETE_BIBLE,
    payload: id,
  };
};
export const deleteBibleSuccess = (id) => {
  return {
    type: DELETE_BIBLE_SUCCESS,
    payload: id,
  };
};
export const deleteBibleFails = (error) => {
  return {
    type: DELETE_BIBLE_FAILURE,
    payload: error,
  };
};

export const getBibledetails = (urlParameters) => {
  return {
    type: GET_BIBLE_DETAILS,
    params: urlParameters,
  };
};

export const getBibledetailsSuccess = (data) => {
  return {
    type: GET_BIBLE_SUCCESS_DETAILS,
    payload: data,
  };
};
export const getBibledetailsFail = (error) => {
  return {
    type: GET_BIBLE_FAILURE_DETAILS,
    payload: error,
  };
};

export const getBibledetailsByID = () => {
  return {
    type: GET_BIBLE_DETAILS_BYID,
  };
};

export const getBibledetailsSuccessByID = (data) => {
  return {
    type: GET_BIBLE_SUCCESS_DETAILS_BYID,
    payload: data,
  };
};

export const getBibledetailsFailByID = (error) => {
  return {
    type: GET_BIBLE_FAILURE_DETAILS_BYID,
    payload: error,
  };
};

// defining action for enabling/disabling delete modal
export const deleteToggleModal = () => {
  return {
    type: DELETE_TOGGLE_MODAL_STATUS,
  };
};

import { Visibility, VisibilityOff } from "@mui/icons-material";
import { FileUploadOutlined } from "@mui/icons-material";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Autocomplete,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import DialogBoxLayout from "../../commonLayout/DialogBoxLayout";
import {
  validateEmail,
  validatePhoneNumber,
} from "../../helpers/InputValidation";

import { getAllDIOCESE } from "../../redux/diocese/action";
import { getAllCHURCH } from "../../redux/church/action";
import {
  createUser,
  showUnshowDialogMessage,
  updateUser,
} from "../../redux/usermanagement/action";
import "../../styles/user-management.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import withMixpanelTracking from "../commonComponent/withMixPanelTracking";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { checkRoles } from "../../helpers/checkRoles";

const AddUser = (props) => {
  const [userData, setUserData] = useState({
    fullName: "",
    email: "",
    password: "",
    dateOfBirth: dayjs().subtract(18, "year"),
    countryCode: "+91",
    phoneNumber: "",
    userType: checkRoles(["ROLE_SUPER_ADMIN"]) ? "ADMIN" : "SUBADMIN",
    dioceseIds: [],
    church: "",
  });
  const [inputValue, setInputValue] = React.useState("");
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const encodedData = query.get("data");
  const decodedData = decodeURIComponent(encodedData);

  const editRecords = JSON.parse(decodedData);
  const navigate = useNavigate();
  const { action } = useParams();

  const [errors, setErrors] = useState({
    fullName: false,
    email: false,
    phoneNumber: false,
    password: false,
    dateOfBirth: false,
    dioceseIds: false,
  });

  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    setErrors({
      fullName: false,
      email: false,
      phoneNumber: false,
      password: false,
      dateOfBirth: false,
      dioceseIds: false,
    });
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const handleDateChange = (date) => {
    if (dayjs(userData.date) < dayjs().subtract(18, "year")) {
      setErrors({ ...errors, dateOfBirth: true });
    } else {
      setErrors({ ...errors, dateOfBirth: false });
    }
    setUserData({
      ...userData,
      dateOfBirth: dayjs(date).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"), // Format the date as required
    });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    const newErrors = {
      fullName: !userData.fullName.trim(),
      email: !validateEmail(userData.email),
      phoneNumber: !validatePhoneNumber(userData.phoneNumber),
      password: action === "edit" ? false : !userData.password.trim(),
      // dateOfBirth: dayjs(userData.dateOfBirth) > dayjs().subtract(18, "year"),
      dioceseIds: userData.dioceseIds.length === 0,
    };

    setErrors(newErrors);

    // Check if there are any errors
    const hasErrors = Object.values(newErrors).some(Boolean);

    if (!hasErrors) {
      let newItem = {
        ...userData,
        dioceseIds: Array.isArray(userData.dioceseIds)
          ? userData.dioceseIds
          : [userData.dioceseIds],
      };
      if (checkRoles(["ROLE_ADMIN"]) && !checkRoles(["ROLE_SUPER_ADMIN"])) {
        newItem = { ...newItem, locationIds: newItem.dioceseIds };
        delete newItem["dioceseIds"];
      }

      if (action === "edit") {
        const updateUserPayload = {
          id: editRecords.id,
          ...newItem,
        };
        props.updateUser(updateUserPayload);
        props.trackButtonClick("Update User Button", 1);
      } else {
        props.createUser(newItem);
        props.trackButtonClick("Create User Button", 1);
      }
    }
  };

  const shouldDisableDate = (date) => {
    return dayjs(date).isAfter(dayjs(), "day");
  };

  const handleCloseSuccessBox = () => {
    props.showUnshowDialogMessage();
    navigate(`/user-management/view`, { replace: true });
    // if (typeof props.closeEditModal == "function") props.closeEditModal(false);
  };

  const resetInputFieldsOnApiSuccess = (successValue) => {
    if (successValue) {
      setUserData({
        ...userData,
        fullName: "",
        email: "",
        password: "",
        dateOfBirth: dayjs().subtract(18, "year"),
        dioceseIds: "",
        phoneNumber: "",
      });
    }
  };
  const [churchData, setChurchData] = useState([]);

  useEffect(() => {
    if (props?.churchData?.length > 0) {
      let activeData = props.churchData.filter((item) => item.active === true);
      setChurchData(activeData);
    } else {
      setChurchData([]);
    }
  }, [props.churchData]);

  useEffect(() => {
    props.getAllDIOCESE();
    props.getAllCHURCH();
  }, []);

  useEffect(() => {
    resetInputFieldsOnApiSuccess(props?.successResponse);
  }, [props?.successResponse]);

  useEffect(() => {
    if (action === "edit") {
      let records = editRecords;

      setUserData({
        ...userData,
        fullName: records.fullName,
        email: records.email,
        phoneNumber: records.phoneNumber,
        password: records.password,
        dateOfBirth: records.dateOfBirth,
        dioceseIds: records?.locations[0]?.locationId,
      });
    }
  }, [action]);

  const matches = useMediaQuery("(max-width:1440px)");

  return (
    <>
      <DialogBoxLayout
        dialogBoxToggle={props?.successResponse}
        setDialogBoxToggle={handleCloseSuccessBox}
        header={
          action === "edit"
            ? "Updated Successfully!"
            : "User Details Added Successfully!"
        }
        content="An Email has been sent to the user with credentials"
      />

      <Grid
        xs={12}
        className={"user_container"}
        component="form"
        onSubmit={handleFormSubmit}
        sx={{ marginTop: { xl: "10px" } }}
      >
        <div style={{ width: "100%", padding: "20px", height: "80%" }}>
          <Stack
            spacing={{ lg: 1, xl: 4 }}
            direction="column"
            gap={{ lg: 2, xl: 4 }}
            sx={{ mt: { lg: 0, xl: "40px" } }}
          >
            <Stack
              spacing={2}
              direction="row"
              gap={3}
              sx={{ justifyContent: "center" }}
            >
              <FormControl fullWidth variant="outlined">
                <TextField
                  id="fullName"
                  name="fullName"
                  size={matches ? "small" : ""}
                  label="Name"
                  placeholder="Enter Name"
                  InputLabelProps={{ shrink: true }}
                  className={"user_input_box"}
                  value={userData.fullName}
                  onChange={handleChange}
                  error={errors.fullName}
                />
                {errors.fullName && (
                  <FormHelperText sx={{ color: "red" }}>
                    Name is required
                  </FormHelperText>
                )}
              </FormControl>

              <FormControl variant="outlined" fullWidth>
                <TextField
                  id="email"
                  name="email"
                  type="email"
                  label="Email ID"
                  placeholder="Enter Email ID"
                  size={matches ? "small" : ""}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  className={"user_input_box"}
                  value={userData.email}
                  error={errors.email}
                  onChange={handleChange}
                />
                {errors.email && (
                  <FormHelperText sx={{ color: "red" }}>
                    {errors.email && "Email is required"}
                  </FormHelperText>
                )}
              </FormControl>
            </Stack>

            <Stack
              spacing={2}
              direction="row"
              gap={3}
              sx={{ justifyContent: "center" }}
            >
              <FormControl variant="outlined" fullWidth>
                <TextField
                  id="phoneNumber"
                  error={errors.phoneNumber}
                  name="phoneNumber"
                  label="Phone Number"
                  placeholder="Enter Ph. Number"
                  type="tel"
                  size={matches ? "small" : ""}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  className={"user_input_box"}
                  value={userData.phoneNumber}
                  onChange={handleChange}
                />
                {errors.phoneNumber && (
                  <FormHelperText sx={{ color: "red" }}>
                    {errors.phoneNumber
                      ? "Invalid Phone number format"
                      : "Phone number is required"}
                  </FormHelperText>
                )}
              </FormControl>

              <FormControl
                variant="outlined"
                fullWidth
                error={errors.dateOfBirth}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    className={"user_input_box"}
                    disabled={action === "edit" ? true : false}
                    fullWidth
                    label="Date of Birth *"
                    format="DD/MM/YYYY"
                    slotProps={{
                      textField: {
                        error:
                          dayjs(userData.dateOfBirth) <
                          dayjs().subtract(18, "year")
                            ? false
                            : true,
                        size: matches ? "small" : "",
                      },
                    }}
                    maxDate={dayjs().subtract(18, "year")}
                    value={dayjs(userData.dateOfBirth)}
                    onChange={handleDateChange}
                    shouldDisableDate={shouldDisableDate}
                  />
                </LocalizationProvider>
                {errors.dateOfBirth && (
                  <FormHelperText sx={{ color: "red" }}>
                    User should be atleast 18 years old.
                  </FormHelperText>
                )}
              </FormControl>
            </Stack>

            <Stack
              spacing={2}
              direction="row"
              gap={3}
              sx={{ justifyContent: "center" }}
            >
              <FormControl variant="outlined" fullWidth>
                <TextField
                  fullWidth
                  error={errors.password}
                  id="password"
                  disabled={action === "edit" ? true : false}
                  name="password"
                  size={matches ? "small" : ""}
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter Password"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  className={"user_input_box"}
                  value={userData.password}
                  onChange={handleChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {errors.password && (
                  <FormHelperText sx={{ color: "red" }}>
                    Password is required
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>Reporting To *</InputLabel>
                <Select
                  id="reportingTo"
                  name="reportingTo"
                  label="Reporting To"
                  className={"user_input_box"}
                  variant="outlined"
                  value="adminName"
                  size={matches ? "small" : ""}
                  disabled
                >
                  <MenuItem value="adminName">Samuel</MenuItem>
                </Select>
              </FormControl>
            </Stack>

            <Stack
              spacing={2}
              direction="row"
              gap={3}
              sx={{ justifyContent: "center" }}
            >
              <FormControl variant="outlined" fullWidth>
                <InputLabel>Role *</InputLabel>
                <Select
                  id="userType"
                  name="userType"
                  label="Role"
                  className={"user_input_box"}
                  variant="outlined"
                  disabled
                  size={matches ? "small" : ""}
                  value={userData.userType}
                >
                  <MenuItem value="SUBADMIN">SUBADMIN</MenuItem>
                  <MenuItem value="ADMIN">ADMIN</MenuItem>
                </Select>
              </FormControl>
              <FormControl
                variant="outlined"
                fullWidth
                size={matches ? "small" : ""}
              >
                <Autocomplete
                  id={checkRoles(["ROLE_SUPER_ADMIN"]) ? "diocese" : "church"}
                  name={checkRoles(["ROLE_SUPER_ADMIN"]) ? "diocese" : "church"}
                  options={churchData || []}
                  getOptionLabel={(option) =>
                    checkRoles(["ROLE_SUPER_ADMIN"])
                      ? option.diocese
                      : option.name || ""
                  }
                  value={
                    props?.churchData?.find(
                      (item) => item.id === userData?.dioceseIds,
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    setErrors({
                      fullName: false,
                      email: false,
                      phoneNumber: false,
                      password: false,
                      dateOfBirth: false,
                      dioceseIds: false,
                    });
                    setUserData({
                      ...userData,
                      dioceseIds: newValue ? newValue.id : "",
                    });
                  }}
                  inputValue={inputValue}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={
                        checkRoles(["ROLE_SUPER_ADMIN"]) ? "Diocese" : "Church"
                      }
                      variant="outlined"
                      size={matches ? "small" : ""}
                      placeholder={
                        checkRoles(["ROLE_SUPER_ADMIN"])
                          ? "Choose diocese"
                          : "Choose church"
                      }
                      InputLabelProps={{ shrink: true }}
                      sx={{
                        paddingBottom: "0px",
                      }}
                      className={"user_input_box"}
                    />
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  fullWidth
                  renderOption={(props, option) => (
                    <MenuItem {...props} key={option.id} value={option.id}>
                      {checkRoles(["ROLE_SUPER_ADMIN"])
                        ? option.diocese
                        : option.name}
                    </MenuItem>
                  )}
                  ListboxProps={{
                    style: {
                      maxHeight: 200, 
                      overflowY: "auto", 
                      scrollbarWidth:'thin' 
                    },
                  }}
                  error={errors.dioceseIds}
                  label={
                    checkRoles(["ROLE_SUPER_ADMIN"]) ? "Diocese" : "Church"
                  }
                />
                {!!errors?.dioceseIds && (
                  <FormHelperText sx={{ color: "red" }}>
                    Please select a{" "}
                    {checkRoles(["ROLE_SUPER_ADMIN"]) ? "diocese" : "church"}
                  </FormHelperText>
                )}
              </FormControl>
            </Stack>

            <Grid
              xs={12}
              sx={{
                justifyContent: "flex-end",
                alignItems: "flex-end",
                marginTop: "15px !important",
                textAlign: "right",
              }}
            >
              <Button
                type="submit"
                variant="contained"
                startIcon={
                  action === "edit" ? (
                    <FileUploadOutlined />
                  ) : (
                    <AddCircleOutlineOutlinedIcon />
                  )
                }
                sx={{
                  backgroundColor: "var(--secondary-color)",
                  textTransform: "none",
                  fontSize: { lg: "18px", xl: "20px" },
                  paddingX: { xl: "30px" },
                  fontWeight: "400",
                }}
                size={matches ? "small" : "medium"}
              >
                {action === "edit" ? "Update" : "Create"}
              </Button>
            </Grid>
          </Stack>
        </div>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  const { dialogMessage, updateUser, createUser } = state.userReducer;
  const { DioceseData } = state.DIOCESEReducer;
  const { data } = state.CHURCHReducer;

  return {
    successResponse: dialogMessage,

    churchData: checkRoles(["ROLE_SUPER_ADMIN"])
      ? DioceseData?.data
      : data?.data,
    updateUser,
    createUser,
  };
};

export default connect(mapStateToProps, {
  getAllDIOCESE,
  getAllCHURCH,
  createUser,
  updateUser,
  showUnshowDialogMessage,
})(withMixpanelTracking(AddUser, " Add User"));

import { call, put, takeEvery } from "redux-saga/effects";
import {
  getAllSingerUrls,
  updateSingerUrls,
  deleteSingerUrls,
  createAllSingerUrls,
} from "../../apiServices/ApiUrl";
import {
  getRequest,
  putRequest,
  deleteRequest,
  postRequest,
} from "../../apiServices/ApiActions";
import {
  CREATE_SINGER,
  createSingerFailure,
  createSingerSuccess,
  DELETE_SINGER,
  deleteSingerFailure,
  deleteSingerSuccess,
  GET_SINGER,
  getAllSingersFailure,
  getAllSingersSuccess,
  UPDATE_SINGER,
  updateSingerFailure,
  updateSingerSuccess,
} from "./action";
import { showSnackbar } from "../snackbar/action";

const createCall = async (payload) => {
  return postRequest(createAllSingerUrls, payload);
};
const getCall = async (page) => {
  return getRequest(`${getAllSingerUrls}?page=${--page}&size=${10}`);
};
const updateCall = async (payload, id) => {
  return putRequest(updateSingerUrls + `${id}`, payload);
};
const deleteCall = async (payload) => {
  return deleteRequest(deleteSingerUrls + `${payload}`);
};

function* createSingers(action) {
  try {
    const result = yield call(createCall, action.payload);

    if (result.status === 200) {
      yield put(createSingerSuccess(result));
    } else {
      yield put(createSingerFailure(result));
    }
  } catch (error) {
    yield put(createSingerFailure(error));
  }
}
function* getSingers(action) {
  try {
    const result = yield call(getCall, action.params);
    if (result.status === 200) {
      let response = result.data;
      yield put(getAllSingersSuccess(response));
    } else {
      yield put(getAllSingersFailure(result));
    }
  } catch (error) {
    yield put(getAllSingersFailure(error));
  }
}
function* updateSingers(action) {
  try {
    const result = yield call(updateCall, action.payload, action.id);
    if (result.status === 200) {
      yield put(updateSingerSuccess(result));
      yield put(
        showSnackbar({
          message: "Singer Updated successfully!",
          severity: "success",
        }),
      );
    } else {
      yield put(updateSingerFailure(result));
    }
  } catch (error) {
    yield put(updateSingerFailure(error));
  }
}
function* deleteSingers(action) {
  try {
    const result = yield call(deleteCall, action.payload);
    if (result.status === 204 || result.status === 200) {
      yield put(deleteSingerSuccess(result));
    } else {
      let res = result?.response?.data;
      yield put(deleteSingerFailure(result));

      if (res) {
        yield put(alert(res.message));
      }
    }
  } catch (error) {
    yield put(deleteSingerFailure(error));
  }
}

function* SingerSaga() {
  yield takeEvery(GET_SINGER, getSingers);
  yield takeEvery(UPDATE_SINGER, updateSingers);
  yield takeEvery(CREATE_SINGER, createSingers);
  yield takeEvery(DELETE_SINGER, deleteSingers);
}
export default SingerSaga;

import {
    CREATE_PRAYER,
    PRAYER_SUCCESS,
    PRAYER_FAILURE,
    FETCH_ALL_PRAYER,
    FETCH_ALL_PRAYER_SUCCESS,
    FETCH_ALL_PRAYER_FAILURE,
    UPDATE_PRAYER,
    UPDATE_PRAYER_SUCCESS,
    UPDATE_PRAYER_FAILURE,
    DELETE_PRAYER,
    DELETE_PRAYER_SUCCESS,
    DELETE_PRAYER_FAILURE,
    DIALOG_MESSAGE,
    DELETE_TOGGLE_MODAL_STATUS
} from "./action";

const initialState = {
    dialogMessage: false,
    deleteSuccess: false,
    loading: false,
    error: "",
    prayerData: [],
};

export const prayerReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_PRAYER:
            return {
                ...state,
                error: "",
                dialogMessage: false,
                loading: true,
            };

        case PRAYER_SUCCESS:
            return {
                ...state,
                error: "",
                dialogMessage: true,
                loading: false,
            };

        case PRAYER_FAILURE:
            return {
                ...state,
                error: action.payload,
                dialogMessage: false,
                loading: false,
            };

        case FETCH_ALL_PRAYER:
            return {
                ...state,
                prayerData: [],
                error: "",
                loading: true
            }

        case FETCH_ALL_PRAYER_SUCCESS:
            return {
                ...state,
                prayerData: action.payload,
                error: "",
                loading: false
            }

        case FETCH_ALL_PRAYER_FAILURE:
            return {
                ...state,
                prayerData: [],
                error: action.payload,
                loading: false
            }

        case UPDATE_PRAYER:
            return {
                ...state,
                error: "",
                dialogMessage: false,
                loading: true,
            };

        case UPDATE_PRAYER_SUCCESS:
            return {
                ...state,
                error: "",
                dialogMessage: true,
                loading: false,
            };

        case UPDATE_PRAYER_FAILURE:
            return {
                ...state,
                error: action.payload,
                dialogMessage: false,
                loading: false,
            };

        case DELETE_PRAYER:
            return {
                ...state,
                error: "",
                deleteSuccess: false,
                loading: true
            }

        case DELETE_PRAYER_SUCCESS:
            return {
                ...state,
                error: "",
                deleteSuccess: true,
                loading: false
            }

        case DELETE_PRAYER_FAILURE:
            return {
                ...state,
                error: action.payload,
                deleteSuccess: false,
                loading: false
            }

        case DIALOG_MESSAGE:
            return {
                ...state,
                dialogMessage: false
            }

        case DELETE_TOGGLE_MODAL_STATUS:
            return {
                ...state,
                deleteSuccess: false,
            };

        default:
            return state;
    }
};

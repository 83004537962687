import {
    CircularProgress
} from "@mui/material";


const LoaderLayout = () => {
    return (
        <div style={{ marginTop: '15px' }}>
            <span>
                Please wait...
            </span>
            <CircularProgress color="primary" sx={{ marginLeft: { sm: 11, md: 25, lg: 40 }}} />
        </div>
    )
}

export default LoaderLayout;

import { put, takeEvery } from "redux-saga/effects";

import { showSnackbar } from "./action";
function* handleSagaError(action) {
  let Errr = action.payload;
  if (action.payload) {
    yield put(
      showSnackbar({
        message: Errr.message || "An error occurred",
        severity: "error",
      }),
    );
  }
}

function* snackbarSaga() {
  yield takeEvery(
    (action) => action.type.endsWith("_FAILURE"),
    handleSagaError,
  );
}

export default snackbarSaga;

import {
  CREATE_DAILY_VERSE,
  CREATE_DAILY_VERSE_SUCCESS,
  CREATE_DAILY_VERSE_FAILURE,
  FETCH_DAILY_VERSE,
  FETCH_DAILY_VERSE_SUCCESS,
  FETCH_DAILY_VERSE_FAILURE,
  FETCH_ALL_DAILY_VERSE,
  FETCH_ALL_DAILY_VERSE_SUCCESS,
  FETCH_ALL_DAILY_VERSE_FAILURE,
  UPDATE_DAILY_VERSE,
  UPDATE_DAILY_VERSE_SUCCESS,
  UPDATE_DAILY_VERSE_FAILURE,
  DELETE_DAILY_VERSE,
  DELETE_DAILY_VERSE_SUCCESS,
  DELETE_DAILY_VERSE_FAILURE,
  DIALOG_MESSAGE,
  DELETE_TOGGLE_MODAL_STATUS
} from "./action";

const initialState = {
  loading: false,
  error: "",
  todayDailyVerseData: [],
  dailyVerseData: [],
  getDailyVerseSuccess: false,
  deleteSuccess: false,
  dialogMessage: false
};

export const dailyVerseReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_DAILY_VERSE:
      return {
        ...state,
        error: "",
        dialogMessage: false,
        loading: true,
      };

    case CREATE_DAILY_VERSE_SUCCESS:
      return {
        ...state,
        error: "",
        dialogMessage: true,
        loading: false,
      };

    case CREATE_DAILY_VERSE_FAILURE:
      return {
        ...state,
        error: action.payload,
        dialogMessage: false,
        loading: false,
      };

    case FETCH_DAILY_VERSE:
      return {
        ...state,
        todayDailyVerseData: [],
        error: "",
        getDailyVerseSuccess: false,
      };

    case FETCH_DAILY_VERSE_SUCCESS:
      return {
        ...state,
        todayDailyVerseData: action.payload,
        error: "",
        getDailyVerseSuccess: true,
      };

    case FETCH_DAILY_VERSE_FAILURE:
      return {
        ...state,
        todayDailyVerseData: [],
        error: action.payload,
        getDailyVerseSuccess: false,
      };

    case FETCH_ALL_DAILY_VERSE:
      return {
        ...state,
        dailyVerseData: [],
        error: "",
        loading: true
      }

    case FETCH_ALL_DAILY_VERSE_SUCCESS:
      return {
        ...state,
        dailyVerseData: action.payload,
        error: "",
        loading: false
      }

    case FETCH_ALL_DAILY_VERSE_FAILURE:
      return {
        ...state,
        dailyVerseData: [],
        error: action.payload,
        loading: false
      }

    case UPDATE_DAILY_VERSE: 
      return {
        ...state,
        error: "",
        dialogMessage: false,
        loading: true
      }

    case UPDATE_DAILY_VERSE_SUCCESS:
      return {
        ...state,
        error: "",
        dialogMessage: true,
        loading: false
      }

    case UPDATE_DAILY_VERSE_FAILURE:
      return {
        ...state,
        error: action.payload,
        dialogMessage: false,
        loading: false
      }

    case DELETE_DAILY_VERSE:
      return {
        ...state,
        error: "",
        deleteSuccess: false,
        loading: true
      }

    case DELETE_DAILY_VERSE_SUCCESS:
      return {
        ...state,
        error: "",
        deleteSuccess: true,
        loading: false
      }

    case DELETE_DAILY_VERSE_FAILURE:
      return {
        ...state,
        error: action.payload,
        deleteSuccess: false,
        loading: false
      }

    case DIALOG_MESSAGE:
      return {
        ...state,
        dialogMessage: false
      }

    case DELETE_TOGGLE_MODAL_STATUS:
      return {
        ...state,
        deleteSuccess: false,
      };

    default:
      return state;
  }
};

import { call, put, takeEvery } from "redux-saga/effects";

import { 
  CREATE_PRAYER, 
  prayerSuccess, 
  prayerFailure,
  FETCH_ALL_PRAYER,
  fetchAllPrayerSuccess,
  fetchAllPrayerFailure,
  UPDATE_PRAYER,
  updatePrayerSuccess,
  updatePrayerFailure,
  DELETE_PRAYER,
  deletePrayerSuccess,
  deletePrayerFailure
} from "./action";

import { postRequest, deleteRequest, putRequest, getRequest } from "../../apiServices/ApiActions";

import { 
  addPrayerUrl, 
  updatePrayerUrl, 
  fetchAllPrayerWithPaginationUrl, 
  deletePrayerUrl 
} from "../../apiServices/ApiUrl";

const addPrayer = async(payload) => {
  return await postRequest(addPrayerUrl, payload);
};

const getAllPrayer = async(page) => {
  return await getRequest(`${fetchAllPrayerWithPaginationUrl}?page=${--page}&size=${10}`);
}

const editPrayer = async(payload, params) => {
  return await putRequest(`${updatePrayerUrl}/${params}`, payload);
}

const removePrayer = async(id) => {
  return await deleteRequest(`${deletePrayerUrl}/${id}`);
}

function* createPrayer(action) {
  try {
    const result = yield call(addPrayer, action.payload);

    if (result.status === 200) {
      yield put(prayerSuccess(result));
    } else {
      let res = result?.response?.data
      yield put(prayerFailure(result));

      if(res){
        yield put(alert(res.message))
      }
    }
  } catch (error) {
    yield put(prayerFailure(error));
  }
}

function* fetchAllPrayerWithPagination(action) {
  try {
    const result = yield call(getAllPrayer, action.params);

    if(result.status === 200) {
      yield put(fetchAllPrayerSuccess(result));
    } else {
      let res = result?.response?.data
      yield put(fetchAllPrayerFailure(result));

      if(res){
        yield put(alert(res.message))
      }
    }
  } catch (error) {
    yield put(fetchAllPrayerFailure(error));
  }
}

function* updatePrayer(action) {
  try {
    const result = yield call(editPrayer, action.payload, action.params);

    if(result.status === 200) {
      yield put(updatePrayerSuccess(result));
    } else {
      let res = result?.response?.data
      yield put(updatePrayerFailure(result));

      if(res){
        yield put(alert(res.message))
      }
    }
  } catch (error) {
    yield put(updatePrayerFailure(error));
  }
}

function* deletePrayer(action) {
  try {
    const result = yield call(removePrayer, action.params);

    if(result.status === 204) {
      yield put(deletePrayerSuccess(result));
    } else {
      let res = result?.response?.data
      yield put(deletePrayerFailure(result));

      if(res){
        yield put(alert(res.message))
      }
    }
  } catch (error) {
    yield put(deletePrayerFailure(error));
  }
}

function* prayerSaga() {
  yield takeEvery(CREATE_PRAYER, createPrayer);
  yield takeEvery(UPDATE_PRAYER, updatePrayer);
  yield takeEvery(FETCH_ALL_PRAYER, fetchAllPrayerWithPagination);
  yield takeEvery(DELETE_PRAYER, deletePrayer);
}

export default prayerSaga;

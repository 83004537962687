import {
  Dialog,
  DialogContent,
  DialogContentText,
  IconButton,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

// importing custom style hook
import useCustomStyles from "../customHooks/useCustomStyles";

import passwordCreationSuccessful from "../assets/images/password_creation_successful_image.svg";

const styles = {
  password_successful_logo: {
    height: "auto",
    width: "100%",
  },

  password_successful_message: {
    fontSize: "24px",
    color: "#000000",
    marginBottom: "10px",
  },
};

const DeleteDialogBoxLayout = ({
  deletedialogBoxToggle,
  closeDialogBox,
  header,
  content,
}) => {
  const theme = useTheme();
  const classes = useCustomStyles(styles, theme);

  return (
    <Dialog onClose={closeDialogBox} open={deletedialogBoxToggle}>
      <DialogContent
        sx={{
          width: { xs: 250, md: 350, lg: 450 },
          height: { xs: 350, md: 400, lg: 450 },
          overflow: "hidden",
        }}
      >
        <IconButton
          aria-label="close"
          onClick={closeDialogBox}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>

        <img
          src={passwordCreationSuccessful}
          className={classes?.password_successful_logo}
          alt="password-creation-successful-image"
        />

        <DialogContentText sx={{ textAlign: "center" }}>
          <p className={classes?.password_successful_message}>
            Deleted Successfully
          </p>

          <small style={{ fontSize: "16px", color: "#444444" }}>
            You Have Deleted {header} Successfully
          </small>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteDialogBoxLayout;

import { call, put, takeEvery } from "redux-saga/effects";
import {
  createDiocese,
  deleteDiocese,
  getAllDiocese,
  updateDiocese,
  updateDioceseStatus,
} from "../../apiServices/Diocese";
import {
  CREATE_DIOCESE,
  createDIOCESEFailure,
  createDIOCESESuccess,
  DELETE_DIOCESE,
  deleteDIOCESEFailure,
  deleteDIOCESESuccess,
  GET_DIOCESE,
  getAllDIOCESEFailure,
  getAllDIOCESESuccess,
  UPDATE_DIOCESE,
  UPDATE_DIOCESE_STATUS,
  updateDIOCESEFailure,
  updateDIOCESEStatusSuccess,
  updateDIOCESEStatusFailure,
  updateDIOCESESuccess,
} from "./action";
import { showSnackbar } from "../snackbar/action";

const createCall = async (payload) => {
  return createDiocese(payload);
};
const getCall = async (payload) => {
  return getAllDiocese(payload);
};
const updateCall = async (payload, id) => {
  return updateDiocese(payload, id);
};
const updateStatusCall = async (id, status) => {
  return updateDioceseStatus(id, status);
};
const deleteCall = async (payload) => {
  return deleteDiocese(payload);
};

function* createDIOCESE(action) {
  try {
    const result = yield call(createCall, action.payload);
    if (result.status === 200 || result.status === 201) {
      yield put(createDIOCESESuccess(result));
    } else {
      yield put(createDIOCESEFailure(result));
      yield put(
        showSnackbar({
          message: "Unable to create DIOCESE.",
          severity: "error",
        }),
      );
    }
  } catch (error) {
    // alert(error.response.data.message);
    yield put(createDIOCESEFailure(error));
    yield put(
      showSnackbar({
        message: error.message,
        severity: "error",
      }),
    );
  }
}
function* getDIOCESE(action) {
  try {
    const result = yield call(getCall, action.payload);
    if (result.status === 200 || result.status === 201) {
      yield put(
        getAllDIOCESESuccess(result?.data?.content, result?.data?.page),
      );
    } else {
      yield put(getAllDIOCESEFailure(result?.data));
    }
  } catch (error) {
    yield put(getAllDIOCESEFailure(error?.data));
  }
}
function* updateDIOCESEStatus(action) {
  try {
    const result = yield call(updateStatusCall, action.id, action.status);

    if (result.status === 200) {
      yield put(updateDIOCESEStatusSuccess(result));

      yield put(
        showSnackbar({
          message: `${action.rowName} ${action.status === false ? "Deactivated" : "Activated"} successfully.`,
          severity: "success",
        }),
      );
    } else {
      yield put(updateDIOCESEStatusFailure(result));
      yield put(
        showSnackbar({
          message: "Unable to update status of DIOCESE.",
          severity: "error",
        }),
      );
    }
  } catch (error) {
    yield put(updateDIOCESEStatusFailure(error));
    yield put(
      showSnackbar({
        message: error.message,
        severity: "error",
      }),
    );
  }
}
function* updateDIOCESE(action) {
  try {
    const result = yield call(updateCall, action.payload, action.id);

    if (result.status === 200) {
      yield put(updateDIOCESESuccess(result));
    } else {
      yield put(updateDIOCESEFailure(result));

      yield put(
        showSnackbar({
          message: "Unable to update DIOCESE.",
          severity: "error",
        }),
      );
    }
  } catch (error) {
    yield put(updateDIOCESEFailure(error));
  }
}
function* deleteDIOCESE(action) {
  try {
    const result = yield call(deleteCall, action.payload);
    if (result.status === 204 || result.status === 200) {
      yield put(deleteDIOCESESuccess(result));
    } else {
      let res = result?.response?.data;
      yield put(deleteDIOCESEFailure(res));

      yield put(
        showSnackbar({
          message: "Unable to delete DIOCESE.",
          severity: "error",
        }),
      );
    }
  } catch (error) {
    yield put(deleteDIOCESEFailure(error));
    yield put(
      showSnackbar({
        message: error.message,
        severity: "error",
      }),
    );
  }
}

function* DIOCESESaga() {
  yield takeEvery(GET_DIOCESE, getDIOCESE);
  yield takeEvery(UPDATE_DIOCESE, updateDIOCESE);
  yield takeEvery(CREATE_DIOCESE, createDIOCESE);
  yield takeEvery(DELETE_DIOCESE, deleteDIOCESE);
  yield takeEvery(UPDATE_DIOCESE_STATUS, updateDIOCESEStatus);
}
export default DIOCESESaga;

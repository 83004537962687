import { call, put, takeEvery } from "redux-saga/effects";

import {
  ADMIN_LOGIN,
  ADMIN_UPDATE,
  loginSuccess,
  loginFailure,
  logoutSuccess,
  updateloginSuccess,
  updateloginFailure,
  ADMIN_LOGOUT,
  updatePolicySuccess,
  updatePolicyFailure,
  UPDATE_POLICY,
} from "./action";

import { postRequest, putRequest } from "../../apiServices/ApiActions";
import {
  loginAuthUrl,
  updateAuthUrl,
  updatePolicyUrl,
} from "../../apiServices/ApiUrl";

const addAdmin = async (payload) => {
  return postRequest(loginAuthUrl, payload);
};

const updateAdmin = async (payload) => {
  return postRequest(updateAuthUrl, payload);
};

const updatePolicyApi = async () => {
  return putRequest(updatePolicyUrl);
};

function* createAdmin(action) {
  try {
    const result = yield call(addAdmin, action.payload);

    if (result.status === 200) {
      yield put(loginSuccess(result));
    } else {
      let res = result?.response;
      yield put(loginFailure(result));

      if (res?.status === 403) {
        yield put(loginFailure("Entered email/password is incorrect!"));
      } else if (res?.status === 400) {
        yield put(loginFailure("Entered email/password is incorrect!"));
      } else {
        yield put(loginFailure(res?.data?.error));
      }
    }
  } catch (error) {
    yield put(loginFailure(error));
  }
}

function* updateLogin(action) {
  try {
    const result = yield call(updateAdmin, action.payload);

    if (result.status === 200) {
      yield put(updateloginSuccess(result?.data));
    } else {
      localStorage.clear();
      action.navigate("/login");

      yield put(updateloginFailure(result));
    }
  } catch (error) {
    localStorage.clear();
    action.navigate("/login");
    yield put(updateloginFailure(error));
  }
}

function* logoutAdmin(action) {
  try {
    yield put(logoutSuccess);
  } catch (error) {}
}

function* updatePolicy(action) {
  try {
    const result = yield call(updatePolicyApi, action.payload);

    if (result.status === 200) {
      yield put(updatePolicySuccess());
    } else {
      yield put(updatePolicyFailure());
    }
  } catch (error) {
    yield put(updatePolicyFailure());
  }
}

function* loginSaga() {
  yield takeEvery(ADMIN_LOGIN, createAdmin);
  yield takeEvery(ADMIN_UPDATE, updateLogin);
  yield takeEvery(ADMIN_LOGOUT, logoutAdmin);
  yield takeEvery(UPDATE_POLICY, updatePolicy);
}

export default loginSaga;

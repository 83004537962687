import {
  CREATE_DIOCESE,
  CREATE_DIOCESE_FAILURE,
  CREATE_DIOCESE_SUCCESS,
  DELETE_DIOCESE,
  DELETE_DIOCESE_FAILURE,
  DELETE_DIOCESE_SUCCESS,
  GET_DIOCESE,
  GET_DIOCESE_FAILURE,
  GET_DIOCESE_SUCCESS,
  UPDATE_DIOCESE,
  UPDATE_DIOCESE_FAILURE,
  UPDATE_DIOCESE_SUCCESS,
  DIALOG_MESSAGE,
  UPDATE_DIOCESE_STATUS_SUCCESS,
  UPDATE_DIOCESE_STATUS,
  UPDATE_DIOCESE_STATUS_FAILURE,
  DELETE_TOGGLE_MODAL_STATUS,
} from "./action";

const initialState = {
  loading: false,
  error: "",
  DioceseData: [],

  DioceseupdateResponse: false,
  DiocesedeleteResponse: false,
  DiocesedialogMessage: false,
  DiocesePage: [],
};

export const DIOCESEReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_DIOCESE:
      return {
        ...state,
        loading: true,
        DiocesedialogMessage: false,
      };
    case CREATE_DIOCESE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        DiocesedialogMessage: true,
      };
    case CREATE_DIOCESE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,

        DiocesedialogMessage: false,
      };
    case GET_DIOCESE:
      return {
        ...state,
        loading: true,
        DioceseData: [],
        DiocesePage: [],
      };
    case GET_DIOCESE_SUCCESS:
      return {
        ...state,
        DioceseData: action.payload,
        error: "",
        loading: false,
        DiocesePage: action.page,
      };
    case GET_DIOCESE_FAILURE:
      return {
        ...state,
        error: action.payload,
        DioceseData: [],
        loading: false,
        DiocesePage: [],
      };
    case UPDATE_DIOCESE:
      return {
        ...state,
        loading: true,

        DiocesedialogMessage: false,
      };
    case UPDATE_DIOCESE_SUCCESS:
      return {
        ...state,
        error: "",

        DiocesedialogMessage: true,
      };
    case UPDATE_DIOCESE_FAILURE:
      return {
        ...state,
        error: action.payload,

        DiocesedialogMessage: false,
      };
    case UPDATE_DIOCESE_STATUS:
      return {
        ...state,
        loading: true,
        DioceseupdateResponse: false,
      };
    case UPDATE_DIOCESE_STATUS_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
        DioceseupdateResponse: true,
      };
    case UPDATE_DIOCESE_STATUS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,

        DioceseupdateResponse: false,
      };
    case DELETE_DIOCESE:
      return {
        ...state,
        loading: true,
        DiocesedeleteResponse: false,
      };
    case DELETE_DIOCESE_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
        DiocesedeleteResponse: true,
      };
    case DELETE_DIOCESE_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
        DiocesedeleteResponse: false,
      };
    case DIALOG_MESSAGE:
      return {
        ...state,
        DiocesedialogMessage: false,
      };
    case DELETE_TOGGLE_MODAL_STATUS:
      return {
        ...state,

        DiocesedeleteResponse: false,
      };
    default:
      return state;
  }
};

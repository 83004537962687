import { Tooltip, styled } from "@mui/material";
import { tooltipClasses } from "@mui/material/Tooltip";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "var(--primary-color)",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}));

const truncateAtDot = (filename, wordLength = 23) => {
  if (!filename) return ""; // Handle cases where filename is null or undefined
  return filename.length > wordLength
    ? filename.slice(0, wordLength) + "..."
    : filename;
};

const TableTooltip = (props) => {
  return (
    <LightTooltip title={props?.title}>
      {truncateAtDot(props?.title, props?.wordLength)}
    </LightTooltip>
  );
};
export default TableTooltip;

import { apiClient } from "./AxiosClient";

export const getAllDiocese = async (payload) =>
  await apiClient.get("/diocese/search" + `?${payload}`);

export const createDiocese = async (data) =>
  await apiClient.post("/diocese/create", data);

export const updateDiocese = async (data, id) =>
  await apiClient.put(`/diocese/update/${id}`, data);

export const updateDioceseStatus = async (id, status) =>
  await apiClient.put(`/diocese/status/${id}?isActive=${status}`);

export const deleteDiocese = async (id) =>
  await apiClient.delete(`/location/${id}`);

export const GET_GEO_LOCATION = "GET_GEO_LOCATION";
export const GET_GEO_LOCATION_SUCCESS = "GET_GEO_LOCATION_SUCCESS";
export const GET_GEO_LOCATION_FAILURE = "GET_ALBUM_FAILURE";
export const CLOSE_GEO_LOCATION = "CLOSE_GEO_LOCATION";

export const closeGeoLocation = () => {
  return {
    type: CLOSE_GEO_LOCATION,
  };
};
export const getGeoLocation = (address, pinCode) => {
  return {
    type: GET_GEO_LOCATION,
    payload: { address, pinCode },
  };
};

export const getGeoLocationSuccess = (data) => {
  return {
    type: GET_GEO_LOCATION_SUCCESS,
    payload: data,
  };
};

export const getGeoLocationFailure = (error) => {
  return {
    type: GET_GEO_LOCATION_FAILURE,
    payload: error,
  };
};

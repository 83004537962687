import {
  CREATE_CHURCH,
  CREATE_CHURCH_FAILURE,
  CREATE_CHURCH_SUCCESS,
  DELETE_CHURCH,
  DELETE_CHURCH_FAILURE,
  DELETE_CHURCH_SUCCESS,
  GET_CHURCH,
  GET_CHURCH_FAILURE,
  GET_CHURCH_SUCCESS,
  UPDATE_CHURCH,
  UPDATE_CHURCH_FAILURE,
  UPDATE_CHURCH_SUCCESS,
  DIALOG_MESSAGE,
  UPDATE_CHURCH_STATUS_SUCCESS,
  UPDATE_CHURCH_STATUS,
  UPDATE_CHURCH_STATUS_FAILURE,
  DELETE_TOGGLE_MODAL_STATUS,
} from "./action";

const initialState = {
  loading: false,
  error: "",
  data: [],

  updateResponse: false,
  deleteResponse: false,
  dialogMessage: false,
  churchPage: [],
};

export const CHURCHReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_CHURCH:
      return {
        ...state,
        loading: true,
        dialogMessage: false,
      };
    case CREATE_CHURCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        dialogMessage: true,
      };
    case CREATE_CHURCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,

        dialogMessage: false,
      };
    case GET_CHURCH:
      return {
        ...state,
        loading: true,
        data: [],
        churchPage: [],
      };
    case GET_CHURCH_SUCCESS:
      return {
        ...state,
        data: action.payload,
        churchPage: action.page,
        error: "",
        loading: false,
      };
    case GET_CHURCH_FAILURE:
      return {
        ...state,
        error: action.payload,
        churchPage: [],
        data: [],
        loading: false,
      };
    case UPDATE_CHURCH:
      return {
        ...state,
        loading: true,

        dialogMessage: false,
      };
    case UPDATE_CHURCH_SUCCESS:
      return {
        ...state,
        error: "",

        dialogMessage: true,
      };
    case UPDATE_CHURCH_FAILURE:
      return {
        ...state,
        error: action.payload,

        dialogMessage: false,
      };
    case UPDATE_CHURCH_STATUS:
      return {
        ...state,
        loading: true,
        updateResponse: false,
      };
    case UPDATE_CHURCH_STATUS_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
        updateResponse: true,
      };
    case UPDATE_CHURCH_STATUS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,

        updateResponse: false,
      };
    case DELETE_CHURCH:
      return {
        ...state,
        deleteResponse: true,
      };
    case DELETE_CHURCH_SUCCESS:
      return {
        ...state,
        error: "",

        deleteResponse: true,
      };
    case DELETE_CHURCH_FAILURE:
      return {
        ...state,
        error: action.payload,

        deleteResponse: false,
      };
    case DIALOG_MESSAGE:
      return {
        ...state,
        dialogMessage: false,
      };
    case DELETE_TOGGLE_MODAL_STATUS:
      return {
        ...state,

        deleteResponse: false,
      };
    default:
      return state;
  }
};

const userAccessToken = localStorage.getItem("token");

export const config = {
  headers: { Authorization: `Bearer ${userAccessToken}` },
};

const BASE_URL = process.env.REACT_APP_BACKEND_URL;

export const dashoardUrl = BASE_URL + "/thought/date-range?endDate=2024-08-05";

export const loginAuthUrl = BASE_URL + "/auth/login";

export const updateAuthUrl = BASE_URL + "/auth/refreshToken";

export const updatePolicyUrl = BASE_URL + "/auth/user/termpolicyViewed";

export const fetchLanguageUrl = BASE_URL + "/language";

// daily verse apis
export const getAlldailyVerseURL = BASE_URL + "/thought/date-range?endDate=";

export const adddailyVerseUrl = BASE_URL + "/thought/save";

export const getAlldailyVerseWithPaginationUrl = BASE_URL + "/thought/getAll";

export const updatedailyVerseUrl = BASE_URL + "/thought/update/";

export const deletedailyVerseUrl = BASE_URL + "/thought/deleteById";

// prayer apis
export const addPrayerUrl = BASE_URL + "/reading/save";

export const updatePrayerUrl = BASE_URL + "/reading/update";

export const fetchAllPrayerWithPaginationUrl = BASE_URL + "/reading/admin/all";

export const deletePrayerUrl = BASE_URL + "/reading/delete";

export const fetchAParticularPrayerUrl = BASE_URL + "/reading/admin";

// community post apis
export const communityPostURL = BASE_URL + "/wealth/save";

export const getCommunityPostUrl = BASE_URL + "/wealth/admin/all";

export const deleteCommunityPostUrl = BASE_URL + "/wealth/delete/";

export const updateCommunityPostUrl = BASE_URL + "/wealth/update";

// event apis
export const createEventsUrl = BASE_URL + "/doc/file/uploads";

export const saveEventsUrl = BASE_URL + "/event/save";

export const getEventsUrl = BASE_URL + "/event/admin/getAll";

export const deleteEventsUrl = BASE_URL + "/event/delete/";

// bible apis
export const addBibleUrl = BASE_URL + "/doc/book/save";

export const deleteBibleUrl = BASE_URL + "/doc/book/";

export const getBibleUrls = BASE_URL + "/doc/book/admin/all";

export const upDateBibleUrls = BASE_URL + "/doc/book/update/";

// help center apis
export const getHelpsUrl = BASE_URL + "/user/profiles/user-help/all";

export const updateHelpsUrl = BASE_URL + "/user/profiles/user-help/update/";

// user management api's
export const addUserUrl = BASE_URL + "/auth/register/csi/sub-admin";

export const addUserSuperAdminUrl = BASE_URL + "/auth/register/csi/admin";

export const updateUserUrl = BASE_URL + "/auth/user/update/sub-admin";

export const updateSuperAdminUserUrl = BASE_URL + "/auth/user/update/csi/admin";

export const deleteUserUrl = BASE_URL + "/auth/user/sub-admin/";

// profile apis
export const addProfileUrl = BASE_URL + "/user/profiles/update";

//Quiz apis
export const addQuizUrls = BASE_URL + "/quiz/upload";

export const getQuizUrls = BASE_URL + "/quiz/showUploaded";

export const updateQuizUrls = BASE_URL + "/quiz/update?";

export const downloadQuizUrls = BASE_URL + "/quiz/downloadXlsx?";

//Volunteer

export const getVolunteerListUrls =
  BASE_URL + "/event/admin/get-event-volunteers/";

export const getVolunteeByStatusUrls = BASE_URL + "/event/event/getByStatus/";

export const updateVolunteerStatusUrls = BASE_URL + "/event/volunteer/";

//Delete-Request

export const getDeleteRequestUrls =
  BASE_URL + "/user/profiles/deleted/accounts";

export const getDeleteRequestByStatusUrls =
  BASE_URL + "/user/profiles/deleteRequests/getByStatus";

export const updateDeleteRequestUrls =
  BASE_URL + "/user/profiles/deleteRequest/status";

//Album-api

export const getAllAlubumUrls = BASE_URL + "/songs/albums/all/albums";

export const createAllAlubumUrls = BASE_URL + "/songs/albums/upload";

export const updateAlubumUrls = BASE_URL + "/songs/albums/update/";

export const deleteAlubumUrls = BASE_URL + "/songs/albums/";

//Singer-api

export const getAllSingerUrls = BASE_URL + "/songs/all/singers";

export const createAllSingerUrls = BASE_URL + "/songs/singer/upload";

export const updateSingerUrls = BASE_URL + "/songs/singer/update/";

export const deleteSingerUrls = BASE_URL + "/songs/singer/";

//Gospel
export const uploadGospelWithSongUrl =
  BASE_URL + "/songs/upload/singerWithSong";

export const uploadGospelWithAlbumUrl =
  BASE_URL + "/songs/albums/upload/albumWithSong";

export const updateGospelWithSongUrl = BASE_URL + "/songs/update/singer/";

export const updateGospelWithAlbumUrl =
  BASE_URL + "/songs/albums/update/album/";

export const getAllGospelWithAlbum = BASE_URL + "/songs/albums/getAlbums";

export const getAllGospelWithSinger = BASE_URL + "/songs/singers";

export const deleteSongWitlAlbumUrls = BASE_URL + "/songs/singers";

export const deleteSongWitlSingerUrls = BASE_URL + "/songs/delete/";

//pane-api

export const getVolunteerStatusUrls =
  BASE_URL + "/event/volunteers/status/count?status=APPLIED";

export const getdeleteRequestStatusUrls =
  BASE_URL + "/user/profiles/deleteRequests/count?status=APPLIED";

export const getHelpCenterStatusUrls =
  BASE_URL + "/user/profiles/user-help/count?status=NEW";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../styles/kirtan.css";
import SearchInput from "../commonComponent/SearchInput";
import ViewAlbum from "./ViewAlbum";
import ViewGospel from "./ViewGospel";
import ViewSinger from "./ViewSinger";
import useMediaQuery from "@mui/material/useMediaQuery";

const Gospel = (props) => {
  const navigate = useNavigate();
  const [gospelMenu, setGospelMenu] = useState("Gospel");
  const [dataList, setDataList] = useState([]);
  const [recordsList, setRecordsList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const matches = useMediaQuery("(max-width:1440px)");

  const handleMenuButtonClick = (event, menu) => {
    setOpenModal(false);
    setGospelMenu(menu);
    props.subTabType(menu);
  };

  useEffect(() => {
    if (props.tabType) {
      setGospelMenu(props.tabType);
    } else {
      props.subTabType("Gospel");
      setGospelMenu("Gospel");
    }
  }, []);

  return (
    <>
      <Stack
        className="tab-box"
        spacing={{ xs: 1, sm: 2, md: 0.5, lg: 1, xl: 3 }}
        direction="row"
        useFlexGap
        flexWrap="wrap"
      >
        <List>
          <ListItem
            disablePadding
            sx={{
              height: {
                lg: "1.1rem",
                xl: "3rem",
              },
            }}
          >
            <ListItemButton
              sx={{
                borderRadius: "12px",
                "&.Mui-selected": {
                  backgroundColor: "#ffffff",
                },
                height: {
                  lg: "2rem",
                  xl: "3rem",
                },
              }}
              selected={gospelMenu === "Gospel"}
              onClick={(event) => {
                handleMenuButtonClick(event, "Gospel");
              }}
            >
              <ListItemText
                primary="Gospel"
                sx={
                  gospelMenu === "Gospel"
                    ? { color: "var(--secondary-color)" }
                    : { color: "#596075" }
                }
              />
            </ListItemButton>
          </ListItem>
        </List>
        <List>
          <ListItem
            disablePadding
            sx={{
              height: {
                lg: "1.1rem",
                xl: "3rem",
              },
            }}
          >
            <ListItemButton
              sx={{
                borderRadius: "12px",
                "&.Mui-selected": {
                  backgroundColor: "#ffffff",
                },
                height: {
                  lg: "2rem",
                  xl: "3rem",
                },
              }}
              selected={gospelMenu === "Singer"}
              onClick={(event) => {
                handleMenuButtonClick(event, "Singer");
              }}
            >
              <ListItemText
                primary="Singer"
                sx={
                  gospelMenu === "Singer"
                    ? { color: "var(--secondary-color)" }
                    : { color: "#596075" }
                }
              />
            </ListItemButton>
          </ListItem>
        </List>

        <List>
          <ListItem
            disablePadding
            sx={{
              height: {
                lg: "1.1rem",
                xl: "3rem",
              },
            }}
          >
            <ListItemButton
              sx={{
                borderRadius: "12px",
                "&.Mui-selected": {
                  backgroundColor: "#ffffff",
                },
                height: {
                  lg: "2rem",
                  xl: "3rem",
                },
              }}
              selected={gospelMenu === "Album"}
              onClick={(event) => {
                handleMenuButtonClick(event, "Album");
              }}
            >
              <ListItemText
                primary="Album"
                sx={
                  gospelMenu === "Album"
                    ? { color: "var(--secondary-color)" }
                    : { color: "#596075" }
                }
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Stack>

      {!openModal && (
        <>
          <div className="search_container">
            <SearchInput
              text={"Search Event"}
              tableData={recordsList}
              setSearchedTable={setDataList}
            />
          </div>

          <div className="add_container">
            <Button
              variant="contained"
              startIcon={<AddCircleOutlineIcon />}
              className="Gospel--Add_Button"
              onClick={() => {
                navigate(`/content/gospel/Add`, {
                  replace: true,
                });
              }}
              sx={{
                textTransform: 'none',
                fontSize: {
                  lg: ".8rem",
                  xl: "1.15rem",
                },
              }}
              size={matches ? "small" : ""}
            >
              Add {gospelMenu}
            </Button>
          </div>
        </>
      )}

      <Grid container xs={12} flexWrap="wrap" sx={{ mt: { lg: 5, xl: 8.5 } }}>
        {(() => {
          switch (gospelMenu) {
            case "Gospel":
              return (
                <ViewGospel
                  openModal={openModal}
                  setOpenModal={setOpenModal}
                  gospelList={dataList}
                  setRecordsList={setRecordsList}
                  setGospelList={setDataList}
                  setContentDataToBeUpdated={props.setContentDataToBeUpdated}
                  subTabType={props?.subTabType}
                  tabType={props?.tabType}
                />
              );

            case "Singer":
              return (
                <ViewSinger
                  openModal={openModal}
                  setOpenModal={setOpenModal}
                  singerList={dataList}
                  setRecordsList={setRecordsList}
                  setSingerList={setDataList}
                  setContentDataToBeUpdated={props.setContentDataToBeUpdated}
                />
              );

            case "Album":
              return (
                <ViewAlbum
                  openModal={openModal}
                  setOpenModal={setOpenModal}
                  albumList={dataList}
                  setRecordsList={setRecordsList}
                  setAlbumList={setDataList}
                  setContentDataToBeUpdated={props.setContentDataToBeUpdated}
                />
              );

            default:
              return (
                <ViewGospel openModal={openModal} setOpenModal={setOpenModal} />
              );
          }
        })()}
      </Grid>
    </>
  );
};

export default Gospel;

import { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import useCustomStyles from "../customHooks/useCustomStyles";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import SearchInput from "./commonComponent/SearchInput";
import withMixpanelTracking from "./commonComponent/withMixPanelTracking";

// importing all the necessary components
import MenuLayout from "../commonLayout/MenuLayout";
import AddCommunityPost from "./communityPost/AddCommunityPost";
import ViewCommunityPost from "./communityPost/ViewCommunityPost";
import AddEvent from "./event/AddEvent";
import ViewEvent from "./event/ViewEvent";
import AddEditPrayer from "./prayer/AddEditPrayer";
import ViewPrayer from "./prayer/ViewPrayer";
import AddEditDailyVerse from "./dailyVerse/AddEditDailyVerse";
import DailyVerseBulkUpload from "./dailyVerse/DailyVerseBulkUpload";
import ViewDailyVerse from "./dailyVerse/ViewDailyVerse";
import AddEditBible from "./bible/AddEditBible";
import ViewBible from "./bible/ViewBible";
import ViewQuiz from "./Quiz/ViewQuiz";
import AddQuiz from "./Quiz/AddQuiz";
import BulkUpload from "./Quiz/BulkUpload";
import Gospel from "./gospel/Gospel";
import { capitalizeWords } from "../helpers/stringFormattingHelper";
import UploadGospel from "./gospel/UploadGospel";
import AddAlbum from "./gospel/AddAlbum";
import AddSinger from "./gospel/AddSinger";
import { connect } from "react-redux";
import { getUploadStatus } from "../redux/dashboard/action";

// creating custom styles object
const styles = (theme) => ({
  list_item_text: {
    [theme.breakpoints.down("xl")]: {
      ".MuiTypography-root": {
        fontSize: "15px",
      },
    },
    [theme.breakpoints.up("xl")]: {
      ".MuiTypography-root": {
        fontSize: "18px",
      },
    },
    [theme.breakpoints.between(1236, 1919)]: {
      fontSize: "24px",
    },
  },

  menu_title: {
    [theme.breakpoints.down("xl")]: {
      fontSize: "20px",
      fontWeight: "500",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "28px",
      fontWeight: "600",
    },
  },

  page_title: {
    fontWeight: "600",
    color: "#000000",
    lineHeight: "0",
    [theme.breakpoints.down("xl")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "32px",
    },
  },

  page_sub_title: {
    fontWeight: "500",
    marginTop: "0.25rem",
    color: "#4F566C",
    [theme.breakpoints.down("xl")]: {
      fontSize: "13px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "18px",
    },
  },

  box: {
    margin: "10px 0px",
    display: "flex",
    justifyContent: "space-around",
    width: "80rem",
  },

  menuBox: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xl")]: {
      justifyContent: "left",
    },
    [theme.breakpoints.up("xl")]: {
      justifyContent: "center",
    },
  },

  boxInside: {
    margin: "10px 0px",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xl")]: {
      width: "70rem",
    },
    [theme.breakpoints.up("xl")]: {
      width: "96rem",
    },
  },

  list_item_button: {
    "&.Mui-selected": {
      backgroundColor: "#ffffff",
      borderRadius: "5px",
      height: "3rem",
      width: "auto",
      border: "3px solid var(--secondary-color)",
      borderStyle: "hidden hidden solid hidden",
      fontSize: "30px",
    },
  },

  button: {
    textTransform: "none",
    fontWeight: "500",
    [theme.breakpoints.down(1535)]: {
      fontSize: "10px",
    },
    [theme.breakpoints.between(1536, 1919)]: {
      fontSize: "14px",
    },
    [theme.breakpoints.up(1920)]: {
      fontSize: "20px",
    },
  },

  first_button: {
    marginRight: "8px",
    color: "#414861",
    border: "1px solid #EBEAEB",
    ":hover": {
      border: "1px solid #EBEAEB",
      color: "inherit",
      backgroundColor: "inherit",
    },
  },

  second_button: {
    color: "#ffffff",
    backgroundColor: "var(--secondary-color)",
    "&:disabled": {
      color: "#ffffff",
    },
  },

  page_heading: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  back_arrow: {
    color: "#000000",
    margin: "5px 10px 0 0",
    fontSize: "36px",
  },
});

const ContentManagement = (props) => {
  const { tabs, action } = useParams();
  const theme = useTheme();
  const classes = useCustomStyles(styles, theme);
  const navigate = useNavigate();

  // declaring all the state variables
  const [selectedMenu, setSelectedMenu] = useState(() => {
    const storedMenu = localStorage.getItem("selectedMenu");

    return storedMenu ? storedMenu : "daily-verse";
  });
  const [openBulkUpload, setOpenBulkUpload] = useState(false);
  const [recordTypes] = useState("Add");
  const [actionType, setActionType] = useState(() => {
    const storedactionType = localStorage.getItem("actionType");
    return storedactionType ? storedactionType : "";
  });
  const [tabType, setTabType] = useState(() => {
    const storedtabType = localStorage.getItem("tabType");

    return storedtabType ? storedtabType : "";
  });
  const [dataList, setDataList] = useState([]);
  const [recordsList, setRecordsList] = useState([]);
  const [contentDataToBeUpdated, setContentDataToBeUpdated] = useState([]);

  const handleMenuButtonClick = (event, menu) => {
    setSelectedMenu(menu);
    setActionType("view");
    navigate(`/content/${menu}/${action}`);
  };

  const HandleBulkUploadClick = (val, type) => {
    setOpenBulkUpload(!openBulkUpload);
  };

  useEffect(() => {
    props.getUploadStatus();
  }, []);

  // Update tabType in localStorage whenever it changes
  useEffect(() => {
    if (tabType) {
      localStorage.setItem("tabType", tabType);
    }
  }, [tabType]);

  // Retrieve tabType from localStorage when component mounts
  useEffect(() => {
    const savedTabType = localStorage.getItem("tabType");

    if (savedTabType) {
      setTabType(savedTabType);
    }
  }, []);

  useEffect(() => {
    if (selectedMenu) {
      localStorage.setItem("selectedMenu", selectedMenu);
    }
  }, [selectedMenu]);

  // Retrieve tabType from localStorage when component mounts
  useEffect(() => {
    const savedTabType = localStorage.getItem("selectedMenu");

    if (savedTabType) {
      setSelectedMenu(savedTabType);
    }
  }, []);
  useEffect(() => {
    if (actionType) {
      localStorage.setItem("actionType", actionType);
    }
  }, [tabType]);

  // Retrieve tabType from localStorage when component mounts
  useEffect(() => {
    const savedTabType = localStorage.getItem("actionType");

    if (savedTabType) {
      setActionType(actionType);
    }
  }, []);

  useEffect(() => {
    if (tabs && action) {
      localStorage.setItem("selectedMenu", tabs);
      localStorage.setItem("actionType", action);
      setSelectedMenu(tabs);
      setActionType(action);
    }
  }, [tabs, action]);

  return (
    <>
      {openBulkUpload && (
        <BulkUpload
          componentName={tabs}
          openBulkUpload={openBulkUpload}
          setOpenBulkUpload={setOpenBulkUpload}
          recordsType={recordTypes}
        />
      )}

      <Box sx={{ flexGrow: 1, maxHeight: "100%", mx: 2 }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 1 }}>
          <Grid container xs={12} className={classes?.box}>
            {actionType === "view" ? (
              <>
                <Grid xs={5} md={5} lg={7}>
                  <Box
                    className={"page_title"}
                    sx={{
                      textWrap: "nowrap",
                      paddingY: {
                        lg: "10px",
                        xl: "20px",
                      },
                    }}
                  >
                    Content Management
                  </Box>

                  <p className={classes?.page_sub_title}>
                    Get a top level view of your contents
                  </p>
                </Grid>

                <Grid
                  xs={7}
                  md={7}
                  lg={5}
                  sx={{ width: { md: 100 }, textAlign: "right" }}
                >
                  {selectedMenu !== "gospel" && (
                    <div className="search__container">
                      <SearchInput
                        text={"Search Event"}
                        tableData={recordsList}
                        setSearchedTable={setDataList}
                      />
                    </div>
                  )}
                </Grid>

                <Grid container xs={12} className={classes?.menuBox}>
                  <Stack
                    sx={{
                      borderRadius: "12px",
                      backgroundColor: "#F7F8FA",
                    }}
                    className={classes?.boxInside}
                    direction="row"
                    useFlexGap
                    flexWrap="wrap"
                  >
                    <List disablePadding>
                      <ListItem disablePadding>
                        <ListItemButton
                          className={classes?.list_item_button}
                          selected={selectedMenu === "daily-verse"}
                          onClick={(event) => {
                            handleMenuButtonClick(event, "daily-verse");
                            props.trackButtonClick("Daily Verse Tab", 1);
                          }}
                        >
                          <ListItemText
                            primary="Daily Verse"
                            className={classes?.list_item_text}
                            primaryTypographyProps={{
                              fontSize: {
                                lg: "12px !important",
                                xl: "18px !important",
                              },
                            }}
                            sx={{
                              color:
                                selectedMenu === "daily-verse"
                                  ? "var(--secondary-color)"
                                  : "#596075",
                              textAlign: "center",
                            }}
                          />
                        </ListItemButton>
                      </ListItem>
                    </List>

                    <List disablePadding>
                      <ListItem disablePadding>
                        <ListItemButton
                          className={classes?.list_item_button}
                          selected={selectedMenu === "gospel"}
                          onClick={(event) => {
                            handleMenuButtonClick(event, "gospel");
                            props.trackButtonClick("Gospel Tab", 1);
                          }}
                        >
                          <ListItemText
                            primaryTypographyProps={{
                              fontSize: {
                                lg: "12px !important",
                                xl: "18px !important",
                              },
                            }}
                            primary="Gospel"
                            className={classes?.list_item_text}
                            sx={{
                              color:
                                selectedMenu === "gospel"
                                  ? "var(--secondary-color)"
                                  : "#596075",
                              textAlign: "center",
                            }}
                          />
                        </ListItemButton>
                      </ListItem>
                    </List>

                    <List disablePadding>
                      <ListItem disablePadding>
                        <ListItemButton
                          className={classes?.list_item_button}
                          selected={selectedMenu === "prayer"}
                          onClick={(event) => {
                            handleMenuButtonClick(event, "prayer");
                            props.trackButtonClick("Prayers Tab", 1);
                          }}
                        >
                          <ListItemText
                            primary="Prayers"
                            primaryTypographyProps={{
                              fontSize: {
                                lg: "12px !important",
                                xl: "18px !important",
                              },
                            }}
                            className={classes?.list_item_text}
                            sx={{
                              color:
                                selectedMenu === "prayer"
                                  ? "var(--secondary-color)"
                                  : "#596075",
                              textAlign: "center",
                            }}
                          />
                        </ListItemButton>
                      </ListItem>
                    </List>

                    <List disablePadding>
                      <ListItem disablePadding>
                        <ListItemButton
                          className={classes?.list_item_button}
                          selected={selectedMenu === "bible"}
                          onClick={(event) => {
                            handleMenuButtonClick(event, "bible");
                            props.trackButtonClick("Bible Tab", 1);
                          }}
                        >
                          <ListItemText
                            primary="Bible"
                            className={classes?.list_item_text}
                            primaryTypographyProps={{
                              fontSize: {
                                lg: "12px !important",
                                xl: "18px !important",
                              },
                            }}
                            sx={{
                              color:
                                selectedMenu === "bible"
                                  ? "var(--secondary-color)"
                                  : "#596075",
                              textAlign: "center",
                            }}
                          />
                        </ListItemButton>
                      </ListItem>
                    </List>

                    <List disablePadding>
                      <ListItem disablePadding>
                        <ListItemButton
                          className={classes?.list_item_button}
                          selected={selectedMenu === "event"}
                          onClick={(event) => {
                            handleMenuButtonClick(event, "event");
                            props.trackButtonClick("Event Tab", 1);
                          }}
                        >
                          <ListItemText
                            primary="Event"
                            className={classes?.list_item_text}
                            primaryTypographyProps={{
                              fontSize: {
                                lg: "12px !important",
                                xl: "18px !important",
                              },
                            }}
                            sx={{
                              color:
                                selectedMenu === "event"
                                  ? "var(--secondary-color)"
                                  : "#596075",
                              textAlign: "center",
                            }}
                          />
                        </ListItemButton>
                      </ListItem>
                    </List>

                    <List disablePadding>
                      <ListItem disablePadding>
                        <ListItemButton
                          className={classes?.list_item_button}
                          selected={selectedMenu === "community-post"}
                          onClick={(event) => {
                            handleMenuButtonClick(event, "community-post");
                            props.trackButtonClick("Community Post Tab", 1);
                          }}
                        >
                          <ListItemText
                            primary="Community post"
                            primaryTypographyProps={{
                              fontSize: {
                                lg: "12px !important",
                                xl: "18px !important",
                              },
                            }}
                            className={classes?.list_item_text}
                            sx={{
                              color:
                                selectedMenu === "community-post"
                                  ? "var(--secondary-color)"
                                  : "#596075",
                              textAlign: "center",
                            }}
                          />
                        </ListItemButton>
                      </ListItem>
                    </List>

                    <List disablePadding>
                      <ListItem disablePadding>
                        <ListItemButton
                          className={classes?.list_item_button}
                          selected={selectedMenu === "quiz"}
                          onClick={(event) => {
                            handleMenuButtonClick(event, "quiz");
                            props.trackButtonClick("Quiz Tab", 1);
                          }}
                        >
                          <ListItemText
                            primary="Quiz"
                            primaryTypographyProps={{
                              fontSize: {
                                lg: "12px !important",
                                xl: "18px !important",
                              },
                            }}
                            className={classes?.list_item_text}
                            sx={{
                              color:
                                selectedMenu === "quiz"
                                  ? "var(--secondary-color)"
                                  : "#596075",
                              textAlign: "center",
                            }}
                          />
                        </ListItemButton>
                      </ListItem>
                    </List>
                  </Stack>
                </Grid>
              </>
            ) : (
              <Grid xs={12} className={classes?.page_heading}>
                <Link to={`/content/${tabs}/view`}>
                  <KeyboardBackspaceOutlinedIcon
                    onClick={() => {
                      setTabType(tabType);
                    }}
                    className={classes?.back_arrow}
                  />
                </Link>

                <Typography
                  component="h1"
                  className={classes?.page_title}
                  sx={{
                    width: { xs: 80, md: 100, lg: 500 },
                    textWrap: "nowrap",
                  }}
                >
                  {`${
                    actionType === "add" || actionType === "Add"
                      ? `Add ${selectedMenu === "gospel" ? tabType : capitalizeWords(selectedMenu.split("-"))}`
                      : actionType === "edit" || actionType === "Edit"
                        ? `Edit ${selectedMenu === "gospel" ? tabType : capitalizeWords(selectedMenu.split("-"))}`
                        : ""
                  }`}
                </Typography>
              </Grid>
            )}

            {actionType === "view" ? (
              <Grid container xs={12} sx={{ padding: "10px 0" }}>
                <Grid xs={8}>
                  <Box
                    sx={{
                      fontSize: {
                        lg: "14px",
                        xl: "16px",
                      },
                      fontWeight: 600,
                    }}
                  >
                    {capitalizeWords(selectedMenu.split("-"))}
                  </Box>
                </Grid>

                {selectedMenu !== "quiz" &&
                selectedMenu !== "gospel" &&
                selectedMenu !== "daily-verse" ? (
                  <Grid xs={4}>
                    <Button
                      sx={{ float: "right" }}
                      variant="contained"
                      startIcon={<AddCircleOutlineOutlinedIcon />}
                      size="small"
                      onClick={() => {
                        setSelectedMenu(`${selectedMenu}`);
                        setActionType("add");
                        navigate(`/content/${selectedMenu}/add`);
                        props.trackButtonClick(`Add ${selectedMenu} Button`, 1);
                      }}
                      className={`${classes?.button} ${classes?.second_button}`}
                    >
                      {`Add ${capitalizeWords(selectedMenu.split("-"))}`}
                    </Button>
                  </Grid>
                ) : selectedMenu === "quiz" ? (
                  <Grid xs={4}>
                    <Button
                      sx={{ float: "right" }}
                      variant="contained"
                      startIcon={<FileUploadOutlinedIcon />}
                      size="small"
                      onClick={() => {
                        HandleBulkUploadClick("Bulk Upload", selectedMenu);
                        props.trackButtonClick(
                          `Bulk Upload ${selectedMenu} Button`,
                          1,
                        );
                      }}
                      className={`${classes?.button} ${classes?.second_button}`}
                    >
                      Bulk Upload
                    </Button>
                  </Grid>
                ) : selectedMenu === "daily-verse" ? (
                  <Grid xs={4}>
                    <Stack spacing={1} direction="row" sx={{ float: "right" }}>
                      {/* <Button
                        variant="outlined"
                        startIcon={<FileUploadOutlinedIcon />}
                        size="small"
                        onClick={() => {
                          HandleBulkUploadClick("Bulk Upload", selectedMenu);
                          props.trackButtonClick(
                            `Bulk Upload ${selectedMenu} Button`,
                            1,
                          );
                        }}
                        className={`${classes?.button} ${classes?.first_button}`}
                      >
                        Bulk upload
                      </Button> */}

                      <Button
                        variant="contained"
                        disabled={props?.uploadStatus?.data}
                        startIcon={<AddCircleOutlineOutlinedIcon />}
                        size="small"
                        onClick={() => {
                          setSelectedMenu(`${selectedMenu}`);
                          setActionType("add");
                          navigate(`/content/${selectedMenu}/add`);
                          props.trackButtonClick(
                            `Add ${selectedMenu} Button`,
                            1,
                          );
                        }}
                        className={`${classes?.button} ${classes?.second_button}`}
                      >
                        {`Add ${capitalizeWords(selectedMenu.split("-"))}`}
                      </Button>
                    </Stack>
                  </Grid>
                ) : null}
              </Grid>
            ) : null}

            {/* calling the components based on which menu has been selected */}
            {(() => {
              switch (selectedMenu) {
                case "daily-verse":
                  if (action === "view") {
                    return (
                      <ViewDailyVerse
                        dailyVerseList={dataList}
                        setRecordsList={setRecordsList}
                        recordsList={recordsList}
                        setDailyVerseList={setDataList}
                        setContentDataToBeUpdated={setContentDataToBeUpdated}
                      />
                    );
                  } else if (action === "add") {
                    return <AddEditDailyVerse contentDataToBeUpdated={[]} />;
                  } else if (action === "edit") {
                    return (
                      <AddEditDailyVerse
                        contentDataToBeUpdated={contentDataToBeUpdated}
                      />
                    );
                  }
                  break;

                case "Bulk Upload":
                  return <DailyVerseBulkUpload />;

                case "gospel" || "Singer" || "Album" || "Gospel":
                  if (action === "view") {
                    return (
                      <Gospel
                        gospelList={dataList}
                        setRecordsList={setRecordsList}
                        setGospelList={setDataList}
                        setContentDataToBeUpdated={setContentDataToBeUpdated}
                        subTabType={setTabType}
                        tabType={tabType}
                      />
                    );
                  } else if (action === "Add") {
                    if (tabType === "Gospel") {
                      return <UploadGospel contentDataToBeUpdated={[]} />;
                    } else if (tabType === "Singer") {
                      return <AddSinger contentDataToBeUpdated={[]} />;
                    } else if (tabType === "Album") {
                      return <AddAlbum contentDataToBeUpdated={[]} />;
                    }
                  } else if (action === "edit") {
                    if (tabType === "Gospel") {
                      return (
                        <UploadGospel
                          contentDataToBeUpdated={contentDataToBeUpdated}
                        />
                      );
                    } else if (tabType === "Singer") {
                      return (
                        <AddSinger
                          contentDataToBeUpdated={contentDataToBeUpdated}
                        />
                      );
                    } else if (tabType === "Album") {
                      return (
                        <AddAlbum
                          contentDataToBeUpdated={contentDataToBeUpdated}
                        />
                      );
                    }
                  }
                  break;

                case "bible":
                  if (action === "view") {
                    return (
                      <ViewBible
                        bibleList={dataList}
                        setRecordsList={setRecordsList}
                        recordsList={recordsList}
                        setBibleList={setDataList}
                        setContentDataToBeUpdated={setContentDataToBeUpdated}
                      />
                    );
                  } else if (actionType === "add") {
                    return <AddEditBible contentDataToBeUpdated={[]} />;
                  } else if (actionType === "edit") {
                    return (
                      <AddEditBible
                        contentDataToBeUpdated={contentDataToBeUpdated}
                      />
                    );
                  }
                  break;

                case "prayer":
                  if (actionType === "view") {
                    return (
                      <ViewPrayer
                        prayerList={dataList}
                        setRecordsList={setRecordsList}
                        recordsList={recordsList}
                        setPrayerList={setDataList}
                        setContentDataToBeUpdated={setContentDataToBeUpdated}
                      />
                    );
                  } else if (actionType === "add") {
                    return <AddEditPrayer contentDataToBeUpdated={[]} />;
                  } else if (actionType === "edit") {
                    return (
                      <AddEditPrayer
                        contentDataToBeUpdated={contentDataToBeUpdated}
                      />
                    );
                  }
                  break;

                case "event":
                  if (action === "view") {
                    return (
                      <ViewEvent
                        eventList={dataList}
                        setRecordsList={setRecordsList}
                        recordsList={recordsList}
                        setEventList={setDataList}
                        setContentDataToBeUpdated={setContentDataToBeUpdated}
                      />
                    );
                  } else if (action === "add") {
                    return <AddEvent contentDataToBeUpdated={[]} />;
                  } else if (action === "edit") {
                    return (
                      <AddEvent
                        contentDataToBeUpdated={contentDataToBeUpdated}
                      />
                    );
                  }
                  break;

                case "community-post":
                  if (action === "view") {
                    return (
                      <ViewCommunityPost
                        communityPostList={dataList}
                        setRecordsList={setRecordsList}
                        recordsList={recordsList}
                        setCommunityPostList={setDataList}
                        setContentDataToBeUpdated={setContentDataToBeUpdated}
                      />
                    );
                  } else if (action === "add") {
                    return <AddCommunityPost contentDataToBeUpdated={[]} />;
                  } else if (action === "edit") {
                    return (
                      <AddCommunityPost
                        contentDataToBeUpdated={contentDataToBeUpdated}
                      />
                    );
                  }
                  break;

                case "quiz":
                  if (action === "view") {
                    return (
                      <ViewQuiz
                        quizList={dataList}
                        setRecordsList={setRecordsList}
                        setQuizList={setDataList}
                      />
                    );
                  } else if (action === "add") {
                    return <AddQuiz />;
                  }
                  break;

                default:
                  return <ViewDailyVerse />;
              }
            })()}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { uploadStatus } = state.dashboardReducer;

  return {
    uploadStatus,
  };
};

const ContentMangment = connect(mapStateToProps, {
  getUploadStatus,
})(withMixpanelTracking(ContentManagement, "Content Management"));

const wrapper = () => {
  return (
    <MenuLayout
      childComponent={() => {
        return <ContentMangment />;
      }}
    />
  );
};

export default wrapper;

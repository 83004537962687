import { call, put, takeEvery } from "redux-saga/effects";
import { CREATE_EVENTS, GET_EVENTS, DELETE_EVENTS } from "./action";

import {
  createEventsPostSuccess,
  createEventsPostFailure,
  getEventsSuccess,
  getEventsFailure,
  deleteEventsByIDSuccess,
  deleteEventsByIDFail,
} from "./action";

import {
  postRequest,
  getRequest,
  deleteRequest,
} from "../../apiServices/ApiActions";

import {
  createEventsUrl,
  getEventsUrl,
  deleteEventsUrl,
} from "../../apiServices/ApiUrl";
import { showSnackbar } from "../snackbar/action";

const createPost = async (payload) => {
  return postRequest(createEventsUrl, payload);
};

const getPost = async (page) => {
  return getRequest(`${getEventsUrl}`);
};

const deleteEvents = async (id) => {
  return deleteRequest(deleteEventsUrl + `${id}`);
};

function* createEvents(action) {
  try {
    const Result = yield call(createPost, action.payload);
    if (Result.status === 200) {
      yield put(createEventsPostSuccess(Result));
    } else {
      yield put(createEventsPostFailure(Result?.response?.data));
    }
  } catch (error) {
    yield put(createEventsPostFailure(error?.response?.data));
  }
}

function* getEvents(action) {
  try {
    const Result = yield call(getPost, action.params);

    if (Result.status === 200) {
      let response = Result.data;
      yield put(getEventsSuccess(response));
    } else {
      yield put(getEventsFailure(Result));
    }
  } catch (error) {
    yield put(getEventsFailure(error));
  }
}

function* deleteEventsById(action) {
  try {
    const Result = yield call(deleteEvents, action.payload);

    if (Result.status === 200 || Result.status === 204) {
      yield put(deleteEventsByIDSuccess());
    } else {
      yield put(deleteEventsByIDFail(Result?.response?.data));
    }
  } catch (error) {
    yield put(deleteEventsByIDFail(error?.response?.data));
  }
}

function* eventsSaga() {
  yield takeEvery(CREATE_EVENTS, createEvents);
  yield takeEvery(GET_EVENTS, getEvents);
  yield takeEvery(DELETE_EVENTS, deleteEventsById);
}
export default eventsSaga;

import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Grid,
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  useTheme,
} from "@mui/material";
import InputBase from "@mui/material/InputBase";
import { formatDateSeperatedWithSlash } from "../../helpers/dateHelper";
import { useEffect, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { connect } from "react-redux";
import DeleteDialogLayout from "../../commonLayout/DeleteDialogLayout";
import TableTooltip from "../../helpers/tableTooltip";
import {
  deleteCHURCH,
  deleteToggleModal,
  getAllCHURCH,
  updateCHURCHStatus,
} from "../../redux/church/action";
import {
  deleteDIOCESE,
  deleteDioceseToggleModal,
  getAllDIOCESE,
  updateDIOCESEStatus,
} from "../../redux/diocese/action";
import "../../styles/church.css";
import withMixpanelTracking from "../commonComponent/withMixPanelTracking";
import AntSwitch from "./AntSwitch";
import UpdateStatusDialog from "../../commonLayout/UpdateStatusDialog";
import { useLocation, useNavigate } from "react-router-dom";
import useCustomStyles from "../../customHooks/useCustomStyles";
import { checkRoles } from "../../helpers/checkRoles";

const styles = (theme) => ({
  table: {
    overflowY: "auto",
    scrollbarWidth: "thin",
    [theme.breakpoints.down(1400)]: {
      maxHeight: 200,
    },
    [theme.breakpoints.between(1401, 1919)]: {
      maxHeight: 310,
    },
    [theme.breakpoints.up(1920)]: {
      maxHeight: 500,
    },
  },
});

const Overview = (props) => {
  const theme = useTheme();
  const classes = useCustomStyles(styles, theme);
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const tabTypeEn = query.get("tabType");

  const [searchKey, setSearchKey] = useState("");
  const [selectedMenu, setSelectedMenu] = useState(() =>
    tabTypeEn ? tabTypeEn : "Churches",
  );
  const [deleteToggle, setDeleteToggle] = useState(false);
  const [ChurchCount, setChurchCount] = useState(0);

  const [activeChurchs, setActiveChurchs] = useState(0);

  const [DioceseCount, setDioceseCount] = useState(0);
  const [, setFilteredData] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [paginatedData, setPaginatedData] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const size = 5;
  const [state, setState] = useState(null);
  const [statusState, setStatusState] = useState(false);
  const [currStatus, setCurrStatus] = useState(null);
  const [id, setId] = useState(null);
  const [updateStatusToggle, setUpdateStatusToggle] = useState(false);
  const [openSuccessDelete, setOpenSuccessDelete] = useState(false);
  const [rowName, setRowName] = useState("");
  const [debouncedSearchKey, setDebouncedSearchKey] = useState("");

  const handleStatus = (row) => {
    setUpdateStatusToggle(true);
    setId(row.id);
    setRowName(row.name ? row.name : row.diocese);
    if (!row.active) {
      setState("Activate");
      setCurrStatus(true);
    } else {
      setState("Deactivate");
      setCurrStatus(false);
    }
  };

  const handleMenuButtonClick = (event, menu) => {
    setSelectedMenu(menu);
    setPaginatedData([]);
    setPage(1);
    setSearchKey("");
    setDebouncedSearchKey("");
    navigate(`/church/view?tabType=${menu}`);
    props.handleTabChange(menu);
    props.trackButtonClick(` ${menu} Tab Button`, 1);
  };

  useEffect(() => {
    if (id) {
      if (selectedMenu === "Churches" || tabTypeEn === "Churches") {
        props.updateCHURCHStatus(id, currStatus, rowName);

        props.trackButtonClick("Update Status Churches Button", 1);
      } else {
        props.updateDIOCESEStatus(id, currStatus, rowName);

        props.trackButtonClick("Update Status Diocese Button", 1);
      }
    }
    setStatusState(false);
    setId(null);
    setRowName("");
  }, [statusState]);

  const handleEditRowData = (Value) => {
    props.handleEdit(Value, selectedMenu);
    props.trackButtonClick(`Edit ${selectedMenu} Button`, 1);
  };

  const deleteRowData = (value) => {
    if (selectedMenu === "Churches" || tabTypeEn === "Churches") {
      props.deleteCHURCH(value.id);
      props.trackButtonClick("Delete Church Button", 1);
    } else {
      props.deleteDIOCESE(value.id);
      props.trackButtonClick("Delete Diocese Button", 1);
    }
  };

  const handleSearch = (e) => {
    setSearchKey(e.target.value);
  };
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchKey(searchKey);
      setPage(1);
    }, 1000);
    return () => {
      clearTimeout(handler);
    };
  }, [searchKey]);

  const closeSuccessDeleteModal = () => {
    props.deleteToggleModal();
    setOpenSuccessDelete(false);
  };

  useEffect(() => {
    if (selectedMenu === "Churches") {
      let pageUrl = `search=${debouncedSearchKey}&page=${page - 1}&size=10`;

      props.getAllCHURCH(pageUrl);
      if (props.deleteResponse) {
        setOpenSuccessDelete(true);
      }
    }
  }, [
    props.deleteResponse,
    props.successResponse,
    props.updateResponse,
    selectedMenu,
    debouncedSearchKey,
    page,
  ]);

  useEffect(() => {
    if (selectedMenu === "Diocese") {
      let pageUrl = `searchTerm=${debouncedSearchKey}&page=${page - 1}&size=10`;
      props.getAllDIOCESE(pageUrl);

      if (props.DiocesedeleteResponse) {
        setOpenSuccessDelete(true);
      }
    }
  }, [
    props.DiocesedialogMessage,
    props.DioceseupdateResponse,
    selectedMenu,
    page,
    debouncedSearchKey,
  ]);

  // useEffect(() => {
  //   let activeChurches = 0;
  //   props?.data?.data?.forEach((row) => {
  //     if (row.active) {
  //       activeChurches++;
  //     }
  //   });
  //   setActiveChurchs(activeChurches);
  //   setChurchCount(props?.data?.data?.length);
  //   setDioceseCount(props?.DioceseData?.data?.length);
  // }, [
  //   props?.data,
  //   props?.DioceseData,
  //   props.DiocesedialogMessage,
  //   props.DioceseupdateResponse,
  //   props.successResponse,
  //   props.updateResponse,
  // ]);

  useEffect(() => {
    if (props?.data && selectedMenu === "Churches") {
      setPaginatedData(props?.data);
      setCount(props?.churchPage?.totalPages);
    }
  }, [
    page,
    props.data,
    selectedMenu,
    props.successResponse,
    props.updateResponse,
  ]);

  useEffect(() => {
    if (props?.DioceseData?.length > 0 && selectedMenu === "Diocese") {
      setPaginatedData(props.DioceseData);
      setCount(props?.DiocesePage?.totalPages);
    }
  }, [
    page,
    props.DioceseData,
    selectedMenu,
    props.DiocesedialogMessage,
    props.DioceseupdateResponse,
  ]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const matches = useMediaQuery("(max-width:1440px)");

  return (
    <>
      <DeleteDialogLayout
        deleteToggle={deleteToggle}
        recordType={selectedMenu}
        setDeleteToggle={setDeleteToggle}
        recordRowData={rowData}
        deleteRowData={deleteRowData}
        openSuccessDelete={openSuccessDelete}
        closeSuccessDeleteModal={closeSuccessDeleteModal}
      />

      {updateStatusToggle && (
        <UpdateStatusDialog
          state={state}
          recordType={selectedMenu}
          deleteToggle={updateStatusToggle}
          setDeleteToggle={setUpdateStatusToggle}
          setStatusState={setStatusState}
        />
      )}
      <Box sx={{ ml: 2 }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 5, sm: 2, md: 1 }}
            justifyContent="flex-start"
          >
            {checkRoles(["ROLE_SUPER_ADMIN"]) && (
              <Grid
                xs={4}
                sm={6}
                md={2.5}
                className={"church_users_section"}
                sx={{ p: 2 }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Box>
                    <img
                      className="church_user_count_icon"
                      src="/totalChurches.svg"
                      alt="total-Gurudwara-icon"
                    />
                  </Box>

                  <Box className={"church_user_count_title"}>Total Diocese</Box>
                </Box>

                <div>
                  <span className={"church_user_count_text"}>
                    {DioceseCount}
                  </span>
                </div>
              </Grid>
            )}

            <Grid
              xs={4}
              sm={6}
              md={2.5}
              className={"church_users_section"}
              sx={{ p: 2 }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Box>
                  <img
                    className="church_user_count_icon"
                    src="/totalDiocese.svg"
                    alt="total-Gurudwara-icon"
                  />
                </Box>

                <Box className={"church_user_count_title"}>Total Churches</Box>
              </Box>

              <div>
                <span className={"church_user_count_text"}>{ChurchCount}</span>
              </div>
            </Grid>

            <Grid
              xs={5}
              sm={6}
              md={3.45}
              className={"church_users_section"}
              sx={{ p: 2, ml: "1%" }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Box>
                  <img
                    className="church_user_count_icon "
                    src="/activeGurudwara.svg"
                    alt="active-Gurudwara-icon"
                  />
                </Box>
                <Box className={"church_user_count_title"}>
                  Active Churches On App
                </Box>
              </Box>
              <div>
                <span className={"church_user_count_text"}>
                  {activeChurchs}
                </span>
              </div>
            </Grid>
          </Grid>

          <Grid
            style={{ display: "flex", justifyContent: "end", width: "100%" }}
          >
            <div className="church-search-bar">
              <IconButton
                type="button"
                className="search_icon-church"
                aria-label="search"
              >
                <SearchIcon />
              </IconButton>
              <InputBase
                className="search_placeholder"
                placeholder="Search"
                value={searchKey}
                onChange={handleSearch}
              />
            </div>
          </Grid>

          <div className="tabs">
            <Stack
              spacing={2}
              sx={{
                borderRadius: "12px",
                backgroundColor: "#F7F8FA",
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                width: "auto",
                p: 1,
                textAlign: "center",
              }}
              className="deleteRequest-tabs-text"
              direction="row"
            >
              <List>
                <ListItem
                  disablePadding
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    backgroundColor: "#F7F8FA",
                  }}
                >
                  <ListItemButton
                    sx={{
                      borderRadius: "12px",
                      display: "flex",
                      justifyContent: "center",
                      width: "156px",
                      textAlign: "center",
                    }}
                    className="deleteRequest-tabs-stack"
                    selected={selectedMenu === "Churches"}
                    onClick={(event) => {
                      handleMenuButtonClick(event, "Churches");
                    }}
                  >
                    <ListItemText
                      primary="Churches"
                      sx={
                        selectedMenu === "Churches"
                          ? { color: "var(--secondary-color)" }
                          : { color: "black" }
                      }
                    />
                  </ListItemButton>
                </ListItem>
              </List>

              <List>
                <ListItem
                  disablePadding
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <ListItemButton
                    sx={{
                      borderRadius: "12px",
                      display: "flex",
                      justifyContent: "center",
                      width: "156px",
                      textAlign: "center",
                    }}
                    className="deleteRequest-tabs-stack"
                    selected={selectedMenu === "Diocese"}
                    onClick={(event) => {
                      handleMenuButtonClick(event, "Diocese");
                    }}
                  >
                    <ListItemText
                      primary="Diocese"
                      sx={
                        selectedMenu === "Diocese"
                          ? { color: "var(--secondary-color)" }
                          : { color: "black" }
                      }
                    />
                  </ListItemButton>
                </ListItem>
              </List>
            </Stack>
          </div>

          <Grid container xs={12} flexWrap="wrap" mt="2%">
            <TableContainer
              component={Paper}
              elevation={0}
              className={classes?.table}
            >
              <Table aria-label="simple table" stickyHeader>
                <TableHead>
                  <TableRow className="chruch_table_row">
                    {selectedMenu === "Churches" && (
                      <TableCell className="church_user_cell">Church</TableCell>
                    )}
                    <TableCell className="church_user_cell">Diocese</TableCell>
                    <TableCell className="church_user_cell">Address</TableCell>
                    <TableCell className="church_user_cell">Pin Code</TableCell>
                    <TableCell className="church_user_cell">
                      Created By
                    </TableCell>
                    <TableCell className="church_user_cell">
                      Created On
                    </TableCell>
                    <TableCell className="church_user_cell">Action</TableCell>
                    <TableCell className="church_user_cell"></TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {paginatedData?.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      {selectedMenu === "Churches" && (
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ maxWidth: "180px" }}
                          className="church_user_cell"
                        >
                          <TableTooltip title={row.name} />
                        </TableCell>
                      )}
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ maxWidth: "180px" }}
                        className="church_user_cell"
                      >
                        <TableTooltip
                          title={
                            selectedMenu === "Churches"
                              ? row?.diocese?.diocese
                              : selectedMenu === "Diocese"
                                ? row?.diocese
                                : ""
                          }
                        />
                      </TableCell>
                      <TableCell
                        sx={{ maxWidth: "180px" }}
                        className="church_user_cell"
                      >
                        <TableTooltip
                          title={
                            selectedMenu === "Churches"
                              ? row?.address
                              : selectedMenu === "Diocese"
                                ? row?.residence
                                : ""
                          }
                        />
                      </TableCell>
                      <TableCell className="church_user_cell">
                        {row.pinCode}
                      </TableCell>
                      <TableCell className="church_user_cell">Admin</TableCell>
                      <TableCell className="church_user_cell">
                        {formatDateSeperatedWithSlash(row.createdAt)}
                      </TableCell>
                      <TableCell
                        sx={{ p: 0, maxWidth: "180px", display: "flex" }}
                        className="church_user_cell"
                      >
                        <img
                          src="/edit-icon.svg"
                          alt="edit"
                          className="church_edit_icon"
                          onClick={() => handleEditRowData(row)}
                        />
                        {/* <img
                          src="/delete-icon.svg"
                          alt="delete"
                          className="church_delete_icon"
                          onClick={() => {
                            handleDeleteData(row);
                          }}
                        /> */}

                        {checkRoles(["ROLE_SUPER_ADMIN"]) &&
                        selectedMenu === "Diocese" ? (
                          <Box
                            display="center"
                            alignItems="center"
                            sx={{ m: 1 }}
                          >
                            <AntSwitch
                              defaultChecked
                              inputProps={{ "aria-label": "ant design" }}
                              onChange={() => handleStatus(row)}
                              checked={row?.active}
                            />
                            {row.active ? (
                              <p
                                style={{
                                  padding: 0,
                                  margin: 4,
                                  color: "var(--secondary-color)",
                                }}
                              >
                                Active
                              </p>
                            ) : (
                              <p
                                style={{
                                  padding: 0,
                                  margin: 4,
                                  color: "#4F566C",
                                }}
                              >
                                Inactive
                              </p>
                            )}
                          </Box>
                        ) : checkRoles(["ROLE_ADMIN"]) &&
                          selectedMenu === "Churches" ? (
                          <Box
                            display="center"
                            alignItems="center"
                            sx={{ m: 1 }}
                          >
                            <AntSwitch
                              defaultChecked
                              inputProps={{ "aria-label": "ant design" }}
                              onChange={() => handleStatus(row)}
                              checked={row?.active}
                            />
                            {row.active ? (
                              <p
                                style={{
                                  padding: 0,
                                  margin: 4,
                                  color: "var(--secondary-color)",
                                }}
                              >
                                Active
                              </p>
                            ) : (
                              <p
                                style={{
                                  padding: 0,
                                  margin: 4,
                                  color: "#4F566C",
                                }}
                              >
                                Inactive
                              </p>
                            )}
                          </Box>
                        ) : (
                          ""
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {paginatedData?.length === 0 && (
                <Box
                  sx={{
                    display: "grid",
                    justifyContent: "center",
                    textAlign: "center",
                    marginTop: "30px",
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <img
                      src="/mailbox.png"
                      alt="No data"
                      style={{ width: "30px", height: "30px" }}
                    />
                  </Box>
                  <Box sx={{}}>No data</Box>
                </Box>
              )}
            </TableContainer>
          </Grid>
        </Grid>
        {count ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <Pagination
              count={count}
              page={page}
              size={matches ? "small" : "large"}
              onChange={handleChange}
            />
          </Box>
        ) : (
          ""
        )}
      </Box>
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    data,
    churchPage,
    createRespone,
    updateResponse,
    deleteResponse,
    dialogMessage,
  } = state.CHURCHReducer;

  const {
    DioceseData,

    DioceseupdateResponse,
    DiocesedeleteResponse,
    DiocesedialogMessage,
    DiocesePage,
  } = state.DIOCESEReducer;

  return {
    data,
    createRespone,
    updateResponse,
    deleteResponse,
    dialogMessage,
    DioceseData,
    churchPage,
    DiocesePage,
    DioceseupdateResponse,
    DiocesedeleteResponse,
    DiocesedialogMessage,
  };
};

export default connect(mapStateToProps, {
  getAllCHURCH,
  deleteCHURCH,
  updateCHURCHStatus,
  getAllDIOCESE,
  deleteDIOCESE,
  updateDIOCESEStatus,
  deleteToggleModal,
  deleteDioceseToggleModal,
})(withMixpanelTracking(Overview, " Church"));

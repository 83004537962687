import { call, put, takeEvery } from "redux-saga/effects";
import {
  createChurch,
  deleteChurch,
  getAllChurch,
  updateChurch,
  updateChurchStatus,
} from "../../apiServices/Church";
import {
  CREATE_CHURCH,
  createCHURCHFailure,
  createCHURCHSuccess,
  DELETE_CHURCH,
  deleteCHURCHFailure,
  deleteCHURCHSuccess,
  GET_CHURCH,
  getAllCHURCHFailure,
  getAllCHURCHSuccess,
  UPDATE_CHURCH,
  UPDATE_CHURCH_STATUS,
  updateCHURCHFailure,
  updateCHURCHStatusSuccess,
  updateCHURCHStatusFailure,
  updateCHURCHSuccess,
} from "./action";
import { showSnackbar } from "../snackbar/action";

const createCall = async (payload) => {
  return createChurch(payload);
};
const getCall = async (payload) => {
  return getAllChurch(payload);
};
const updateCall = async (payload) => {
  return updateChurch(payload);
};
const updateStatusCall = async (id, status) => {
  return updateChurchStatus(id, status);
};
const deleteCall = async (payload) => {
  return deleteChurch(payload);
};

function* createCHURCH(action) {
  try {
    const result = yield call(createCall, action.payload);
    if (result.status === 200) {
      yield put(createCHURCHSuccess(result));
    } else {
      yield put(createCHURCHFailure(result));
      yield put(
        showSnackbar({
          message: "Unable to create CHURCH.",
          severity: "error",
        }),
      );
    }
  } catch (error) {
    // alert(error.response.data.message);
    yield put(createCHURCHFailure(error));
    yield put(
      showSnackbar({
        message: error.message,
        severity: "error",
      }),
    );
  }
}
function* getCHURCH(action) {
  try {
    const result = yield call(getCall, action.payload);

    if (result.status === 200) {
      yield put(getAllCHURCHSuccess(result?.data?.content, result?.data?.page));
    } else {
      yield put(getAllCHURCHFailure(result?.data));
    }
  } catch (error) {
    yield put(getAllCHURCHFailure(error?.data));
  }
}
function* updateCHURCHStatus(action) {
  try {
    const result = yield call(updateStatusCall, action.id, action.status);

    if (result.status === 200) {
      yield put(updateCHURCHStatusSuccess(result));

      yield put(
        showSnackbar({
          message: `${action.rowName} ${action.status === false ? "Deactivated" : "Activated"} successfully.`,
          severity: "success",
        }),
      );
    } else {
      yield put(updateCHURCHStatusFailure(result));
      yield put(
        showSnackbar({
          message: "Unable to update status of CHURCH.",
          severity: "error",
        }),
      );
    }
  } catch (error) {
    yield put(updateCHURCHStatusFailure(error));
    yield put(
      showSnackbar({
        message: error.message,
        severity: "error",
      }),
    );
  }
}
function* updateCHURCH(action) {
  try {
    const result = yield call(updateCall, action.payload, action.id);

    if (result.status === 200) {
      yield put(updateCHURCHSuccess(result));
    } else {
      yield put(updateCHURCHFailure(result));

      yield put(
        showSnackbar({
          message: "Unable to update CHURCH.",
          severity: "error",
        }),
      );
    }
  } catch (error) {
    yield put(updateCHURCHFailure(error));
  }
}
function* deleteCHURCH(action) {
  try {
    const result = yield call(deleteCall, action.payload);
    if (result.status === 204 || result.status === 200) {
      yield put(deleteCHURCHSuccess(result));
    } else {
      let res = result?.response?.data;
      yield put(deleteCHURCHFailure(res));

      yield put(
        showSnackbar({
          message: "Unable to delete CHURCH.",
          severity: "error",
        }),
      );
    }
  } catch (error) {
    yield put(deleteCHURCHFailure(error));
    yield put(
      showSnackbar({
        message: error.message,
        severity: "error",
      }),
    );
  }
}

function* CHURCHSaga() {
  yield takeEvery(GET_CHURCH, getCHURCH);
  yield takeEvery(UPDATE_CHURCH, updateCHURCH);
  yield takeEvery(CREATE_CHURCH, createCHURCH);
  yield takeEvery(DELETE_CHURCH, deleteCHURCH);
  yield takeEvery(UPDATE_CHURCH_STATUS, updateCHURCHStatus);
}
export default CHURCHSaga;

import {
  CREATE_GOSPEL_WITH_SINGER,
  CREATE_GOSPEL_WITH_SINGER_SUCCESS,
  CREATE_GOSPEL_WITH_SINGER_FAILURE,
  UPDATE_GOSPEL_WITH_SINGER,
  UPDATE_GOSPEL_WITH_SINGER_SUCCESS,
  UPDATE_GOSPEL_WITH_SINGER_FAILURE,
  CREATE_GOSPEL_WITH_ALBUM,
  CREATE_GOSPEL_WITH_ALBUM_SUCCESS,
  CREATE_GOSPEL_WITH_ALBUM_FAILURE,
  UPDATE_GOSPEL_WITH_ALBUM,
  UPDATE_GOSPEL_WITH_ALBUM_SUCCESS,
  UPDATE_GOSPEL_WITH_ALBUM_FAILURE,
  GET_GOSPEL_WITH_ALBUM,
  GET_GOSPEL_WITH_ALBUM_SUCCESS,
  GET_GOSPEL_WITH_ALBUM_FAILURE,
  GET_GOSPEL_WITH_SINGER,
  GET_GOSPEL_WITH_SINGER_SUCCESS,
  GET_GOSPEL_WITH_SINGER_FAILURE,
  DIALOG_MESSAGE,
  DELETE_SONG_SINGER,
  DELETE_SONG_SINGER_SUCCESS,
  DELETE_SONG_SINGER_FAILURE,
  DELETE_SONG_ALBUM,
  DELETE_SONG_ALBUM_SUCCESS,
  DELETE_SONG_ALBUM_FAILURE,
  DELETE_TOGGLE_MODAL_STATUS
} from "./action";

const initialState = {
  loading: false,
  error: "",
  createResponse: false,
  updateResponse: false,
  gospelWithSingerData: [],
  gospelWithAlbumData: [],
  singerSuccessResponse: false,
  albumSuccessResponse: false,
  dialogMessage: false,
  deleteSuccess: false,
};

export const gospelReducer = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_SONG_SINGER:
      return {
        ...state,
        error: "",
        deleteSuccess: false,
      };
    case DELETE_SONG_SINGER_SUCCESS:
      return {
        ...state,
        error: "",
        deleteSuccess: true,
      };
    case DELETE_SONG_SINGER_FAILURE:
      return {
        ...state,
        error: action.payload,
        deleteSuccess: false,
      };
    case DELETE_SONG_ALBUM:
      return {
        ...state,
        error: "",
        deleteSuccess: false,
      };
    case DELETE_SONG_ALBUM_SUCCESS:
      return {
        ...state,
        error: "",
        deleteSuccess: true,
      };
    case DELETE_SONG_ALBUM_FAILURE:
      return {
        ...state,
        error: action.payload,
        deleteSuccess: false,
      };
    case CREATE_GOSPEL_WITH_SINGER:
      return {
        ...state,
        loading: true,
        createResponse: false,
        dialogMessage: false,
      };
    case CREATE_GOSPEL_WITH_SINGER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        createResponse: true,
        dialogMessage: true,
      };
    case CREATE_GOSPEL_WITH_SINGER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        createResponse: false,
        dialogMessage: false,
      };

    case UPDATE_GOSPEL_WITH_SINGER:
      return {
        ...state,
        loading: true,
        updateResponse: false,
        dialogMessage: false,
      };
    case UPDATE_GOSPEL_WITH_SINGER_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
        updateResponse: true,
        dialogMessage: true,
      };
    case UPDATE_GOSPEL_WITH_SINGER_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
        updateResponse: false,
        dialogMessage: false,
      };
    case CREATE_GOSPEL_WITH_ALBUM:
      return {
        ...state,
        loading: true,
        createResponse: false,
        dialogMessage: false,
      };
    case CREATE_GOSPEL_WITH_ALBUM_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        createResponse: true,
        dialogMessage: true,
      };
    case CREATE_GOSPEL_WITH_ALBUM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        createResponse: false,
        dialogMessage: false,
      };

    case UPDATE_GOSPEL_WITH_ALBUM:
      return {
        ...state,
        loading: true,
        updateResponse: false,
        dialogMessage: false,
      };
    case UPDATE_GOSPEL_WITH_ALBUM_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
        updateResponse: true,
        dialogMessage: true,
      };
    case UPDATE_GOSPEL_WITH_ALBUM_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
        updateResponse: false,
        dialogMessage: false,
      };
    case GET_GOSPEL_WITH_ALBUM:
      return {
        ...state,
        error: "",
        loading: false,
        albumSuccessResponse: false,
        gospelWithAlbumData: [],
      };
    case GET_GOSPEL_WITH_ALBUM_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
        albumSuccessResponse: true,
        gospelWithAlbumData: action.payload,
      };
    case GET_GOSPEL_WITH_ALBUM_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
        albumSuccessResponse: false,
        gospelWithAlbumData: [],
      };

    case GET_GOSPEL_WITH_SINGER:
      return {
        ...state,
        error: "",
        loading: false,
        singerSuccessResponse: false,
        gospelWithSingerData: [],
      };
    case GET_GOSPEL_WITH_SINGER_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
        singerSuccessResponse: true,
        gospelWithSingerData: action.payload,
      };
    case GET_GOSPEL_WITH_SINGER_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
        singerSuccessResponse: false,
        gospelWithSingerData: [],
      };

    case DIALOG_MESSAGE:
      return {
        ...state,
        dialogMessage: false,
      };

    case DELETE_TOGGLE_MODAL_STATUS:
      return {
        ...state,
        deleteSuccess: false,
      };

    default:
      return state;
  }
};

export const checkRoles = (allowedRoles) => {
  let roles = localStorage.getItem("role");

  let userRoles = JSON.stringify(roles);

  const hasRole = (userRoles, allowedRoles) => {
    return allowedRoles.some((role) => userRoles.includes(role));
  };
  return hasRole(userRoles, allowedRoles);
};

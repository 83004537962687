import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { Box, Button, Grid, useMediaQuery } from "@mui/material";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import MenuLayout from "../commonLayout/MenuLayout";
import AddChurch from "./Church/AddChurch";
import Overview from "./Church/Overview";
import "../styles/church.css";
import { useState, useEffect } from "react";
import AddDiocese from "./Church/AddDiocese";
import withMixpanelTracking from "./commonComponent/withMixPanelTracking";
import { checkRoles } from "../helpers/checkRoles";

const Churches = (props) => {
  const matches = useMediaQuery("(max-width:1440px)");
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const tabTypeEn = query.get("tabType");
  const navigate = useNavigate();
  const { action } = useParams();
  const [tabTypes, setTabType] = useState(" ");

  const handleAddChurch = (val) => {
    props.trackButtonClick(`Add ${val} Button`, 1);
    navigate(`/church/Add?tabType=${val}`);
  };

  const handleTabChange = (val) => {
    setTabType(val);
  };

  useEffect(() => {
    if (tabTypeEn === "Churches" || tabTypeEn === "Diocese") {
      setTabType(tabTypeEn);
    }
  }, [tabTypeEn]);
  const updateChurch = (value, tabType) => {
    const jsonData = JSON.stringify(value);

    const encodedData = encodeURIComponent(jsonData);
    navigate(`/church/edit?data=${encodedData}&tabType=${tabType}`);
  };

  return (
    <>
      <Box sx={{ flexGrow: 1, maxHeight: "100%", ml: 2 }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 1 }}>
          {action === "edit" || action === "Add" ? (
            tabTypeEn === "Churches" ? (
              <AddChurch />
            ) : (
              <AddDiocese />
            )
          ) : (
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 5, sm: 2, md: 1 }}
              justifyContent="flex-start"
            >
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Grid xs={5} md={5} lg={7}>
                  <Box
                    className={"page_title"}
                    sx={{
                      textWrap: "nowrap",
                      paddingY: {
                        lg: "10px",
                        xl: "20px",
                        marginTop: "1rem",
                      },
                    }}
                  >
                    Churches
                  </Box>

                  <p className="church_page_sub_title">
                    Get a top level view of your Contents
                  </p>
                </Grid>
                {!checkRoles(["ROLE_SUPER_ADMIN"]) &&
                tabTypes === "Diocese" ? null : (
                  <Button
                    variant="contained"
                    startIcon={<AddCircleOutlineOutlinedIcon />}
                    size={"small"}
                    onClick={() =>
                      handleAddChurch(
                        tabTypes === "Diocese" ? "Diocese" : "Churches",
                      )
                    }
                    className={`church_button`}
                    sx={{
                      fontSize: matches ? "12px" : "inherit",
                      textTransform: "none",
                    }}
                  >
                    Add {tabTypes === "Diocese" ? "Diocese" : "Church"}
                  </Button>
                )}
              </Grid>

              <Overview
                handleEdit={updateChurch}
                handleTabChange={handleTabChange}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
};

const ChurchComp = withMixpanelTracking(Churches, "Church View");

const wrapper = () => {
  return (
    <MenuLayout
      childComponent={() => {
        return <ChurchComp />;
      }}
    />
  );
};

export default wrapper;

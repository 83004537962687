export const CREATE_DAILY_VERSE = "CREATE_DAILY_VERSE";
export const CREATE_DAILY_VERSE_SUCCESS = "CREATE_DAILY_VERSE_SUCCESS";
export const CREATE_DAILY_VERSE_FAILURE = "CREATE_DAILY_VERSE_FAILURE";

export const FETCH_DAILY_VERSE = "FETCH_DAILY_VERSE";
export const FETCH_DAILY_VERSE_SUCCESS = "FETCH_DAILY_VERSE_SUCCESS";
export const FETCH_DAILY_VERSE_FAILURE = "FETCH_DAILY_VERSE_FAILURE";

export const FETCH_ALL_DAILY_VERSE = "FETCH_ALL_DAILY_VERSE";
export const FETCH_ALL_DAILY_VERSE_SUCCESS = "FETCH_ALL_DAILY_VERSE_SUCCESS";
export const FETCH_ALL_DAILY_VERSE_FAILURE = "FETCH_ALL_DAILY_VERSE_FAILIURE";

export const UPDATE_DAILY_VERSE = "UPDATE_DAILY_VERSE";
export const UPDATE_DAILY_VERSE_SUCCESS = "UPDATE_DAILY_VERSE_SUCCESS";
export const UPDATE_DAILY_VERSE_FAILURE = "UPDATE_DAILY_VERSE_FAILURE";

export const DELETE_DAILY_VERSE = "DELETE_DAILY_VERSE";
export const DELETE_DAILY_VERSE_SUCCESS = "DELETE_DAILY_VERSE_SUCCESS";
export const DELETE_DAILY_VERSE_FAILURE = "DELETE_DAILY_VERSE_FAILURE";

export const DIALOG_MESSAGE = "DIALOG_MESSAGE";
export const DELETE_TOGGLE_MODAL_STATUS = "DELETE_TOGGLE_MODAL_STATUS";

// defining actions for create daily verse
export const createDailyVerse = (data) => {
  return {
    type: CREATE_DAILY_VERSE,
    payload: data,
  };
};

export const createDailyVerseSuccess = (data) => {
  return {
    type: CREATE_DAILY_VERSE_SUCCESS,
    payload: data,
  };
};

export const createDailyVerseFailure = (error) => {
  return {
    type: CREATE_DAILY_VERSE_FAILURE,
    payload: error,
  };
};

// defining actions for fetch particular daily verse
export const fetchDailyVerse = (urlParameters) => {
  return {
      type: FETCH_DAILY_VERSE,
      params: urlParameters
  }
}

export const fetchDailyVerseSuccess = (data) => {
  return {
    type: FETCH_DAILY_VERSE_SUCCESS,
    payload: data,
  };
};

export const fetchDailyVerseFailure = (error) => {
  return {
    type: FETCH_DAILY_VERSE_FAILURE,
    payload: error,
  };
};

// defining actions for fetch all daily verse
export const fetchAllDailyVerseWithPagination = (urlParameters) => {
  return {
    type: FETCH_ALL_DAILY_VERSE,
    params: urlParameters
  };
};

export const fetchAllDailyVerseSuccess = (data) => {
  return {
    type: FETCH_ALL_DAILY_VERSE_SUCCESS,
    payload: data,
  };
};

export const fetchAllDailyVerseFailure = (error) => {
  return {
    type: FETCH_ALL_DAILY_VERSE_FAILURE,
    payload: error,
  };
};

// defining actions for update daily verse
export const updateDailyVerse = (data, urlParameters) => {
  return {
    type: UPDATE_DAILY_VERSE,
    payload: data,
    params: urlParameters
  }
}

export const updateDailyVerseSuccess = (data) => {
  return {
    type: UPDATE_DAILY_VERSE_SUCCESS,
    payload: data,
  };
};

export const updateDailyVerseFailure = (error) => {
  return {
    type: UPDATE_DAILY_VERSE_FAILURE,
    payload: error,
  };
};

// defining actions for delete daily verse
export const deleteDailyVerse = (id) => {
  return {
    type: DELETE_DAILY_VERSE,
    params: id
  };
};

export const deleteDailyVerseSuccess = (data) => {
  return {
    type: DELETE_DAILY_VERSE_SUCCESS,
    payload: data,
  };
};

export const deleteDailyVerseFailure = (error) => {
  return {
    type: DELETE_DAILY_VERSE_FAILURE,
    payload: error,
  };
};

export const showUnshowDialogMessage = () => {
  return {
    type: DIALOG_MESSAGE
  };
};

// defining action for enabling/disabling delete modal
export const deleteToggleModal = () => {
  return {
    type: DELETE_TOGGLE_MODAL_STATUS,
  };
};
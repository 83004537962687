import { call, put, takeEvery } from "redux-saga/effects";

import {  
    CREATE_DAILY_VERSE, 
    createDailyVerseSuccess,
    createDailyVerseFailure,
    FETCH_DAILY_VERSE,
    fetchDailyVerseSuccess,
    fetchDailyVerseFailure,
    FETCH_ALL_DAILY_VERSE,
    fetchAllDailyVerseSuccess,
    fetchAllDailyVerseFailure,
    UPDATE_DAILY_VERSE,
    updateDailyVerseFailure,
    DELETE_DAILY_VERSE,
    deleteDailyVerseSuccess,
    deleteDailyVerseFailure
} from "./action";

import { getRequest, postRequest, putRequest, deleteRequest } from "../../apiServices/ApiActions";
import { 
  getAlldailyVerseURL, 
  getAlldailyVerseWithPaginationUrl, 
  adddailyVerseUrl, 
  updatedailyVerseUrl, 
  deletedailyVerseUrl
} from "../../apiServices/ApiUrl";
import { formatDateSeperatedWithHyphen, todayDate } from "../../helpers/dateHelper";

const adddailyVerse = async (payload) => {
  return postRequest(adddailyVerseUrl, payload);
};

const getdailyVerse = async(languageCode) => {
  const hyphenFormattedDate = formatDateSeperatedWithHyphen(todayDate());
  return getRequest(`${getAlldailyVerseURL}${hyphenFormattedDate}&languageCode=${languageCode}`);
}

const getAlldailyVerses = async(page) => {
  return await getRequest(`${getAlldailyVerseWithPaginationUrl}?page=${--page}&size=${10}`);
}

const editdailyVerse = async(payload, id) => {
  return await putRequest(`${updatedailyVerseUrl}${id}`, payload);
}

const removedailyVerse =  async(id) => {
  return await deleteRequest(`${deletedailyVerseUrl}/${id}`);
}

function* createdailyVerse(action) {
  try {
    const result = yield call(adddailyVerse, action.payload);

    if (result.status === 200) {
      yield put(createDailyVerseSuccess(result));
    } else {
      let res = result?.response?.data
      yield put(createDailyVerseFailure(result));

      if(res){
        yield put(alert(res.message))
      }
    }
  } catch (error) {
    yield put(createDailyVerseFailure(error));
  }
}

function* getdailyVerses(action) {
    try {
       const result = yield call(getdailyVerse, action.params); 

       if(result.status === 200) {
        yield put(fetchDailyVerseSuccess(result));
       } else {
        yield put(fetchDailyVerseFailure(result));
       }
    } catch (error) {
        yield put(fetchDailyVerseFailure(error));
    }
}

function* fetchAlldailyVerseWithPagination(action) {
  try {
    const result = yield call(getAlldailyVerses, action.params);

    if(result.status === 200) {
      yield put(fetchAllDailyVerseSuccess(result));
    } else {
      let res = result?.response?.data
      yield put(fetchAllDailyVerseFailure(result));

      if(res){
        yield put(alert(res.message))
      }
    }
  } catch (error) {
    yield put(fetchAllDailyVerseFailure(error));
  }
}

function* updatedailyVerse(action) {
  try {
    const result = yield call(editdailyVerse, action.payload, action.params);

    if (result.status === 200) {
      yield put(createDailyVerseSuccess(result));
    } else {
      let res = result?.response?.data
      yield put(createDailyVerseFailure(result));

      if(res){
        yield put(alert(res.message))
      }
    }
  } catch (error) {
    yield put(updateDailyVerseFailure(error));
  }
}

function* deletedailyVerse(action) {
  try {
    const result = yield call(removedailyVerse, action.params);

    if(result.status === 204) {
      yield put(deleteDailyVerseSuccess(result));
    } else {
      let res = result?.response?.data
      yield put(deleteDailyVerseFailure(result));

      if(res){
        yield put(alert(res.message))
      }
    }
  } catch (error) {
    yield put(deleteDailyVerseFailure(error));
  }
}

function* dailyVerseSaga() {
  yield takeEvery(CREATE_DAILY_VERSE, createdailyVerse);
  yield takeEvery(FETCH_DAILY_VERSE, getdailyVerses);
  yield takeEvery(FETCH_ALL_DAILY_VERSE, fetchAlldailyVerseWithPagination);
  yield takeEvery(UPDATE_DAILY_VERSE, updatedailyVerse);
  yield takeEvery(DELETE_DAILY_VERSE, deletedailyVerse);
}

export default dailyVerseSaga;
